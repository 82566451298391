<template>
  <div>
    <Title :pages="[{ icon: 'inbox', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <v-card>
        <div class="row mb-5 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">                                           
                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="client_selected"
                    autocomplete="off"
                    data-vv-as="client_selected"
                    name="client_selected"
                    item-text="business_name"
                    item-value="id"
                    :items="clients"
                    label="Cliente"
                    class="pt-0 mt-0"                    
                    clearable
                  ></v-combobox>
                </div>  
                <div class="col-md-6 pt-0">
                </div>              

                <div class="col-md-3 pt-0" style="display: flex; justify-content: end">                  
                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.client.business_name }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.doc_type.name }}</td>
              <td>{{ props.item.doc_nr }}</td>
              <td>{{ props.item.iva_condition.name }}</td>
              
              <td class="justify-content-center px-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue" class="mr-2" @click="editItem(props.item)"> edit </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-1" @click="viewItem(props.item)"> remove_red_eye </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2" @click="deleteItem(props.item)"> delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { receiversService } from "@/libs/ws/receiversService";
import { generalService } from "@/libs/ws/generalService";
import { formatUtils } from "@/libs/utils/formatUtils";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import Modal from "./Modal.vue";
import RolMixin from "@/mixins/RolMixin.vue";

export default {
  components: { Title, Modal, DialogDelete },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      formatUtils: formatUtils,
      parent: this,
      service: receiversService,
      page_title: "Receptores",
      modal_title: "Receptor",
      client_selected: null,
      mainItem: {
        id: "",
        client: null,
        name: "",
        doc_type: null,
        doc_nr: null,
        iva_condition: null,
        address: "",
        transfer_type: null
      },
      defaultItem: null,
      headers: [
        {
          text: "Cliente",
          align: "left",
          sortable: true,
          value: "client.business_name",
        },
        {
          text: "Nombre",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Tipo de documento",
          align: "left",
          sortable: true,
          value: "doc_type.name",
        },
        {
          text: "Nº de documento",
          align: "left",
          sortable: true,
          value: "doc_nr",
        },
        {
          text: "Condición frente al IVA",
          align: "left",
          sortable: true,
          value: "iva_condition.name",
        },        
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
      clients: [],
      dialog_delete: false,
    };
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));    
    this.loadList(this.service, this.getFilterParams());
    this.loadComboData();
  },
  methods: {
    loadComboData() {
      generalService.filter({}, Constant.SECTION_CLIENTS).then((response) => {
        this.clients = response.result;
      });      
    },
    getFilterParams() {
      const param = {
        client: (this.client_selected != null)?this.client_selected.id:null,
      };
      return param;
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadList(this.service);
    },
  },
};

// TODO agregar un boton de limpiar busqueda.
</script>
