import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VDialog,
        {
          attrs: { transition: "dialog-bottom-transition", "max-width": "600" },
          on: {
            "click:outside": function ($event) {
              return _vm.$emit("closeDialog")
            },
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.$emit("closeDialog")
            },
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, { staticClass: "headline" }, [
                _vm._v("Carga rapida Actas / Libros / Fojas"),
              ]),
              _c(
                VCardText,
                [
                  _c("label", { staticClass: "fs-16 mt-3 mb-5" }, [
                    _vm._v("Ingrese los datos para la carga rápida"),
                  ]),
                  _c("br"),
                  _c("b", [
                    _c("label", { staticClass: "fs-16 mt-3" }, [
                      _vm._v("Acta"),
                    ]),
                  ]),
                  _c(
                    VRow,
                    { staticClass: "mt-2" },
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              dense: "",
                              "data-vv-as": "acta_desde",
                              label: "Desde",
                              name: "acta_desde",
                            },
                            model: {
                              value: _vm.model.acta_desde,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "acta_desde", $$v)
                              },
                              expression: "model.acta_desde",
                            },
                          }),
                          _c("span", { staticClass: "red--text" }, [
                            _vm._v(_vm._s(_vm.errors.first("acta_desde"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              dense: "",
                              "data-vv-as": "acta_hasta",
                              label: "Hasta",
                              name: "acta_hasta",
                            },
                            model: {
                              value: _vm.model.acta_hasta,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "acta_hasta", $$v)
                              },
                              expression: "model.acta_hasta",
                            },
                          }),
                          _c("span", { staticClass: "red--text" }, [
                            _vm._v(_vm._s(_vm.errors.first("acta_hasta"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c("b", [
                    _c("label", { staticClass: "fs-16 mt-3" }, [
                      _vm._v("Libro"),
                    ]),
                  ]),
                  _c(
                    VRow,
                    { staticClass: "mt-2" },
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              dense: "",
                              "data-vv-as": "libro_desde",
                              label: "Desde",
                              name: "libro_desde",
                            },
                            model: {
                              value: _vm.model.libro_desde,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "libro_desde", $$v)
                              },
                              expression: "model.libro_desde",
                            },
                          }),
                          _c("span", { staticClass: "red--text" }, [
                            _vm._v(_vm._s(_vm.errors.first("libro_desde"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              dense: "",
                              "data-vv-as": "libro_hasta",
                              label: "Hasta",
                              name: "libro_hasta",
                            },
                            model: {
                              value: _vm.model.libro_hasta,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "libro_hasta", $$v)
                              },
                              expression: "model.libro_hasta",
                            },
                          }),
                          _c("span", { staticClass: "red--text" }, [
                            _vm._v(_vm._s(_vm.errors.first("libro_hasta"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c("b", [
                    _c("label", { staticClass: "fs-16 mt-3" }, [
                      _vm._v("Foja"),
                    ]),
                  ]),
                  _c(
                    VRow,
                    { staticClass: "mt-2" },
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              dense: "",
                              "data-vv-as": "foja_desde",
                              label: "Desde",
                              name: "foja_desde",
                            },
                            model: {
                              value: _vm.model.foja_desde,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "foja_desde", $$v)
                              },
                              expression: "model.foja_desde",
                            },
                          }),
                          _c("span", { staticClass: "red--text" }, [
                            _vm._v(_vm._s(_vm.errors.first("foja_desde"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              dense: "",
                              "data-vv-as": "foja_hasta",
                              label: "Hasta",
                              name: "foja_hasta",
                            },
                            model: {
                              value: _vm.model.foja_hasta,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "foja_hasta", $$v)
                              },
                              expression: "model.foja_hasta",
                            },
                          }),
                          _c("span", { staticClass: "red--text" }, [
                            _vm._v(_vm._s(_vm.errors.first("foja_hasta"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VCardActions,
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "error", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("closeDialog")
                            },
                          },
                        },
                        [_vm._v("Cerrar")]
                      ),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "success", text: "" },
                          on: { click: _vm.QuickLoadProcess },
                        },
                        [_vm._v("Agregar")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }