<template>
  <div>    
    <header class="pdf-viewer__header box-shadow elevation-1">
      
      <div class="pdf-preview-toggle pdf-icons">
        <a @click.prevent.stop="togglePreview" class="icon">
          <font-awesome-icon icon="scroll" />
        </a>
      </div>
        
      <div class="pdf-icons">
        <a @click.prevent.stop="savePdf" class="icon" title="Guardar">
            <font-awesome-icon icon="floppy-disk" />
        </a>
      </div>

      <PDFZoom
        :scale="scale"
        @change="updateScale"
        @fit="updateFit"
        class="header-item"
        @exitModal="$emit('exitModal')"
        />

      <PDFPaginator
        v-model="currentPage"
        :pageCount="pageCount"
        class="header-item"
        />

      <slot name="header"></slot>
    </header>

    <PDFData
      class="pdf-viewer__main"
      :url="url"
      @page-count="updatePageCount"
      @page-focus="updateCurrentPage"
      @document-rendered="onDocumentRendered"
      @document-errored="onDocumentErrored"
      >
      <template v-slot:preview="{pages}">
        <PDFPreview
          v-show="isPreviewEnabled"
          class="pdf-viewer__preview"
          v-bind="{pages, scale, currentPage, pageCount, isPreviewEnabled}"
          />
      </template>

      <template v-slot:document="{pages}">
        <PDFDocument
          class="pdf-viewer__document"
          :class="{ 'preview-enabled': isPreviewEnabled }"
          v-bind="{pages, scale, optimalScale, fit, currentPage, pageCount, isPreviewEnabled, b64img}"
          @scale-change="updateScale"
          @imageDropped="imageDropped"
          @finishLoading="finishLoading"
          />
      </template>
    </PDFData>    
  </div>
</template>

<script>
import { pdfUtils } from '../utils';
import { PDFDocument as PDFDoc } from "pdf-lib";

import PDFDocument from './PDFDocument';
import PDFData from './PDFData';
import PDFPaginator from './PDFPaginator';
import PDFPreview from './PDFPreview';
import PDFZoom from './PDFZoom';

function floor(value, precision) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.floor(value * multiplier) / multiplier;
}

export default {
  name: 'PDFViewer',

  components: {
    PDFDocument,
    PDFData,
    PDFPaginator,
    PDFPreview,
    PDFZoom,
  },

  props: {
    url: String,
    b64img: String,
  },

  data() {
    return {
      scale: undefined,
      optimalScale: undefined,
      fit: undefined,
      currentPage: 1,
      pageCount: undefined,
      isPreviewEnabled: false,

      pdfDoc: null,
      loading: true,
    };
  },

  methods: {
    imageDropped(flag){
      if(flag){
        localStorage.setItem("page",this.currentPage-1);    
      }
    },
    onDocumentRendered() {
      this.$emit('document-errored', this.url);
    },

    onDocumentErrored(e) {
      this.$emit('document-errored', e);
    },

    updateScale({scale, isOptimal = false}) {      
      const roundedScale = floor(scale, 2);
      if (isOptimal) this.optimalScale = roundedScale;      
      this.scale = roundedScale;
    },

    updateFit(fit) {
      this.fit = fit;
    },

    updatePageCount(pageCount) {
      this.pageCount = pageCount;
    },

    updateCurrentPage(pageNumber, flag = false) {
      this.currentPage = pageNumber;      
    },

    togglePreview() {
      this.isPreviewEnabled = !this.isPreviewEnabled;
    },    
    getImageData(){
      let image = document.getElementById("dogPic")
      return {
        page_index: localStorage.getItem("page"),
        image_b64: image.toDataURL("image/png"),
        top: parseInt(image.style.top.replace("px","")),
        left: parseInt(image.style.left.replace("px",""))
      }
    },
    async savePdf(){             
      let pdfBufferArray = await pdfUtils.getBufferArray(this.url);         
      this.pdfDoc = await PDFDoc.load(pdfBufferArray);           
      await this.drawImageInPdf(this.getImageData());
      
      pdfBufferArray = await this.pdfDoc.save();
      /*
      let blob = new Blob([pdfBufferArray], { type: "application/pdf" });

      //Download
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);      
      link.download = "test.pdf";
      link.click();
      */
            
      this.$emit("save",pdfBufferArray);
    },
    async drawImageInPdf(imageData){
      //console.log(imageData)
      const pngImage = await this.pdfDoc.embedPng(imageData.image_b64);
      var browserZoomLevel = window.devicePixelRatio;
      const scale = (1/(this.optimalScale) * parseFloat(browserZoomLevel));//1/this.optimalScale;
      console.log("browserZoomLevel",browserZoomLevel)
      console.log("scale",scale)
      console.log("optimalScale",this.optimalScale)
      //console.log("page",localStorage.getItem("page"))

      const pngDims = pngImage.scale(scale);
      const pages = this.pdfDoc.getPages();
      const page_index = localStorage.getItem("page");
      let currentPage = pages[page_index];
      const { width, height } = currentPage.getSize();
      /*
      console.log("imageData.top",imageData.top)
      console.log("imageData.left",imageData.left)
      console.log("imageData.left*scale",imageData.left*scale)
      console.log("pngDims.width",pngDims.width)
      console.log("pngDims.height",pngDims.height)
      console.log("page.width",width)
      console.log("page.height",height)
      console.log("page_index",page_index)
      console.log("(height*page_index)",((height*page_index)))
      */
      
      let delta_y = 0;
      if(page_index == 0)
        delta_y = ((imageData.top*scale) - height*page_index*this.optimalScale) + pngDims.height;
      else
        delta_y = pngDims.height + ((imageData.top*scale) - height*page_index*this.optimalScale);

      const delta_x = width * (imageData.left/screen.availWidth);
      currentPage.drawImage(pngImage, {
        x: delta_x,
        y: height - delta_y + 7,//7Pointer  heigth
        width: pngDims.width,
        height: pngDims.height,
      });    
    },
    finishLoading(){
      console.log("finishLoading")
      
    }
  },

  watch: {
    url() {
      this.currentPage = undefined;
    },
  },

  mounted() {
    document.body.classList.add('overflow-hidden');
  },
};
</script>

<style scoped>
header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 1em;
  position: relative;
  z-index: 99;
}
.header-item {
  margin: 0 2.5em;
}

.pdf-viewer .pdf-viewer__document,
.pdf-viewer .pdf-viewer__preview {
  top: 70px;
}

.pdf-viewer__preview {
  display: block;
  width: 15%;
  right: 85%;
}

.pdf-viewer__document {
  top: 70px;
  width: 100%;
  left: 0;
}

.pdf-viewer__document.preview-enabled {
  width: 85%;
  left: 15%;
}

.pdf-icons a{
  float: left;
  cursor: pointer;
  display: block;
  padding:5px;
  background: #f4f4f4;
  color: #333;
  font-weight: bold;
  line-height: 1.5em;
  font-size: 1.5em;
}

.pdf-viewer__header{
  background: #f4f4f4;
}

@media print {
  header {
    display: none;
  }
}
</style>
