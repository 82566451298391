import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Title", {
        attrs: {
          pages: [{ icon: "file-invoice", page: _vm.page_title.toUpperCase() }],
        },
      }),
      _c(
        VContainer,
        { attrs: { fluid: "" } },
        [
          _vm.create_access
            ? _c("Modal", {
                attrs: {
                  button_name: _vm.$t("Crear") + " " + _vm.modal_title,
                  modal_title: _vm.modal_title,
                  formBtnName: _vm.formBtn,
                  dialog_modal: _vm.dialog,
                  service: _vm.service,
                  item: _vm.mainItem,
                },
                on: {
                  updateDialogParent: _vm.updateDialog,
                  loadList: function ($event) {
                    return _vm.loadList(_vm.service)
                  },
                  updateItem: function (newItem) {
                    _vm.mainItem = newItem
                  },
                },
              })
            : _vm._e(),
          _c("ModalFiles", {
            attrs: {
              dialog: _vm.dialog_files,
              task_name: _vm.taskNameSelected,
              documents: _vm.documentsSelected,
              criteria_price: _vm.criteriaPriceSelected,
              asigned_task: _vm.asignedTaskSelected,
              work_order: _vm.workOrderSelected,
            },
            on: { closeDialog: _vm.closeDialogFiles },
          }),
          _c(VCard, [
            _c("div", { staticClass: "row mb-5 mt-0" }, [
              _vm.list_access
                ? _c("div", { staticClass: "col-md-12" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-md-12",
                        staticStyle: { background: "white" },
                      },
                      [
                        _c(
                          VRow,
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              VCol,
                              { attrs: { cols: "12", md: "1", sm: "12" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "cursor text-filter",
                                    staticStyle: { "font-size": "20px" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.expandFilter()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" Filtros "),
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "filter" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              VCol,
                              {
                                staticClass: "text--secondary cursor",
                                attrs: { cols: "12", md: "10", sm: "12" },
                                on: {
                                  click: function ($event) {
                                    return _vm.expandFilter()
                                  },
                                },
                              },
                              [
                                _vm.open_filter
                                  ? _c("p", { staticClass: "mb-0 mt-1" }, [
                                      _vm._v("Seleccione los filtros deseados"),
                                    ])
                                  : _c(
                                      "span",
                                      [
                                        _vm.nro_orden_selected == null
                                          ? _c(
                                              VChip,
                                              {
                                                staticClass: "ml-1 mb-1",
                                                attrs: {
                                                  outlined: "",
                                                  color: "#666",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Desde: " +
                                                    _vm._s(_vm.desde_selected)
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.nro_orden_selected == null
                                          ? _c(
                                              VChip,
                                              {
                                                staticClass: "ml-1 mb-1",
                                                attrs: {
                                                  outlined: "",
                                                  color: "#666",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Hasta: " +
                                                    _vm._s(_vm.hasta_selected)
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.nro_orden_selected != null
                                          ? _c(
                                              VChip,
                                              {
                                                staticClass: "ml-1 mb-1",
                                                attrs: {
                                                  outlined: "",
                                                  color: "#666",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Nº orden: " +
                                                    _vm._s(
                                                      _vm.nro_orden_selected
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.state_selected != null
                                          ? _c(
                                              VChip,
                                              {
                                                staticClass: "ml-1 mb-1",
                                                attrs: {
                                                  outlined: "",
                                                  color: "#666",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Estado: " +
                                                    _vm._s(
                                                      _vm.state_selected.name
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.employee_selected != null
                                          ? _c(
                                              VChip,
                                              {
                                                staticClass: "ml-1 mb-1",
                                                attrs: {
                                                  outlined: "",
                                                  color: "#666",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Ejecutante: " +
                                                    _vm._s(
                                                      _vm.employee_selected.name
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.client_selected != null
                                          ? _c(
                                              VChip,
                                              {
                                                staticClass: "ml-1 mb-1",
                                                attrs: {
                                                  outlined: "",
                                                  color: "#666",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Cliente: " +
                                                    _vm._s(
                                                      _vm.client_selected
                                                        .business_name
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.applicant_selected != null
                                          ? _c(
                                              VChip,
                                              {
                                                staticClass: "ml-1 mb-1",
                                                attrs: {
                                                  outlined: "",
                                                  color: "#666",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Solicitante: " +
                                                    _vm._s(
                                                      _vm.applicant_selected
                                                        .name
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.task_selected != null
                                          ? _c(
                                              VChip,
                                              {
                                                staticClass: "ml-1 mb-1",
                                                attrs: {
                                                  outlined: "",
                                                  color: "#666",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Tarea: " +
                                                    _vm._s(
                                                      _vm.task_selected.name
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.acta_selected != null
                                          ? _c(
                                              VChip,
                                              {
                                                staticClass: "ml-1 mb-1",
                                                attrs: {
                                                  outlined: "",
                                                  color: "#666",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Acta: " +
                                                    _vm._s(_vm.acta_selected)
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.libro_selected != null
                                          ? _c(
                                              VChip,
                                              {
                                                staticClass: "ml-1 mb-1",
                                                attrs: {
                                                  outlined: "",
                                                  color: "#666",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Libro: " +
                                                    _vm._s(_vm.libro_selected)
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.foja_selected != null
                                          ? _c(
                                              VChip,
                                              {
                                                staticClass: "ml-1 mb-1",
                                                attrs: {
                                                  outlined: "",
                                                  color: "#666",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Foja: " +
                                                    _vm._s(_vm.foja_selected)
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                            _c(
                              VCol,
                              {
                                staticClass: "dropdown-filter",
                                attrs: { cols: "12", md: "1", sm: "12" },
                              },
                              [
                                !_vm.open_filter
                                  ? _c("font-awesome-icon", {
                                      staticClass: "cursor",
                                      attrs: { icon: "chevron-down" },
                                      on: {
                                        click: function ($event) {
                                          _vm.open_filter = true
                                        },
                                      },
                                    })
                                  : _c("font-awesome-icon", {
                                      staticClass: "cursor",
                                      attrs: { icon: "chevron-up" },
                                      on: {
                                        click: function ($event) {
                                          _vm.open_filter = false
                                        },
                                      },
                                    }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.open_filter
                          ? _c("hr", { staticClass: "mt-4" })
                          : _vm._e(),
                        _vm.open_filter
                          ? _c("div", { staticClass: "row pt-5" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-3 pt-0" },
                                [
                                  _c(
                                    VMenu,
                                    {
                                      ref: "desde_selected",
                                      attrs: {
                                        "close-on-content-click": false,
                                        "close-on-click": false,
                                        "return-value": _vm.desde_selected,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "max-width": "290px",
                                        "min-width": "auto",
                                        clearable: "",
                                      },
                                      on: {
                                        "update:returnValue": function (
                                          $event
                                        ) {
                                          _vm.desde_selected = $event
                                        },
                                        "update:return-value": function (
                                          $event
                                        ) {
                                          _vm.desde_selected = $event
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  VTextField,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "pt-0 mt-0",
                                                        attrs: {
                                                          label: "Desde",
                                                          "prepend-icon":
                                                            "mdi-calendar",
                                                          readonly: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.desde_selected,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.desde_selected =
                                                              $$v
                                                          },
                                                          expression:
                                                            "desde_selected",
                                                        },
                                                      },
                                                      "v-text-field",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2292088029
                                      ),
                                      model: {
                                        value: _vm.desde_selected_open,
                                        callback: function ($$v) {
                                          _vm.desde_selected_open = $$v
                                        },
                                        expression: "desde_selected_open",
                                      },
                                    },
                                    [
                                      _c(
                                        VDatePicker,
                                        {
                                          attrs: {
                                            type: "date",
                                            "no-title": "",
                                            scrollable: "",
                                          },
                                          model: {
                                            value: _vm.desde_selected,
                                            callback: function ($$v) {
                                              _vm.desde_selected = $$v
                                            },
                                            expression: "desde_selected",
                                          },
                                        },
                                        [
                                          _c(VSpacer),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.desde_selected = false
                                                },
                                              },
                                            },
                                            [_vm._v(" Cancel ")]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$refs.desde_selected.save(
                                                    _vm.desde_selected
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" OK ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3 pt-0" },
                                [
                                  _c(
                                    VMenu,
                                    {
                                      ref: "hasta_selected",
                                      attrs: {
                                        "close-on-content-click": false,
                                        "close-on-click": false,
                                        "return-value": _vm.hasta_selected,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "max-width": "290px",
                                        "min-width": "auto",
                                        clearable: "",
                                      },
                                      on: {
                                        "update:returnValue": function (
                                          $event
                                        ) {
                                          _vm.hasta_selected = $event
                                        },
                                        "update:return-value": function (
                                          $event
                                        ) {
                                          _vm.hasta_selected = $event
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  VTextField,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "pt-0 mt-0",
                                                        attrs: {
                                                          label: "Hasta",
                                                          "prepend-icon":
                                                            "mdi-calendar",
                                                          readonly: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.hasta_selected,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.hasta_selected =
                                                              $$v
                                                          },
                                                          expression:
                                                            "hasta_selected",
                                                        },
                                                      },
                                                      "v-text-field",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1999352541
                                      ),
                                      model: {
                                        value: _vm.hasta_selected_open,
                                        callback: function ($$v) {
                                          _vm.hasta_selected_open = $$v
                                        },
                                        expression: "hasta_selected_open",
                                      },
                                    },
                                    [
                                      _c(
                                        VDatePicker,
                                        {
                                          attrs: {
                                            type: "date",
                                            "no-title": "",
                                            scrollable: "",
                                          },
                                          model: {
                                            value: _vm.hasta_selected,
                                            callback: function ($$v) {
                                              _vm.hasta_selected = $$v
                                            },
                                            expression: "hasta_selected",
                                          },
                                        },
                                        [
                                          _c(VSpacer),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.hasta_selected = false
                                                },
                                              },
                                            },
                                            [_vm._v(" Cancel ")]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$refs.hasta_selected.save(
                                                    _vm.hasta_selected
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" OK ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3 pt-0" },
                                [
                                  _c(VTextField, {
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      autocomplete: "off",
                                      label: "Nº Orden",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.nro_orden_selected,
                                      callback: function ($$v) {
                                        _vm.nro_orden_selected = $$v
                                      },
                                      expression: "nro_orden_selected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3 pt-0" },
                                [
                                  _c(VCombobox, {
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      autocomplete: "off",
                                      "data-vv-as": "state_selected",
                                      name: "state_selected",
                                      "item-text": "name",
                                      "item-value": "id",
                                      items: _vm.states,
                                      label: "Estado",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.state_selected,
                                      callback: function ($$v) {
                                        _vm.state_selected = $$v
                                      },
                                      expression: "state_selected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3 pt-0" },
                                [
                                  _c(VCombobox, {
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      autocomplete: "off",
                                      "data-vv-as": "client_selected",
                                      name: "client_selected",
                                      "item-text": "business_name",
                                      "item-value": "id",
                                      items: _vm.clients,
                                      label: "Cliente",
                                      clearable: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.clientChangeCombo($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.client_selected,
                                      callback: function ($$v) {
                                        _vm.client_selected = $$v
                                      },
                                      expression: "client_selected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3 pt-0" },
                                [
                                  _c(VCombobox, {
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      autocomplete: "off",
                                      "data-vv-as": "applicant_selected",
                                      name: "applicant_selected",
                                      "item-text": "name",
                                      "item-value": "id",
                                      items: _vm.applicants,
                                      label: "Solicitante",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.applicant_selected,
                                      callback: function ($$v) {
                                        _vm.applicant_selected = $$v
                                      },
                                      expression: "applicant_selected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3 pt-0" },
                                [
                                  _c(VCombobox, {
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      autocomplete: "off",
                                      "data-vv-as": "tarea",
                                      name: "tarea",
                                      "item-text": "name",
                                      "item-value": "id",
                                      items: _vm.tasks,
                                      label: "Tarea",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.task_selected,
                                      callback: function ($$v) {
                                        _vm.task_selected = $$v
                                      },
                                      expression: "task_selected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3 pt-0" },
                                [
                                  _c(VTextField, {
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      autocomplete: "off",
                                      label: "Acta",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.acta_selected,
                                      callback: function ($$v) {
                                        _vm.acta_selected = $$v
                                      },
                                      expression: "acta_selected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3 pt-0" },
                                [
                                  _c(VTextField, {
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      autocomplete: "off",
                                      label: "Libro",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.libro_selected,
                                      callback: function ($$v) {
                                        _vm.libro_selected = $$v
                                      },
                                      expression: "libro_selected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3 pt-0" },
                                [
                                  _c(VTextField, {
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      autocomplete: "off",
                                      label: "Foja",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.foja_selected,
                                      callback: function ($$v) {
                                        _vm.foja_selected = $$v
                                      },
                                      expression: "foja_selected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3 pt-0 text-right" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { color: "info" },
                                      on: { click: _vm.filter },
                                    },
                                    [_vm._v("Buscar")]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm.list_access
            ? _c(
                VCard,
                [
                  _c(
                    VCardTitle,
                    [
                      _c("span", { staticClass: "text-uppercase" }, [
                        _vm._v(_vm._s(_vm.page_title)),
                      ]),
                      _c(VSpacer),
                      _c(VTextField, {
                        attrs: {
                          "append-icon": "search",
                          label: "Buscar",
                          "single-line": "",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(VDataTable, {
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.list,
                      search: _vm.search,
                      loading: _vm.loading,
                      "loading-text": "Cargando...",
                      options: {
                        descending: true,
                        itemsPerPage: 15,
                      },
                      "footer-props": {
                        itemsPerPageOptions: [5, 10, 15, 50, -1],
                      },
                      "item-key": "id",
                      "single-expand": false,
                      expanded: _vm.expanded,
                      "show-expand": "",
                      "show-select": "",
                      "single-select": false,
                    },
                    on: {
                      pagination: _vm.updatePagination,
                      "update:expanded": function ($event) {
                        _vm.expanded = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function (props) {
                            return [
                              _c("tr", [
                                _c(
                                  "td",
                                  [
                                    !props.isExpanded
                                      ? _c("font-awesome-icon", {
                                          staticClass: "cursor",
                                          attrs: { icon: "chevron-down" },
                                          on: {
                                            click: function ($event) {
                                              return props.expand(
                                                !props.isExpanded
                                              )
                                            },
                                          },
                                        })
                                      : _c("font-awesome-icon", {
                                          staticClass: "cursor",
                                          attrs: { icon: "chevron-up" },
                                          on: {
                                            click: function ($event) {
                                              return props.expand(
                                                !props.isExpanded
                                              )
                                            },
                                          },
                                        }),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  [
                                    _c(VCheckbox, {
                                      staticStyle: {
                                        margin: "0px",
                                        padding: "0px",
                                      },
                                      attrs: {
                                        "input-value": props.isSelected,
                                        "hide-details": "",
                                        disabled:
                                          props.item.state.id ==
                                          _vm.remitosWS.STATE_BILLED,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return props.select($event)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      props.item.id.toString().padStart(7, "0")
                                    )
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(props.item.applicant_date)),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(props.item.client.business_name)
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(props.item.asignated_tasks.length)
                                  ),
                                ]),
                                _c("td", [
                                  _c(
                                    "span",
                                    {
                                      class: _vm.renderExpirationDate(
                                        props.item.expiration_date
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          props.item.expiration_date != null
                                            ? props.item.expiration_date
                                            : "-"
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("td", {
                                  staticStyle: { "min-width": "135px" },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.service.getStateHtml(props.item.state)
                                    ),
                                  },
                                }),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center px-0",
                                    staticStyle: { "min-width": "135px" },
                                  },
                                  [
                                    props.item.state.id == 0
                                      ? _c(
                                          VTooltip,
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    return [
                                                      _vm.update_access
                                                        ? _c(
                                                            VIcon,
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                                attrs: {
                                                                  small: "",
                                                                  color: "blue",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.editItem(
                                                                        props.item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [_vm._v(" edit ")]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [_c("span", [_vm._v("Editar")])]
                                        )
                                      : _vm._e(),
                                    _c(
                                      VTooltip,
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    VIcon,
                                                    _vm._g(
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          small: "",
                                                          color: "blue",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.viewItem(
                                                              props.item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v(" remove_red_eye ")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Ver")])]
                                    ),
                                    props.item.state.id == 0
                                      ? _c(
                                          VTooltip,
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    return [
                                                      _vm.delete_access
                                                        ? _c(
                                                            VIcon,
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                                attrs: {
                                                                  small: "",
                                                                  color: "red",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.deleteItem(
                                                                        props.item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [_vm._v(" delete ")]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [_c("span", [_vm._v("Eliminar")])]
                                        )
                                      : _vm._e(),
                                    props.item.state_accounting.id > 0
                                      ? _c(
                                          VTooltip,
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        VIcon,
                                                        _vm._g(
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              small: "",
                                                              color: "orange",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.printCocepts(
                                                                  props.item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v(" print ")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Imprimir Remito"),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "expanded-item",
                          fn: function (ref) {
                            var headers = ref.headers
                            var item = ref.item
                            return [
                              _c(
                                "td",
                                { attrs: { colspan: headers.length } },
                                [
                                  _c(
                                    VDataTable,
                                    {
                                      staticClass:
                                        "background-color: grey lighten-3",
                                      attrs: {
                                        headers: _vm.headersTasks,
                                        items: item.asignated_tasks,
                                        search: _vm.search,
                                        "loading-text": "Cargando",
                                        "hide-default-footer": "",
                                        "disable-pagination": "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item",
                                            fn: function (props2) {
                                              return [
                                                _c("tr", [
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(props2.index + 1)
                                                    ),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        props2.item.task.name
                                                      )
                                                    ),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        props2.item.details
                                                          .length
                                                      )
                                                    ),
                                                  ]),
                                                  _c(
                                                    "td",
                                                    {
                                                      key: props2.item.documents
                                                        .length,
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            props2.item
                                                              .documents.length
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        props2.item.signatories
                                                          .length
                                                      )
                                                    ),
                                                  ]),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          props2.item
                                                            .priority_name
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          props2.item.state.name
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      _c(
                                                        VTooltip,
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      VIcon,
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "mr-2",
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                              color:
                                                                                "blue",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.viewFiles(
                                                                                  props2.item,
                                                                                  item
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          " mdi-file "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Ver documentos"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      [
                                        _c("thead", [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              { attrs: { colspan: "1" } },
                                              [_vm._v("Numero")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { colspan: "1" } },
                                              [_vm._v("Tarea")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { colspan: "4" },
                                              },
                                              [_vm._v("Informacion adicional")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { colspan: "1" },
                                              },
                                              [_vm._v("Prioridad")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { colspan: "1" },
                                              },
                                              [_vm._v("Estado")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { colspan: "1" },
                                              },
                                              [_vm._v("Accion")]
                                            ),
                                          ]),
                                        ]),
                                      ],
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "no-results",
                          fn: function () {
                            return [
                              _c(
                                VAlert,
                                {
                                  attrs: {
                                    value: true,
                                    color: "error",
                                    icon: "warning",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        "Busqueda sin resultados - " +
                                          _vm.search
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1990904256
                    ),
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("DialogLoading", {
            attrs: { dialog: _vm.dialog_loading, text: "Cargando..." },
          }),
          _c("DialogDelete", {
            attrs: { dialog_delete: _vm.dialog_delete },
            on: {
              updateDialogDelete: _vm.updateDialogDelete,
              deleteItemConfirm: _vm.deleteItemConfirm,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }