<template>
  <v-layout>
    <ModalTask
      :dialog="dialog_task"
      :item="item"
      @editAssignatedTasks="editAssignatedTasks"
      :client="item.client"
      :asignatedTaskItemEdit="asignatedTaskItemEdit"
      @closeDialog="closeModalTask"
      :editedIndex="editedIndex"
      :viewdIndex="viewdIndex"
    />

    <v-dialog v-bind:value="dialog_modal" @click:outside="$emit('updateDialogParent', false)" v-on:input="$emit('updateDialogParent', false)" transition="dialog-bottom-transition" fullscreen>
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark @click="$emit('updateDialogParent', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem" ref="modal_form">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text class="container mt-5 card-text-body">
            <!-- START FORM DIALOG -->

            <p class="text-h6 mb-5" v-if="item.id <= 0">Complete el formulario para crear una orden</p>
            <div class="row">
              <div class="col-md-12">
                <v-row>
                  <v-col md="3">
                    <v-combobox
                      v-model="item.client"
                      data-vv-as="cliente"
                      name="client"
                      item-text="business_name"
                      item-value="id"
                      :items="clients"
                      label="Cliente*"
                      autocomplete="off"
                      v-validate="'required'"                      
                      :disabled="isViewModal() || item.asignated_tasks.length > 0"
                    ></v-combobox>
                    <span class="red--text">{{ errors.first("client") }}</span>
                  </v-col>                  
                </v-row>

                <v-row>
                  <v-col md="6">
                    <v-textarea outlined v-model="item.observaciones_cliente" name="observaciones_cliente" label="Observaciones Cliente"></v-textarea>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn color="info" class="mb-3" @click="openModalTask" v-if="!isViewModal()">+ Agregar tarea</v-btn>
                  </v-col>
                </v-row>

                <v-data-table :headers="headers" :items="item.asignated_tasks" style="border: solid 1px #1c1c1c" :hide-default-footer="true" :items-per-page="100">
                  <template v-slot:item="props">
                    <tr>
                      <td>{{ props.index + 1 }}</td>
                      <td>{{ props.item.task.name }}</td>
                      <td>{{ props.item.details.length }}</td>
                      <td :key="props.item.documents.length">{{ props.item.documents.length }}</td>
                      <td>{{ props.item.signatories.length }}</td>
                      <td>{{ getPriorityName(props.item.priority) }}</td>
                      <td class="text-center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small color="blue" class="mr-1" @click="viewItem(props.item)"> remove_red_eye </v-icon>
                          </template>
                          <span>Ver</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="!isViewModal()">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small color="blue" class="mr-2" @click="editItem(props.item)"> edit </v-icon>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="!isViewModal()">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small color="red" @click="removeItem(props.item)"> indeterminate_check_box </v-icon>
                          </template>
                          <span>Remover</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:header="props">
                    <thead>
                      <tr>
                        <th colspan="1">Numero</th>
                        <th colspan="1">Tarea</th>
                        <th colspan="3" class="text-center">Informacion adicional</th>
                        <th colspan="1" class="text-center">Prioridad</th>
                        <th colspan="1" class="text-center">Accion</th>
                      </tr>
                    </thead>
                  </template>
                </v-data-table>
              </div>
            </div>
          </v-card-text>

          <v-card-actions class="card-footer-fullscreen">
            <v-spacer></v-spacer>
            <v-btn color="error" min-width="100" @click="$emit('updateDialogParent', false)">Cerrar</v-btn>
            <v-btn color="success" min-width="100" :loading="loading" type="submit" v-if="!isViewModal()">{{ formBtnName }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Constant from "@/constants/sections";
import UserType from "@/constants/user_type";
import ConstantEmployeeTypes from "@/constants/employee_types.js";
import ModalTask from "./ModalTask.vue";
import { orderService } from "@/libs/ws/orderService";
import { generalService } from "@/libs/ws/generalService";
import { fileUtils } from "@/libs/utils/fileUtils";

export default {
  props: {
    updateDialogParent: Function,
    service: Object,
    modal_title: String,
    dialog_modal: Boolean,
    item: Object,
    button_name: String,
    formBtnName: String,
  },
  components: { ModalTask },
  data() {
    return {
      loading: false,
      menu: false,
      show: false,
      dialog_task: false,
      clients: [],      
      editedIndex: -1,
      viewdIndex: -1,
      headers: [
        { text: "", value: "number", sortable: false },
        { text: "", value: "task", sortable: false },
        { text: "Cant. Actas/Libros/Fojas", value: "", sortable: false, class: "bg-grey" },
        { text: "Cant. de Documentos", value: "cant_documentos", sortable: false, class: "bg-grey" },
        { text: "Cant. de Firmas", value: "cant_firmas", sortable: false, class: "bg-grey" },
        { text: "", value: "priority", sortable: false },
        { text: "", value: "", sortable: false },
      ],
      asignatedTaskItemEdit: null,
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog_modal() {
      this.errors.clear();
    },
  },
  mounted() {    
    this.loadComboData();
    this.setWorkOrderApplicant();
  },
  methods: {
    setWorkOrderApplicant() {
      if (localStorage.getItem("user_type") == UserType.APPLICANT) {
        this.item.applicant = JSON.parse(localStorage.getItem("app_object"));
      }
    },
    isViewModal() {
      return this.formBtnName == "";
    },
    getPriorityName(p) {
      switch (p) {
        case 1:
          return "Baja";
        case 2:
          return "Media";
        case 3:
          return "Alta";
      }
    },
    loadComboData() {
      generalService.filter({}, Constant.SECTION_CLIENTS).then((response) => {
        this.clients = response.result;
      });
      //if (this.item.client != null && this.item.client.id > 0) this.clientChangeCombo();
    },
    /*
    clientChangeCombo(event) {
      var param = { client: this.item.client.id };
      generalService.filter(param, Constant.SECTION_APPLICANTS).then((response) => {
        this.applicants = response.result;
      });
    },
    */
    createItem(e, nextFlag = false) {
      return this.$validator.validate().then((valid) => {
        if (valid) {
          if (e != null) e.preventDefault();
          const data = orderService.getDataToRequest(this.item);
          this.loading = true;
          if (this.item.id > 0) {
            console.log("this.item",this.item)
            return this.service
              .update(data)
              .then((response) => {
                console.log(response);
                this.loading = false;
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialogParent", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
                this.loading = false;
              });
          } else {
            return orderService
              .create(data)
              .then((response) => {
                this.loading = false;
                console.log(response);
                if (response.result.id > 0) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialogParent", false);
                  this.$emit("loadList");
                  return response;
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                  return response;
                }
              })
              .catch((response) => {
                console.log(response);
                this.loading = false;
                this.$toast.error(response.msg, "Error");
                return response;
              });
          }
        } else {
          console.log("form incompleto", valid);
          console.log("form errors", this.errors);
        }
      });
    },
    viewItem(item) {
      this.viewdIndex = this.item.asignated_tasks.indexOf(item);
      this.asignatedTaskItemEdit = this.item.asignated_tasks[this.viewdIndex];
      this.dialog_task = true;
    },
    editItem(item) {
      this.editedIndex = this.item.asignated_tasks.indexOf(item);
      this.asignatedTaskItemEdit = this.item.asignated_tasks[this.editedIndex];
      this.dialog_task = true;
    },
    removeItem(item) {
      const index = this.item.asignated_tasks.indexOf(item);
      if (index > -1) {
        this.item.asignated_tasks.splice(index, 1);
      }
    },
    editAssignatedTasks(index, new_assignated_task) {
      let editedItem = { ...this.item.asignated_tasks[index], ...new_assignated_task };
      this.item.asignated_tasks.splice(index, 1, editedItem);
      this.asignatedTaskItemEdit = null;
    },
    closeModalTask() {
      this.dialog_task = false;
      this.asignatedTaskItemEdit = null;
      this.editedIndex = -1;
      this.viewdIndex = -1;
    },
    openModalTask() {
      this.$validator.validate("client").then((valid) => {
        if (valid) this.dialog_task = true;
        else this.$toast.error("Debe completar el cliente", "Error");
      });
    },
  },
};
</script>
