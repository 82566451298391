var CryptoJS = require("crypto-js");
var sha256 = require('js-sha256');

function hash_sha256(text) {
  return sha256(text);
}

function encrypt(plain_text, key) {
  if (plain_text != null) {
    const encrypted_text = CryptoJS.AES.encrypt(plain_text, key).toString();
    return encrypted_text;
  }
  return "";
}

function decrypt(enc_value, key, is_json = true) {
  if (enc_value != null) {
    const dec_bytes = CryptoJS.AES.decrypt(enc_value, key);
    var plain_text = dec_bytes.toString(CryptoJS.enc.Utf8);
    if (is_json) {
      return JSON.parse(plain_text);
    }
    return plain_text;
  }
  return "";
}

export const cryptoUtils = {
  encrypt,
  decrypt,
  hash_sha256,
};
