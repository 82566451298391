<template>
  <div>
    <Title
      :pages="[        
        { icon: '', page: page_title.toUpperCase() },
      ]"
    ></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :page_title="page_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <v-card v-if="list_access">
        <div class="row mb-5 mt-0">
          <div class="col-md-12" >
            <div class="col-md-12" style="background:white; elevation-0">
              <v-row no-gutters>
                <v-col cols="12" md="1" sm="12">
                  <span class="cursor text-filter" style="font-size: 20px" @click="expandFilter()">
                    Filtros
                    <font-awesome-icon icon="filter" />
                  </span>
                </v-col>
                <v-col cols="12" md="10" sm="12" class="text--secondary cursor" @click="expandFilter()">
                  <p class="mb-0 mt-1" v-if="open_filter">Seleccione los filtros deseados</p>
                  <span v-else>
                    <v-chip outlined color="#666" class="ml-1 mb-1" v-if="clients_selected.length > 0"> Clientes: {{ showClientsSelected() }}</v-chip>
                    <v-chip outlined color="#666" class="ml-1 mb-1" v-if="signatories_selected.length > 0"> Firmantes: {{ showSignatoriesSelected() }}</v-chip>                    
                  </span>
                </v-col>
                <v-col cols="12" md="1" sm="12" class="dropdown-filter">
                  <font-awesome-icon class="cursor" icon="chevron-down" v-if="!open_filter" @click="open_filter = true" />
                  <font-awesome-icon v-else class="cursor" icon="chevron-up" @click="open_filter = false" />
                </v-col>
              </v-row>
              <hr v-if="open_filter" class="mt-4" />
              <div class="row pt-5" v-if="open_filter">
                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="clients_selected"
                    autocomplete="off"
                    data-vv-as="clients_selected"
                    name="clients_selected"
                    item-text="business_name"
                    item-value="id"
                    :items="clients"
                    label="Clientes"
                    class="pt-0 mt-0"
                    v-on:change="clientChangeCombo($event)"
                    clearable
                    multiple
                    small-chips
                  ></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="signatories_selected"
                    autocomplete="off"
                    data-vv-as="signatories_selected"
                    name="signatories_selected"
                    item-text="alias"
                    item-value="id"
                    :items="signatories"
                    label="Firmantes"
                    class="pt-0 mt-0"                    
                    clearable
                    multiple
                    small-chips
                  ></v-combobox>
                </div>  

                <div class="col-md-3 pt-0">              
                </div>

                <div class="col-md-3 pt-0" style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>                
              </div>
            </div>
          </div>          
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ this.signatory_name }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.name }}</td>
              <td>
                <v-chip class="mr-1" v-for="obj in props.item.signatories" :key="obj.id" small> 
                  <span 
                    class="truncate"
                    :title="obj.alias"
                  >
                    {{ obj.alias }}
                  </span>                  
                </v-chip>
              </td>
              <td>{{ props.item.expiration_date != null ? props.item.expiration_date : "-" }}</td>
              <td class="justify-content-center layout px-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue" class="mr-2" @click="editItem(props.item)"> edit </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-1" @click="viewItem(props.item)"> remove_red_eye </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2" @click="deleteItem(props.item)"> delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import Modal from "./Modal.vue";
import RolMixin from "@/mixins/RolMixin.vue";

export default {
  components: { Title, Modal, DialogDelete },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      open_filter: false,
      signatories: [],
      clients: [],
      signatories_selected: [],
      clients_selected: [],
      signatoryId: null,
      signatory: null,
      signatory_name: "",
      parent: this,
      service: generalService,
      page_title: "Personerías",
      modal_title: "Personería ",
      mainItem: {
        id: "",
        name: "",
        description: "",
        expiration_date: null,
        signatories: [],
      },
      defaultItem: null,
      headers: [
        {
          text: "Nombre",
          align: "left",
          sortable: true,
          value: "name",
        },
        { text: "Firmantes", value: "", align: "left" },
        { text: "Fecha de expiración", value: "expiration_date", align: "left" },
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
      dialog_delete: false,
    };
  },
  mounted() {    
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.service.setSection(Constant.SECTION_PERSONALITIES);
    this.loadList(this.service);
    this.loadComboData();
  },
  methods: {
    getFilterParams() {
      const param = {
        signatories: this.signatories_selected.map((item) => item.id),
        clients: this.clients_selected.map((item) => item.id),
      };
      return param;
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    showSignatoriesSelected(){
      let signatories = this.signatories_selected.map((item) => item.alias);
      return signatories.join(", ");
    },
    showClientsSelected(){
      let clients = this.clients_selected.map((item) => item.business_name);
      return clients.join(", ");    
    },
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadList(this.service);
    },    
    loadComboData() {
      generalService.filter({}, Constant.SECTION_CLIENTS).then((response) => {
        this.clients = response.result;
      });

      generalService
        .filter({}, Constant.SECTION_SIGNATORIES)
        .then((response) => {          
          this.signatories = response.result;          
        })
        .catch((e) => {
          console.log("getSignatories error");
          console.log(e);
        });
    },
  },
};
</script>
