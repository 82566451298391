var CryptoJS = require("crypto-js");
import Constant from "@/constants/sections";

function getAccessOf(module_key) {
  const access_arr = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("access"), Constant.SECRET_PHRASE).toString(CryptoJS.enc.Utf8));      
  if(access_arr[module_key] == undefined)
  return {
    create: false,
    update: false,
    delete: false,
    sensible: false,
    sidebar: false
  }
  return access_arr[module_key];      
}

export const accessUtils = {
  getAccessOf,  
};
