import { wsService } from "./wsService";

const section = "remitos";
const STATE_UNBILLED = 0;
const STATE_BILLED = 1;
const STATE_CANCELED = 2;

function filter(param) {
  return wsService.make_post_request(section + "/list", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function massive_create(param = {}) {
  return wsService.make_post_request(section + "/massive_create", param);
}

function update(param = {}) {
  return wsService.make_put_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function export_filter(params) {
  return wsService.make_post_request(section + "/export", params, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/vnd.ms-excel",
    },
  });
}

function printOrder(order_id) {
  return wsService.make_post_request(
    section + "/print/" + order_id,
    {},
    {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
      },
    }
  );
}

function printCocepts(remito_id) {
  return wsService.make_post_request(
    section + "/print/concepts/" + remito_id,
    {},
    {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
      },
    }
  );
}

function printValues(remito_id) {  
  return wsService.make_post_request(
    section + "/print/values/" + remito_id,
    {},
    {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
      },
    }
  );
}

function get_states(param = {}) {
  return wsService.make_get_request(section + "/states", param);
}

function getStateHtml(state) {
  switch (parseInt(state.id)) {
    case STATE_UNBILLED:
      return '<p class="state_accounting_unbilled">' + state.name + "</p>";
    case STATE_BILLED:
      return '<p class="state_accounting_billed">' + state.name + "</p>";
    case STATE_CANCELED:
      return '<p class="state_accounting_canceled">' + state.name + "</p>";
    default:
      return "-";
  }
}

export const remitoService = {
  section,
  get_states,
  getStateHtml,
  filter,
  remove,
  create,
  massive_create,
  update,
  printOrder,
  printCocepts,
  printValues,
  export_filter,
  
  //Constants
  STATE_UNBILLED,
  STATE_BILLED,
};
