<template>
  <div>
    <Title :pages="[{ icon: 'user-tie', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :page_title="page_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.user.username }}</td>
              <td>{{ props.item.employee_type.name }}</td>
              <td>{{ props.item.user.role.name }}</td>
              <td>{{ props.item.user.enable ? "Si" : "No" }}</td>
              <td class="justify-content-center layout px-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue" class="mr-2" @click="editItem(props.item)"> edit </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-1" @click="viewItem(props.item)"> remove_red_eye </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2" @click="deleteItem(props.item)"> delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />
    </v-container>
  </div>
</template>

<script>
import { employeesService } from "@/libs/ws/employeesService";
import { filesService } from "@/libs/ws/filesService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import Modal from "./Modal.vue";
import RolMixin from "@/mixins/RolMixin.vue";

export default {
  components: { Title, Modal, DialogDelete },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      parent: this,
      service: employeesService,
      page_title: "Empleados",
      modal_title: "Empleado",
      mainItem: {
        id: "",
        name: "",
        employee_type: null,
        matricula: null,
        record: null,
        user: {
          role: null,
          enable: true,
        },
      },
      defaultItem: null,
      headers: [
        {
          text: "Nombre",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Usuario",
          align: "left",
          sortable: true,
          value: "user.username",
        },
        {
          text: "Tipo",
          align: "left",
          sortable: true,
          value: "employee_type.name",
        },
        {
          text: "Rol",
          align: "left",
          sortable: true,
          value: "user.role.name",
        },
        {
          text: "Habilitado",
          align: "left",
          sortable: true,
          value: "user.enable",
        },
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
      dialog_delete: false,
    };
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.loadList(this.service);
  },
  methods: {
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadList(this.service);
    },
    downloadSeal(employee) {
      this.loading = true;
      filesService
        .downloadFile(employee.seal_path, "image/jpg", "blob")
        .then((response) => {
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(response.data);
          link.download = employee.seal_path.split("/")[2];
          link.click();
          link.remove();
        })
        .catch((response) => {
          this.$toast.error(response.msg, "Error");
        })
        .finally(() => (this.loading = false));
    },
  },
};

// TODO agregar un boton de limpiar busqueda.
</script>
