import Vue from "vue";
import VueRouter from "vue-router";
import UserType from "@/constants/user_type";

import Home from "@/views/Home.vue";
import Login from "@/views/Login/Login.vue";
import Roles from "@/views/Roles/Roles.vue";

import Employees from "@/views/Employees/Employees.vue";
import Clients from "@/views/Clients/Clients.vue";
import Applicants from "@/views/Applicants/Applicants.vue";
import Cargos from "@/views/Cargos/Cargos.vue";
import Signatories from "@/views/Signatories/Signatories.vue";
import Personalities from "@/views/Personalities/Personalities.vue";

import Tasks from "@/views/General/Tasks/Tasks.vue";

import Certifications from "@/views/Certifications/Certifications.vue";
import Remitos from "@/views/Remitos/Remitos.vue";

import MonitoreoOrdenes from "@/views/Monitoring/MonitoreoOrdenes.vue";

//FACTURACION
import Emitters from "@/views/Billing/Emitters/Emitters.vue";
import Receivers from "@/views/Billing/Receivers/Receivers.vue";
import Vouchers from "@/views/Billing/Vouchers/Vouchers.vue";

//Applicant
import CertificationsClient from "@/views/ApplicantsViews/CertificationsClient/CertificationsClient.vue";
import ClientsClient from "@/views/ApplicantsViews/Clients/Clients.vue";

import Constant from "@/constants/sections";
import { cryptoUtils } from "@/libs/utils/cryptoUtils";
import { authService } from "@/libs/ws/authService";
import PropertySales from "../views/PropertySales/PropertySales.vue";
import Cupboard from "../views/Cupboard/Cupboard.vue";


//import Main from "@/views/PdfEditor2/Main.vue";

Vue.use(VueRouter);

/**
 * Routes applicant
 */
const routes_applicant = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      guest: true,
    },
  },
  { path: "/", redirect: "/" + Constant.SECTION_CERTIFICATION_ORDERS },

  {
    path: "/" + Constant.SECTION_CLIENTS,
    name: "Clientes",
    component: ClientsClient,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_CARGOS,
    name: "Cargos",
    component: Cargos,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_SIGNATORIES,
    name: "Firmantes",
    component: Signatories,
    meta: {
      requiresAuth: true,
    },
  },      

  {
    path: "/" + Constant.SECTION_CERTIFICATION_ORDERS,
    name: "Ordenes de certificaciíon",
    component: CertificationsClient,
    meta: {
      requiresAuth: true,
    },
  },  
];


/**
 * Routes general
 */
const routes = [
  /*
  {
    path: "/test_pdf",
    name: "Edicion de PDF",
    component: Main,
    meta: {
      guest: true,
    },
  },
  */
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      guest: true,
    },
  },
  { path: "/", redirect: "/" + Constant.SECTION_CERTIFICATION_ORDERS },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_ROLES,
    name: "Roles",
    component: Roles,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_EMPLOYEES,
    name: "Empleador",
    component: Employees,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_CLIENTS,
    name: "Clientes",
    component: Clients,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_APPLICANTS,
    name: "Solicitantes",
    component: Applicants,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_CARGOS,
    name: "Cargos",
    component: Cargos,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_SIGNATORIES,
    name: "Firmantes",
    component: Signatories,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_PERSONALITIES ,
    name: "Personerías",
    component: Personalities,
    meta: {
      requiresAuth: true,
    },
  },
  
  {
    path: "/" + Constant.SECTION_TASKS,
    name: "Nombres de tareas",
    component: Tasks,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/" + Constant.SECTION_CERTIFICATION_ORDERS,
    name: "Ordenes de certificaciíon",
    component: Certifications,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/" + Constant.SECTION_PROPERTY_SALES,
    name: "Carpetas",
    component: PropertySales,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/" + Constant.SECTION_CUPBOARD,
    name: "Cupboard",
    component: Cupboard,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/" + Constant.SECTION_MONITORING,
    name: "Monitoreo",
    component: MonitoreoOrdenes,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/" + Constant.SECTION_REMITOS,
    name: "Remitos",
    component: Remitos,
    meta: {
      requiresAuth: true,
    },
  },

  //FACTURACION
  {
    path: "/" + Constant.SECTION_EMITTERS,
    name: "Emisores",
    component: Emitters,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_RECEIVERS,
    name: "Receptores",
    component: Receivers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_VOUCHERS,
    name: "Comprobantes",
    component: Vouchers,
    meta: {
      requiresAuth: true,
    },
  },    
];

var router = null;
if( localStorage.getItem("user_type") == UserType.APPLICANT){ 
  router = new VueRouter({
    routes: routes_applicant,
  });
}else{
  router = new VueRouter({
    routes,
  });
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authService.isAuthenticated()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    if (authService.isAuthenticated() && to.path == "/login") {
      next({
        path: "/",
      });
    } else {
      next(); // make sure to always call next()!
    }
  }
});

export default router;
