<template>
<div>
  <canvas ref="PageRef" v-visible.once="drawPage" v-bind="canvasAttrs"></canvas>
  <canvas ref="ImgRef"
  v-if="pageNumber == 1"
      id="dogPic"
      style="
        cursor:pointer;
        position: absolute;
        z-index: 999;
        top: 0px; left: 0px;
        background: white;
      "></canvas>
</div>
</template>

<script>
import debug from 'debug';
const log = debug('app:components/PDFPage');

import {PIXEL_RATIO} from '../utils/constants';
import visible from '../directives/visible';

export default {
  name: 'PDFPage',

  props: {
    page: {
      type: Object, // instance of PDFPageProxy returned from pdf.getPage
      required: true,
    },
    b64img: {
      type: String,
      required: true,
    },
    scale: {
      type: Number,
      required: true,
    },
    optimalScale: {
      type: Number,
      required: true,
    },
    isPageFocused: {
      type: Boolean,
      default: false,
    },
    isElementFocused: {
      type: Boolean,
      default: false,
    },
    drawImage: {
      type: Boolean,
      default: false,
    },
    scrollValue: {
      type: Number,
      required: false,
    }    
  },
  data() {
    return {      
      pngImageBase64: null,
      moving: false,
      image: null,
      lastScrolled: 0,
      lastY: 0,
    }
  },

  directives: {
    visible,
  },

  computed: {
    actualSizeViewport() {
      return this.viewport.clone({scale: this.scale});
    },

    canvasStyle() {
      const {width: actualSizeWidth, height: actualSizeHeight} = this.actualSizeViewport;
      const [pixelWidth, pixelHeight] = [actualSizeWidth, actualSizeHeight]
        .map(dim => Math.ceil(dim / PIXEL_RATIO));
      return `width: ${pixelWidth}px; height: ${pixelHeight}px;`;
    },

    canvasAttrs() {
      let {width, height} = this.viewport;
      [width, height] = [width, height].map(dim => Math.ceil(dim));
      const style = this.canvasStyle;

      return {
        width,
        height,
        style,
        class: 'pdf-page box-shadow',
      };
    },

    pageNumber() {
      return this.page.pageNumber;
    },
  },
  methods: {
    init(){
      this.drawImageBoxAndSave();      
    },
    drawImageBoxAndSave(){
      const myImage = new Image();      
      let currentObjet = this;

      myImage.onload = function(){        
        console.log("this.scale",currentObjet.scale)
        const canvaImgCtx = currentObjet.$refs.ImgRef.getContext("2d");         
        currentObjet.$refs.ImgRef.width = myImage.width// * currentObjet.scale;
        currentObjet.$refs.ImgRef.height = myImage.height// * currentObjet.scale;       
        canvaImgCtx.drawImage(
          myImage,
          0,0
        );

        currentObjet.pngImageBase64 = currentObjet.$refs.ImgRef.toDataURL("image/png");         
        currentObjet.setUpMovingImg();
        currentObjet.$emit('finishLoading',true);
      }
      
      myImage.src = this.b64img;
                                     
    },
    setUpMovingImg(){ 
      /*
      let pdfBufferArray = await pdfUtils.getBufferArray(this.src);    
      let pdfDoc = await PDFDocument.load(pdfBufferArray); 
      */
      this.image = document.getElementById("dogPic");
      
      this.image.addEventListener("mousedown", this.initialClick, false);
      window.addEventListener('scrollTop', (event) => {
        this.handleScroll()
      });
    },
    initialClick(e) {                   
      if(this.moving){
        document.removeEventListener("mousemove", this.move);
        this.moving = !this.moving;                
        this.$emit('imageDropped',true);       
        return;
      }      
      this.moving = !this.moving;      
      document.addEventListener("mousemove", this.move, false);
    },
    handleScroll(diff){
      if(this.moving){        
        const oldY = parseInt(this.image.style.top.replace("px",""));
        this.image.style.top = (oldY+diff) + "px";
      }
    },
    move(e){               
      var newX = e.clientX - (this.image.width / 2);
      var newY = e.clientY - (this.image.height / 2) + this.scrollValue;            
      this.image.style.left = newX + "px";
      this.image.style.top = newY + "px";       
    },
    focusPage() {
      if (this.isPageFocused) return;

      this.$emit('page-focus', this.pageNumber);      
    },    
    drawPage() {
      if (this.renderTask) return;

      const {viewport} = this;
      const canvasContext = this.$refs.PageRef.getContext('2d');
      const renderContext = {canvasContext, viewport};

      // PDFPageProxy#render
      // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
      this.renderTask = this.page.render(renderContext);
      this.renderTask
        .then(() => {
          this.$emit('page-rendered', {
            page: this.page,
            text: `Rendered page ${this.pageNumber}`,
          });          
         })
        .catch(response => {
          this.destroyRenderTask();
          this.$emit('page-errored', {
            response,
            page: this.page,
            text: `Failed to render page ${this.pageNumber}`,
          });
        });        
    },

    updateVisibility() {
      this.$parent.$emit('update-visibility');
    },

    destroyPage(page) {
      // PDFPageProxy#_destroy
      // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
      if (page) page._destroy();

      this.destroyRenderTask();
    },

    destroyRenderTask() {
      if (!this.renderTask) return;

      // RenderTask#cancel
      // https://mozilla.github.io/pdf.js/api/draft/RenderTask.html
      this.renderTask.cancel();
      delete this.renderTask;
    },
  },

  watch: {
    scale: 'updateVisibility',

    page(_newPage, oldPage) {
      this.destroyPage(oldPage);
    },

    isElementFocused(isElementFocused) {
      if (isElementFocused) this.focusPage();
    },

    scrollValue(_new, old) {
      this.handleScroll(_new-old);
    },
  },

  created() {
    // PDFPageProxy#getViewport
    // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
    this.viewport = this.page.getViewport(this.optimalScale);    
  },

  mounted() {    
    log(`Page ${this.pageNumber} mounted`);    
    if(this.pageNumber == 1){
      this.init();      
    }
  },

  beforeDestroy() {
    this.destroyPage(this.page);
  },
};
</script>
<style>
.pdf-page {
  display: block;
  margin: 0 auto;
}
</style>
