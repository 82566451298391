<template>
  <div>
    <v-dialog v-model="open" max-width="500" @keydown.esc="$emit('cancel')" @click:outside="$emit('cancel')">
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>

        <v-card-text>
          <p class="mt-5 mb-3 fs-16 text-center" style="font-weight: bold">
            {{ message }}
          </p>
          <v-checkbox style="font-size: 14px" :label="checkbox_label" v-model="checkbox" />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="$emit('cancel')"> No </v-btn>

          <v-btn color="green darken-1" text @click="acceptMethod" :loading="loading"> Si </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    message: String,
    checkbox_label: String,
    open: Boolean,
    cancel: Function,
    accept: Function,
    loading: {
      default: false,
      checkbox: false,
      type: Boolean,
    },
  },
  data: () => ({
    checkbox: false,
  }),
  methods: {
    acceptMethod() {
      this.$emit("accept", this.checkbox);
    },
  },
};
</script>
