const POR_SI = "por_si";
const DIFIERA_FECHA = "difiera_fecha";
const DIFIERA_LUGAR = "difiera_lugar";
const DIFIERA_FECHA_LUGAR = "difiera_fecha_lugar";
const REGISTRO_AUTOMOTOR = "registro_automotor";
const IDIOMA_EXTRANJERO = "idioma_extranjero";

const STANDARD_SIGNATORY_TEXT = "{name}, D.N.I. Nº {dni}";
const STANDARD_SIGNATORY_END_TEXT = " ,quien acredita su identidad en los términos del artículo 306 inciso b) del Código Civil y Comercial de la Nación.";
const STANDARD_SIGNATORIES_END_TEXT = " ;quienes acreditan su identidad en los términos del artículo 306 inciso b) del Código Civil y Comercial de la Nación.";

const POR_SI_TEXT = "Concurre a este acto por derecho propio.";
const DIFIERA_FECHA_TEXT = "Se deja constancia que difiere la fecha del texto de la presente certificación.";
const DIFIERA_LUGAR_TEXT = "Se deja constancia que difiere el lugar del texto de la presente certificación.";
const DIFIERA_FECHA_LUGAR_TEXT = "Se deja constancia que difiere el lugar y la fecha del texto de la presente certificación.";
const REGISTRO_AUTOMOTOR_TEXT = "Se deja constancia que la presente certificación es en el formulario 08 número *** dominio número ***.";
const IDIOMA_EXTRANJERO_TEXT = "Dejo constancia que el documento objeto de la presente certificación se encuentra redactada en idioma extranjero el cual conozco.";

const getSignatoryText = (name, dni) => {
    const dniText = (dni != null && dni != "") ? dni : "FALTA_DNI_EN_FIRMANTE";
    return STANDARD_SIGNATORY_TEXT.replace("{name}", name).replace("{dni}", dniText);
};

const getTextByKey = (key) => {
    switch (key) {
        case POR_SI:
            return POR_SI_TEXT;
        case DIFIERA_FECHA:
            return DIFIERA_FECHA_TEXT;
        case DIFIERA_LUGAR:
            return DIFIERA_LUGAR_TEXT;
        case DIFIERA_FECHA_LUGAR:
            return DIFIERA_FECHA_LUGAR_TEXT;
        case REGISTRO_AUTOMOTOR:
            return REGISTRO_AUTOMOTOR_TEXT;
        case IDIOMA_EXTRANJERO:
            return IDIOMA_EXTRANJERO_TEXT;
        default:
            return "";
    }
}

export default { 
    POR_SI: POR_SI,
    DIFIERA_FECHA: DIFIERA_FECHA,    
    DIFIERA_LUGAR: DIFIERA_LUGAR,
    DIFIERA_FECHA_LUGAR: DIFIERA_FECHA_LUGAR,
    REGISTRO_AUTOMOTOR: REGISTRO_AUTOMOTOR,
    IDIOMA_EXTRANJERO: IDIOMA_EXTRANJERO,

    STANDARD_SIGNATORY_TEXT: STANDARD_SIGNATORY_TEXT,
    STANDARD_SIGNATORY_END_TEXT: STANDARD_SIGNATORY_END_TEXT,
    STANDARD_SIGNATORIES_END_TEXT: STANDARD_SIGNATORIES_END_TEXT,

    getSignatoryText: getSignatoryText,
    getTextByKey: getTextByKey
};
