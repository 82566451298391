import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Title", {
        attrs: {
          pages: [{ icon: "folder", page: _vm.page_title.toUpperCase() }],
        },
      }),
      _c(
        VContainer,
        { attrs: { fluid: "" } },
        [
          _vm.create_access
            ? _c("Modal", {
                attrs: {
                  button_name: _vm.$t("Crear") + " " + _vm.modal_title,
                  modal_title: _vm.modal_title,
                  formBtn: _vm.formBtn,
                  dialog: _vm.dialog,
                  parent: _vm.parent,
                  service: _vm.service,
                  item: _vm.mainItem,
                },
                on: {
                  updateDialog: _vm.updateDialog,
                  loadList: function ($event) {
                    return _vm.loadList(_vm.service)
                  },
                  updateMainItem: _vm.updateMainItem,
                  updateItem: function (newItem) {
                    _vm.mainItem = newItem
                  },
                },
              })
            : _vm._e(),
          _c(VCard, [
            _c("div", { staticClass: "row mb-5 mt-0" }, [
              _vm.list_access
                ? _c("div", { staticClass: "col-md-12" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-md-12",
                        staticStyle: { background: "white" },
                      },
                      [
                        _c(
                          VRow,
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              VCol,
                              { attrs: { cols: "12", md: "1", sm: "12" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "cursor text-filter",
                                    staticStyle: { "font-size": "20px" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.expandFilter()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" Filtros "),
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "filter" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              VCol,
                              {
                                staticClass: "text--secondary cursor",
                                attrs: { cols: "12", md: "10", sm: "12" },
                                on: {
                                  click: function ($event) {
                                    return _vm.expandFilter()
                                  },
                                },
                              },
                              [
                                _vm.open_filter
                                  ? _c("p", { staticClass: "mb-0 mt-1" }, [
                                      _vm._v("Seleccione los filtros deseados"),
                                    ])
                                  : _c(
                                      "span",
                                      [
                                        _vm.nro_carpeta_selected == null
                                          ? _c(
                                              VChip,
                                              {
                                                staticClass: "ml-1 mb-1",
                                                attrs: {
                                                  outlined: "",
                                                  color: "#666",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Desde: " +
                                                    _vm._s(_vm.desde_selected)
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.nro_carpeta_selected == null
                                          ? _c(
                                              VChip,
                                              {
                                                staticClass: "ml-1 mb-1",
                                                attrs: {
                                                  outlined: "",
                                                  color: "#666",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Hasta: " +
                                                    _vm._s(_vm.hasta_selected)
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.nro_carpeta_selected != null
                                          ? _c(
                                              VChip,
                                              {
                                                staticClass: "ml-1 mb-1",
                                                attrs: {
                                                  outlined: "",
                                                  color: "#666",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Nº carpeta: " +
                                                    _vm._s(
                                                      _vm.nro_carpeta_selected
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.state_selected != null
                                          ? _c(
                                              VChip,
                                              {
                                                staticClass: "ml-1 mb-1",
                                                attrs: {
                                                  outlined: "",
                                                  color: "#666",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Estado: " +
                                                    _vm._s(
                                                      _vm.state_selected.name
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.notary_selected != null
                                          ? _c(
                                              VChip,
                                              {
                                                staticClass: "ml-1 mb-1",
                                                attrs: {
                                                  outlined: "",
                                                  color: "#666",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Escribano: " +
                                                    _vm._s(
                                                      _vm.notary_selected.name
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.folder_manager_selected != null
                                          ? _c(
                                              VChip,
                                              {
                                                staticClass: "ml-1 mb-1",
                                                attrs: {
                                                  outlined: "",
                                                  color: "#666",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Carpetista: " +
                                                    _vm._s(
                                                      _vm
                                                        .folder_manager_selected
                                                        .name
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.seller_selected != null
                                          ? _c(
                                              VChip,
                                              {
                                                staticClass: "ml-1 mb-1",
                                                attrs: {
                                                  outlined: "",
                                                  color: "#666",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Vendedor: " +
                                                    _vm._s(
                                                      _vm.seller_selected
                                                        .business_name
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.buyer_selected != null
                                          ? _c(
                                              VChip,
                                              {
                                                staticClass: "ml-1 mb-1",
                                                attrs: {
                                                  outlined: "",
                                                  color: "#666",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Comprador: " +
                                                    _vm._s(
                                                      _vm.buyer_selected
                                                        .business_name
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                            _c(
                              VCol,
                              {
                                staticClass: "dropdown-filter",
                                attrs: { cols: "12", md: "1", sm: "12" },
                              },
                              [
                                !_vm.open_filter
                                  ? _c("font-awesome-icon", {
                                      staticClass: "cursor",
                                      attrs: { icon: "chevron-down" },
                                      on: {
                                        click: function ($event) {
                                          _vm.open_filter = true
                                        },
                                      },
                                    })
                                  : _c("font-awesome-icon", {
                                      staticClass: "cursor",
                                      attrs: { icon: "chevron-up" },
                                      on: {
                                        click: function ($event) {
                                          _vm.open_filter = false
                                        },
                                      },
                                    }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.open_filter
                          ? _c("hr", { staticClass: "mt-4" })
                          : _vm._e(),
                        _vm.open_filter
                          ? _c("div", { staticClass: "row pt-5" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-3 pt-0" },
                                [
                                  _c(
                                    VMenu,
                                    {
                                      ref: "desde_selected",
                                      attrs: {
                                        "close-on-content-click": false,
                                        "close-on-click": false,
                                        "return-value": _vm.desde_selected,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "max-width": "290px",
                                        "min-width": "auto",
                                        clearable: "",
                                      },
                                      on: {
                                        "update:returnValue": function (
                                          $event
                                        ) {
                                          _vm.desde_selected = $event
                                        },
                                        "update:return-value": function (
                                          $event
                                        ) {
                                          _vm.desde_selected = $event
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  VTextField,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "pt-0 mt-0",
                                                        attrs: {
                                                          label: "Desde",
                                                          "prepend-icon":
                                                            "mdi-calendar",
                                                          readonly: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.desde_selected,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.desde_selected =
                                                              $$v
                                                          },
                                                          expression:
                                                            "desde_selected",
                                                        },
                                                      },
                                                      "v-text-field",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2292088029
                                      ),
                                      model: {
                                        value: _vm.desde_selected_open,
                                        callback: function ($$v) {
                                          _vm.desde_selected_open = $$v
                                        },
                                        expression: "desde_selected_open",
                                      },
                                    },
                                    [
                                      _c(
                                        VDatePicker,
                                        {
                                          attrs: {
                                            type: "date",
                                            "no-title": "",
                                            scrollable: "",
                                          },
                                          model: {
                                            value: _vm.desde_selected,
                                            callback: function ($$v) {
                                              _vm.desde_selected = $$v
                                            },
                                            expression: "desde_selected",
                                          },
                                        },
                                        [
                                          _c(VSpacer),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.desde_selected = false
                                                },
                                              },
                                            },
                                            [_vm._v(" Cancel ")]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$refs.desde_selected.save(
                                                    _vm.desde_selected
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" OK ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3 pt-0" },
                                [
                                  _c(
                                    VMenu,
                                    {
                                      ref: "hasta_selected",
                                      attrs: {
                                        "close-on-content-click": false,
                                        "close-on-click": false,
                                        "return-value": _vm.hasta_selected,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "max-width": "290px",
                                        "min-width": "auto",
                                        clearable: "",
                                      },
                                      on: {
                                        "update:returnValue": function (
                                          $event
                                        ) {
                                          _vm.hasta_selected = $event
                                        },
                                        "update:return-value": function (
                                          $event
                                        ) {
                                          _vm.hasta_selected = $event
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  VTextField,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "pt-0 mt-0",
                                                        attrs: {
                                                          label: "Hasta",
                                                          "prepend-icon":
                                                            "mdi-calendar",
                                                          readonly: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.hasta_selected,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.hasta_selected =
                                                              $$v
                                                          },
                                                          expression:
                                                            "hasta_selected",
                                                        },
                                                      },
                                                      "v-text-field",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1999352541
                                      ),
                                      model: {
                                        value: _vm.hasta_selected_open,
                                        callback: function ($$v) {
                                          _vm.hasta_selected_open = $$v
                                        },
                                        expression: "hasta_selected_open",
                                      },
                                    },
                                    [
                                      _c(
                                        VDatePicker,
                                        {
                                          attrs: {
                                            type: "date",
                                            "no-title": "",
                                            scrollable: "",
                                          },
                                          model: {
                                            value: _vm.hasta_selected,
                                            callback: function ($$v) {
                                              _vm.hasta_selected = $$v
                                            },
                                            expression: "hasta_selected",
                                          },
                                        },
                                        [
                                          _c(VSpacer),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.hasta_selected = false
                                                },
                                              },
                                            },
                                            [_vm._v(" Cancel ")]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$refs.hasta_selected.save(
                                                    _vm.hasta_selected
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" OK ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3 pt-0" },
                                [
                                  _c(VTextField, {
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      autocomplete: "off",
                                      label: "Nº Carpeta",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.nro_carpeta_selected,
                                      callback: function ($$v) {
                                        _vm.nro_carpeta_selected = $$v
                                      },
                                      expression: "nro_carpeta_selected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3 pt-0" },
                                [
                                  _c(VCombobox, {
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      autocomplete: "off",
                                      "data-vv-as": "notary_selected",
                                      name: "notary_selected",
                                      "item-text": "name",
                                      "item-value": "id",
                                      items: _vm.employees_notary,
                                      label: "Escribano",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.notary_selected,
                                      callback: function ($$v) {
                                        _vm.notary_selected = $$v
                                      },
                                      expression: "notary_selected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3 pt-0" },
                                [
                                  _c(VCombobox, {
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      autocomplete: "off",
                                      "data-vv-as": "folder_manager_selected",
                                      name: "folder_manager_selected",
                                      "item-text": "name",
                                      "item-value": "id",
                                      items: _vm.employees_employee,
                                      label: "Carpetista",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.folder_manager_selected,
                                      callback: function ($$v) {
                                        _vm.folder_manager_selected = $$v
                                      },
                                      expression: "folder_manager_selected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3 pt-0" },
                                [
                                  _c(VCombobox, {
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      autocomplete: "off",
                                      "data-vv-as": "seller_selected",
                                      name: "seller_selected",
                                      "item-text": "business_name",
                                      "item-value": "id",
                                      items: _vm.clients,
                                      label: "Vendedor",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.seller_selected,
                                      callback: function ($$v) {
                                        _vm.seller_selected = $$v
                                      },
                                      expression: "seller_selected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3 pt-0" },
                                [
                                  _c(VCombobox, {
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      autocomplete: "off",
                                      "data-vv-as": "buyer_selected",
                                      name: "buyer_selected",
                                      "item-text": "business_name",
                                      "item-value": "id",
                                      items: _vm.clients,
                                      label: "Comprador",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.buyer_selected,
                                      callback: function ($$v) {
                                        _vm.buyer_selected = $$v
                                      },
                                      expression: "buyer_selected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3 pt-0" },
                                [
                                  _c(VCombobox, {
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      autocomplete: "off",
                                      "data-vv-as": "state_selected",
                                      name: "state_selected",
                                      "item-text": "name",
                                      "item-value": "id",
                                      items: _vm.states,
                                      label: "Estado",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.state_selected,
                                      callback: function ($$v) {
                                        _vm.state_selected = $$v
                                      },
                                      expression: "state_selected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col-md-12 pt-0",
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "end",
                                  },
                                },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { color: "info" },
                                      on: { click: _vm.filter },
                                    },
                                    [_vm._v("Buscar")]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm.list_access
            ? _c(
                VCard,
                [
                  _c(
                    VCardTitle,
                    [
                      _c("span", { staticClass: "text-uppercase" }, [
                        _vm._v(_vm._s(_vm.page_title)),
                      ]),
                      _c(VSpacer),
                      _c(VTextField, {
                        attrs: {
                          "append-icon": "search",
                          label: "Buscar",
                          "single-line": "",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(VDataTable, {
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.list,
                      search: _vm.search,
                      loading: _vm.loading,
                      "loading-text": "Cargando...",
                      options: {
                        descending: true,
                        itemsPerPage: 15,
                      },
                      "footer-props": {
                        itemsPerPageOptions: [5, 10, 15, 50, -1],
                      },
                    },
                    on: { pagination: _vm.updatePagination },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function (props) {
                            return [
                              _c("tr", [
                                _c("td", [_vm._v(_vm._s(props.item.id))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      new Date(
                                        props.item.open_date
                                      ).toLocaleDateString()
                                    )
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      props.item.notary
                                        ? props.item.notary.name
                                        : ""
                                    )
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      props.item.folder_manager
                                        ? props.item.folder_manager.name
                                        : ""
                                    )
                                  ),
                                ]),
                                _c("td", [_vm._v(_vm._s(props.item.address))]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatUtils.formatPrice(
                                        props.item.amount
                                      )
                                    )
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      props.item.signature_date != ""
                                        ? new Date(
                                            props.item.signature_date
                                          ).toLocaleDateString()
                                        : "-"
                                    )
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(props.item.signature_location)),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(props.item.written_sale_folio)),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(props.item.written_sale_numero)
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(props.item.written_mortage_folio)
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(props.item.written_mortage_numero)
                                  ),
                                ]),
                                _c("td", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.service.getStateHtml(props.item.state)
                                    ),
                                  },
                                }),
                                _c(
                                  "td",
                                  {
                                    staticClass: "justify-content-center px-0",
                                  },
                                  [
                                    _c(
                                      VTooltip,
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    VIcon,
                                                    _vm._g(
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          small: "",
                                                          color: "orange",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.printPDF(
                                                              props.item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v(" print ")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Imprimir carpeta")])]
                                    ),
                                    _c(
                                      VTooltip,
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                return [
                                                  _vm.update_access
                                                    ? _c(
                                                        VIcon,
                                                        _vm._g(
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              small: "",
                                                              color: "blue",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.editItem(
                                                                  props.item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v(" edit ")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Editar")])]
                                    ),
                                    _c(
                                      VTooltip,
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    VIcon,
                                                    _vm._g(
                                                      {
                                                        staticClass: "mr-1",
                                                        attrs: {
                                                          small: "",
                                                          color: "blue",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.viewItem(
                                                              props.item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v(" remove_red_eye ")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Ver")])]
                                    ),
                                    _c(
                                      VTooltip,
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                return [
                                                  _vm.delete_access
                                                    ? _c(
                                                        VIcon,
                                                        _vm._g(
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              small: "",
                                                              color: "red",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteItem(
                                                                  props.item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v(" delete ")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Eliminar")])]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "no-results",
                          fn: function () {
                            return [
                              _c(
                                VAlert,
                                {
                                  attrs: {
                                    value: true,
                                    color: "error",
                                    icon: "warning",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        "Busqueda sin resultados - " +
                                          _vm.search
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3610540949
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("DialogLoading", {
            attrs: { dialog: _vm.dialog_loading, text: "Cargando..." },
          }),
          _c("DialogDelete", {
            attrs: { dialog_delete: _vm.dialog_delete },
            on: {
              updateDialogDelete: _vm.updateDialogDelete,
              deleteItemConfirm: _vm.deleteItemConfirm,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }