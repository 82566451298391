import { wsService } from "./wsService";
import sections from "@/constants/sections";

const section = sections.SECTION_EMITTERS;

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_post_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function cbteTiposOthers(emitter_id) {
  return wsService.make_post_request(section + "/cbte_tipos", {emitter: emitter_id, voucher_types: 'others'});
}

function ptosvtas(emitter_id) {
  return wsService.make_post_request(section + "/ptosvtas", {emitter: emitter_id});
}

function conceptos(emitter_id) {
  return wsService.make_post_request(section + "/conceptos", {emitter: emitter_id});
}

function monedas(emitter_id) {
  return wsService.make_post_request(section + "/monedas", {emitter: emitter_id});
}

function ivas(emitter_id) {
  return wsService.make_post_request(section + "/ivas", {emitter: emitter_id});
}

function tributos(emitter_id) {
  return wsService.make_post_request(section + "/tributos", {emitter: emitter_id});
}

export const emmiterService = {
  section,
  filter,
  remove,
  create,
  update,  
  cbteTiposOthers,
  ptosvtas,
  conceptos,
  monedas,
  ivas,
  tributos,
};
