import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { class: _vm.center ? "d-flex justify-content-center" : "" },
    [
      _c(
        VDialog,
        {
          attrs: { value: _vm.dialog, "max-width": "1200" },
          on: {
            "click:outside": function ($event) {
              return _vm.$emit("updateDialog", false)
            },
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.$emit("updateDialog", false)
            },
          },
          scopedSlots: _vm._u(
            [
              _vm.show_create_button
                ? {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      return [
                        _c(
                          VBtn,
                          _vm._g(
                            {
                              staticClass: "mb-5",
                              attrs: { color: "primary", dark: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.openModal()
                                },
                              },
                            },
                            on
                          ),
                          [_vm._v(_vm._s(_vm.button_name))]
                        ),
                      ]
                    },
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c(
            VCard,
            [
              _c(
                VForm,
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.createItem.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(VCardTitle, [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v(_vm._s(_vm.modal_title)),
                    ]),
                  ]),
                  _c(VCardText, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c(VCombobox, {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              "data-vv-as": "signatories",
                              name: "signatories",
                              "item-text": "alias",
                              "item-value": "id",
                              items: _vm.signatories,
                              label: "Firmantes*",
                              autocomplete: "off",
                              disabled: _vm.isViewModal(),
                              clearable: "",
                              multiple: "",
                              "small-chips": "",
                            },
                            on: { change: _vm.selectedSignatories },
                            model: {
                              value: _vm.item.signatories,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "signatories", $$v)
                              },
                              expression: "item.signatories",
                            },
                          }),
                          _c("span", { staticClass: "red--text" }, [
                            _vm._v(_vm._s(_vm.errors.first("signatories"))),
                          ]),
                          _c(VTextField, {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              "data-vv-as": "name",
                              label: "Nombre*",
                              name: "name",
                              disabled: _vm.isViewModal(),
                            },
                            model: {
                              value: _vm.item.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          }),
                          _c("span", { staticClass: "red--text" }, [
                            _vm._v(_vm._s(_vm.errors.first("name"))),
                          ]),
                          _c("DatePickerEsp", {
                            attrs: {
                              label: "Fecha de expiración",
                              initToday: false,
                              date: _vm.item.expiration_date,
                              isRequired: false,
                              name: "expiration_date",
                              isViewModal: _vm.isViewModal(),
                            },
                            on: {
                              setDate: function (date) {
                                _vm.item.expiration_date = date
                              },
                            },
                          }),
                          _c(
                            VRow,
                            {
                              staticClass: "mt-4",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-md-4 pl-2 pr-2" },
                                [
                                  !_vm.isViewModal()
                                    ? _c(
                                        VBtn,
                                        {
                                          staticClass: "primary warning w-100",
                                          attrs: { text: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.autoFill(
                                                _vm.PersonalityConstants.POR_SI
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("1 - Por sí")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-4 pl-2 pr-2" },
                                [
                                  !_vm.isViewModal()
                                    ? _c(
                                        VBtn,
                                        {
                                          staticClass: "primary warning w-100",
                                          attrs: { text: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.autoFill(
                                                _vm.PersonalityConstants
                                                  .REGISTRO_AUTOMOTOR
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("2 - Registro automotor")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-4 pl-2 pr-2" },
                                [
                                  !_vm.isViewModal()
                                    ? _c(
                                        VBtn,
                                        {
                                          staticClass: "primary warning w-100",
                                          attrs: { text: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.autoFill(
                                                _vm.PersonalityConstants
                                                  .IDIOMA_EXTRANJERO
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("3 - Idioma extranjero")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            VRow,
                            {
                              staticClass: "mt-4",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-md-4 pl-2 pr-2" },
                                [
                                  !_vm.isViewModal()
                                    ? _c(
                                        VBtn,
                                        {
                                          staticClass: "primary warning w-100",
                                          attrs: { text: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.autoFill(
                                                _vm.PersonalityConstants
                                                  .DIFIERA_FECHA
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("4 - Difiera fecha")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-4 pl-2 pr-2" },
                                [
                                  !_vm.isViewModal()
                                    ? _c(
                                        VBtn,
                                        {
                                          staticClass: "primary warning w-100",
                                          attrs: { text: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.autoFill(
                                                _vm.PersonalityConstants
                                                  .DIFIERA_LUGAR
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("5 - Difiera lugar")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-4 pl-2 pr-2" },
                                [
                                  !_vm.isViewModal()
                                    ? _c(
                                        VBtn,
                                        {
                                          staticClass: "primary warning w-100",
                                          attrs: { text: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.autoFill(
                                                _vm.PersonalityConstants
                                                  .DIFIERA_FECHA_LUGAR
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("6 - Difiera fecha y lugar")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(VTextarea, {
                            ref: "description_textarea",
                            staticClass: "mt-4",
                            attrs: {
                              rows: "12",
                              outlined: "",
                              name: "description",
                              label: "Descripción*",
                              disabled: _vm.isViewModal(),
                            },
                            model: {
                              value: _vm.item.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "description", $$v)
                              },
                              expression: "item.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c(
                    VCardActions,
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "error", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("updateDialog", false)
                            },
                          },
                        },
                        [_vm._v("Cerrar")]
                      ),
                      !_vm.isViewModal()
                        ? _c(
                            VBtn,
                            {
                              attrs: {
                                color: "success",
                                text: "",
                                type: "submit",
                                loading: _vm.loading,
                              },
                            },
                            [_vm._v(_vm._s(_vm.formBtn))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }