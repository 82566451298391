import { render, staticRenderFns } from "./Employees.vue?vue&type=template&id=15a9d591&"
import script from "./Employees.vue?vue&type=script&lang=js&"
export * from "./Employees.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\WWW\\escibania\\Frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15a9d591')) {
      api.createRecord('15a9d591', component.options)
    } else {
      api.reload('15a9d591', component.options)
    }
    module.hot.accept("./Employees.vue?vue&type=template&id=15a9d591&", function () {
      api.rerender('15a9d591', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Employees/Employees.vue"
export default component.exports