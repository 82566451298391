import axios from "axios";
import { wsService } from "./wsService";
import Constant from "@/constants/sections";

const section = Constant.SECTION_ASSIGNATED_TASK;
const api_url = process.env.VUE_APP_API_URL;

function update_state(param = {}) {
  return wsService.make_put_request(section + "/update_state", param);
}

function get_history_state_assigned_task(assigned_task_id) {
  return wsService.make_post_request(section + "/get_history", { assigned_task_id: assigned_task_id });
}

/*
function export_filter(params) {
  return wsService.make_post_request(section + "/export", params, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/vnd.ms-excel",
    },
  });
}
*/

function gen_seal(assigned_task_id,document_id,params) {
  return wsService.make_post_request(section + "/gen_seal/"+assigned_task_id+"/"+document_id, params);
}

function save_seal(params) {
  return wsService.make_post_request(section + "/save_seal", params);
}

function printFoja(params, accept = "application/pdf", responseType = "arraybuffer") {  
  const token = localStorage.getItem("token");  
  return axios
      .post(api_url + "assigned_task/print_foja/"+params.assigned_task_id+"/"+params.document_id, params,
      {
          responseType: responseType,
          headers: {
              'Accept': accept,
              "Content-Type": "application/json; charset=utf-8",
              'Authorization': "Bearer " + token,
          }
      })
}

export const assignatesTaskService = {
  section,
  update_state,
  get_history_state_assigned_task,
  gen_seal,
  save_seal,
  printFoja,
};
