import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VDialog,
        {
          attrs: { transition: "dialog-bottom-transition", "max-width": "600" },
          on: {
            "click:outside": function ($event) {
              return _vm.$emit("closeDialog")
            },
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, { staticClass: "headline" }, [
                _vm._v("Documentos"),
              ]),
              _c(
                VCardText,
                [
                  _c("label", { staticClass: "fs-16 mt-3" }, [
                    _vm._v(
                      "Descargue los documentos de la tarea " +
                        _vm._s(_vm.task_name)
                    ),
                  ]),
                  _c(VDataTable, {
                    staticClass: "pb-4 mt-3",
                    staticStyle: { border: "solid 1px #1c1c1c" },
                    attrs: {
                      headers: _vm.headers_documents,
                      items: _vm.documents,
                      "hide-default-footer": true,
                      "items-per-page": 100,
                      loading: _vm.loading,
                      "loading-text": "Cargando...",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function (props) {
                          return [
                            _c("tr", [
                              _c("td", [
                                _vm._v(" " + _vm._s(props.index + 1) + " "),
                              ]),
                              _c("td", [_vm._v(_vm._s(props.item.name))]),
                              _c(
                                "td",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  VIcon,
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        color: "green",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.download(
                                                            props.item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v(" download ")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [_c("span", [_vm._v("Descargar")])]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VCardActions,
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "error", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("closeDialog")
                            },
                          },
                        },
                        [_vm._v("Cerrar")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }