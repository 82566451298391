<template>
  <div>
    <v-dialog v-model="dialog" @click:outside="close()" transition="dialog-bottom-transition" width="600px">
      <v-card>
        <v-card-title class="headline">Firmar documento</v-card-title>

        <v-card-text>
          <label class="fs-16 mt-4 mb-3">Seleccione el numero de foja con la cual desea firmar el documento</label>

          <div class="row justify-content-md-center">
            <div class="col-md-6 mt-5">
              
              <DatePickerEsp
                v-if="!isCertificacionFirma()"
                label="Fecha"
                :initToday="true"
                :date="seal.fecha"
                name="fecha"
                @setDate="
                  (date) => {
                    seal.fecha = date;
                  }
                "
              />
              
              <v-combobox dense v-model="seal.foja" data-vv-as="foja" name="foja" :items="getFojasComboBox()" label="Nº Foja*" autocomplete="off" v-validate="'required'"></v-combobox>
              <span class="red--text">{{ errors.first("foja") }}</span>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="close()">Cerrar</v-btn>
            <v-btn color="green" text :loading="loading" :disabled="seal.foja == null" @click="$emit('openModalPdf', seal, document)">Firmar</v-btn>
          </v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DatePickerEsp from "@/components/DatePickerEsp.vue";

export default {
  components: { DatePickerEsp },
  props: {
    dialog: Boolean,
    asigned_task: Object,
    work_order: Object,
    document: Object,
    closeDialog: Function,
    openModalPdf: Function,
    loading: Boolean,
  },
  data: () => ({
    seal: {
      fecha: null,
      foja: null,
    },
    defaultSeal: {
      fecha: null,
      foja: null,
    },
  }),
  mounted() {},
  watch: {
    dialog(value) {
      if (value) {
        this.initialize();
      }
    },
  },
  methods: {
    initialize() {
      this.seal.fecha = this.work_order.asignation_date;
      this.seal.foja = this.asigned_task.details[0].foja;
    },
    getFojasComboBox() {
      let fojas = [];
      if (this.asigned_task != null) this.asigned_task.details.forEach((detail) => fojas.push(detail.foja));
      return fojas;
    },
    close() {
      this.seal = Object.assign({}, this.defaultSeal);
      this.$emit("closeDialog");
    },
    isCertificacionFirma() {
      return this.asigned_task != null && this.asigned_task.task.id == 2;
    },
  },
};
</script>
