import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VNavigationDrawer,
    {
      staticClass: "rounded-0",
      attrs: {
        width: 240,
        permanent: !_vm.$vuetify.breakpoint.sm && !_vm.$vuetify.breakpoint.xs,
        dark: "",
        app: "",
        "mini-variant": _vm.$store.state.sidebar.mini,
        "expand-on-hover":
          !_vm.$vuetify.breakpoint.sm &&
          !_vm.$vuetify.breakpoint.xs &&
          _vm.$store.state.sidebar.expand_on_hover,
      },
      on: {
        "update:miniVariant": function ($event) {
          return _vm.$set(_vm.$store.state.sidebar, "mini", $event)
        },
        "update:mini-variant": function ($event) {
          return _vm.$set(_vm.$store.state.sidebar, "mini", $event)
        },
      },
      model: {
        value: _vm.$store.state.sidebar.open,
        callback: function ($$v) {
          _vm.$set(_vm.$store.state.sidebar, "open", $$v)
        },
        expression: "$store.state.sidebar.open",
      },
    },
    [
      _c(
        VCard,
        {
          attrs: { height: "100vh", width: "240" },
          on: {
            mouseenter: function ($event) {
              return _vm.handleExpandMenu(true)
            },
            mouseleave: function ($event) {
              return _vm.handleExpandMenu(false)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                VList,
                { staticStyle: { height: "100px" } },
                [
                  _c(
                    VListItem,
                    [
                      _c(
                        VListItemContent,
                        {
                          staticClass: "pt-1",
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center",
                          },
                        },
                        [
                          _c(VImg, {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.expandMenu,
                                expression: "expandMenu",
                              },
                            ],
                            staticClass: "pt-0",
                            staticStyle: { "max-width": "75px" },
                            attrs: {
                              transition: "fade-transition",
                              src: require("@/assets/images/logo.png"),
                              contain: "",
                            },
                          }),
                          _c(VImg, {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.expandMenu,
                                expression: "!expandMenu",
                              },
                            ],
                            staticClass: "pt-8",
                            attrs: {
                              transition: "fade-transition",
                              src: require("@/assets/images/logo.png"),
                              contain: "",
                              height: "70",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(VDivider, { staticClass: "mt-0" }),
            ],
            1
          ),
          _vm._l(_vm.items, function (item) {
            return _c(
              "div",
              {
                key: item.title,
                staticStyle: { "scroll-margin-top": "100px" },
              },
              [
                item.visible
                  ? _c(
                      VList,
                      { attrs: { dense: "", nav: "" } },
                      [
                        item.visible && "items" in item && item.items.length > 0
                          ? _c(
                              VListGroup,
                              {
                                key: item.title,
                                attrs: { "no-action": "" },
                                model: {
                                  value: item.active,
                                  callback: function ($$v) {
                                    _vm.$set(item, "active", $$v)
                                  },
                                  expression: "item.active",
                                },
                              },
                              [
                                _c(
                                  VListItem,
                                  {
                                    attrs: { slot: "activator" },
                                    slot: "activator",
                                  },
                                  [
                                    _c(
                                      VListItemIcon,
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: item.icon },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      VListItemContent,
                                      [
                                        _c(VListItemTitle, [
                                          _vm._v(_vm._s(item.title)),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._l(item.items, function (subItem) {
                                  return _c(
                                    "router-link",
                                    {
                                      key: subItem.title,
                                      attrs: { to: "/" + subItem.path },
                                    },
                                    [
                                      subItem.visible
                                        ? _c(
                                            VListItem,
                                            {
                                              staticClass: "subitem",
                                              attrs: { ripple: "" },
                                              on: { click: _vm.close },
                                            },
                                            [
                                              _c(
                                                VListItemIcon,
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: subItem.icon,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VListItemContent,
                                                [
                                                  _c(VListItemTitle, [
                                                    _vm._v(
                                                      _vm._s(subItem.title)
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            )
                          : item.visible
                          ? _c(
                              "router-link",
                              { attrs: { to: "/" + item.path } },
                              [
                                _c(
                                  VListItem,
                                  { key: item.title, attrs: { link: "" } },
                                  [
                                    _c(
                                      VListItemIcon,
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: item.icon },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      VListItemContent,
                                      [
                                        _c(VListItemTitle, [
                                          _vm._v(_vm._s(item.title)),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }