import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.viewRequiresAuth()
    ? _c(VApp, { staticClass: "scrollbar-hidden" }, [_c("router-view")], 1)
    : _c(
        VApp,
        { staticClass: "scrollbar-hidden" },
        [
          _c("Sidebar"),
          _c(
            VAppBar,
            { staticClass: "elevation-0", attrs: { dense: "", app: "" } },
            [_c("Header")],
            1
          ),
          _c(VMain, [_c("router-view")], 1),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }