<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" max-width="750px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">

                <v-combobox
                  v-model="item.client"
                  data-vv-as="client"
                  name="client"
                  item-text="business_name"
                  item-value="id"
                  :items="clients"
                  label="Cliente*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("client") }}</span>

                <v-text-field data-vv-as="name" label="Razon social*" name="name" v-model="item.name" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("name") }}</span>

                <div>
                  <label class="fs-16">Tipo de documento</label>
                  <v-combobox
                    v-model="item.doc_type"
                    data-vv-as="doc_type"
                    name="doc_type"
                    :items="doc_types"
                    item-text="name"
                    item-value="id"
                    autocomplete="off"
                    :disabled="isViewModal()"
                  ></v-combobox>
                </div>

                <v-text-field autocomplete="off" data-vv-as="doc_nr" label="Nº de documento*" name="doc_nr" v-validate="'integer|required'" v-model="item.doc_nr" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("doc_nr") }}</span>

                <div>
                  <label class="fs-16">Condición frente al IVA</label>
                  <v-combobox
                    v-model="item.iva_condition"
                    data-vv-as="iva_condition"
                    name="iva_condition"
                    :items="iva_conditions"
                    item-text="name"
                    item-value="id"
                    autocomplete="off"
                    :disabled="isViewModal()"
                  ></v-combobox>
                </div>

                <v-text-field autocomplete="off" data-vv-as="address" label="Domicilio*" name="address" v-validate="'required'" v-model="item.address" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("address") }}</span>

                <div>
                  <label class="fs-16">Tipo de transferencia</label>
                  <v-combobox
                    v-model="item.transfer_type"
                    data-vv-as="transfer_type"
                    name="transfer_type"
                    :items="transfer_types"
                    item-text="name"
                    item-value="id"
                    autocomplete="off"
                    :disabled="isViewModal()"
                  ></v-combobox>
                </div>

              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Constant from "@/constants/sections";
import { billingGeneralService } from "@/libs/ws/billingGeneralService";
import { generalService } from "@/libs/ws/generalService";

export default {
  components: { },
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      show: false,
      roles: [],
      doc_types: [],
      iva_conditions: [],
      clients: [],
      loading: false,
      transfer_types: [
        { id: "SCA", name: "TRANSFERENCIA AL SISTEMA DE CIRCULACION ABIERTA" },
        { id: "ADC", name: "AGENTE DE DEPOSITO COLECTIVO" },
      ],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadDocTypes();
    this.loadIVAConditions();
    this.loadClients();
  },
  methods: {
    loadClients() {
      generalService
        .filter({}, Constant.SECTION_CLIENTS)
        .then((response) => {
          this.clients = response.result;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    isViewModal() {
      return this.formBtn == "";
    },
    loadDocTypes(){
      billingGeneralService.list_doc_types().then((response) => {        
        this.doc_types = response.result;
      });
    },
    loadIVAConditions(){
      billingGeneralService.list_iva_conditions().then((response) => {
        this.iva_conditions = response.result;
      });
    },
    createItem(e) {
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.loading = true;
          e.preventDefault();          
          //Se crea data para reemplazar criteria_price co nel id
          let data = new FormData();
          data.append("id", this.item.id);
          data.append("name", this.item.name);          
          data.append("doc_type", this.item.doc_type.id);
          data.append("doc_nr", this.item.doc_nr);
          data.append("client", this.item.client.id);
          data.append("iva_condition", this.item.iva_condition.id);
          data.append("address", this.item.address);
          if(this.item.transfer_type != null)
            data.append("transfer_type", this.item.transfer_type.id);              
          
          if (this.item.id > 0) {
            this.service
              .update(data)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.service
              .create(data)
              .then((response) => {
                console.log(response);
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => {
                this.loading = false;
              });
          }          
        }
      });
    },
  },
};
</script>
