import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VDialog,
        {
          attrs: { transition: "dialog-bottom-transition", "max-width": "600" },
          on: {
            "click:outside": function ($event) {
              return _vm.$emit("closeDialog")
            },
            input: function ($event) {
              return _vm.$emit("updateDialogAddSignaroty", false)
            },
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, { staticClass: "headline" }, [
                _vm._v("Agregar firmante"),
              ]),
              _c(
                VCardText,
                [
                  _c("label", { staticClass: "fs-16 mt-3" }, [
                    _vm._v(
                      "Seleccione los firmates que desea agregar a la tarea"
                    ),
                  ]),
                  _c(VCombobox, {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      "data-vv-as": "firmante",
                      name: "firmante",
                      "item-text": "name",
                      "item-value": "id",
                      items: _vm.signatories,
                      label: "Firmante",
                      autocomplete: "off",
                      multiple: "",
                    },
                    model: {
                      value: _vm.selected_signatories,
                      callback: function ($$v) {
                        _vm.selected_signatories = $$v
                      },
                      expression: "selected_signatories",
                    },
                  }),
                  _c("span", { staticClass: "red--text" }, [
                    _vm._v(_vm._s(_vm.errors.first("firmante"))),
                  ]),
                  _c("div", { staticClass: "text-center" }, [
                    _c("hr"),
                    _c("label", { staticClass: "subtext" }, [
                      _vm._v(
                        "Si el firmante buscado no se encuentra en el listado puede darlo de alta"
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "mt-3 text-center" },
                      [
                        _vm.hasCreateAccessSignatory()
                          ? _c("ModalCreateSignature", {
                              attrs: {
                                fixed_client: true,
                                center: true,
                                button_name: _vm.$t("Crear Firmante"),
                                modal_title: "Nuevo Firmante",
                                page_title: "Firmantes",
                                formBtn: "Crear",
                                dialog: _vm.dialog_signaroty,
                                service: _vm.service,
                                item: _vm.mainItem,
                              },
                              on: {
                                updateDialog: function (value) {
                                  _vm.dialog_signaroty = value
                                },
                                loadList: function ($event) {
                                  return _vm.loadComboData()
                                },
                                updateItem: function (newItem) {
                                  _vm.mainItem = newItem
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VCardActions,
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "error", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("closeDialog")
                            },
                          },
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "success", text: "" },
                          on: { click: _vm.acceptModal },
                        },
                        [_vm._v("Agregar")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }