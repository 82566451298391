var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pdf-zoom" }, [
    _c(
      "a",
      {
        staticClass: "icon",
        attrs: { disabled: _vm.isDisabled },
        on: {
          click: function ($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.$emit("exitModal")
          },
        },
      },
      [_c("font-awesome-icon", { attrs: { icon: "xmark" } })],
      1
    ),
    _c(
      "a",
      {
        staticClass: "icon",
        attrs: { disabled: _vm.isDisabled },
        on: {
          click: function ($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.zoomIn.apply(null, arguments)
          },
        },
      },
      [_c("font-awesome-icon", { attrs: { icon: "magnifying-glass-plus" } })],
      1
    ),
    _c(
      "a",
      {
        staticClass: "icon",
        attrs: { disabled: _vm.isDisabled },
        on: {
          click: function ($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.zoomOut.apply(null, arguments)
          },
        },
      },
      [_c("font-awesome-icon", { attrs: { icon: "magnifying-glass-minus" } })],
      1
    ),
    _c(
      "a",
      {
        staticClass: "icon",
        attrs: { disabled: _vm.isDisabled },
        on: {
          click: function ($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.fitWidth.apply(null, arguments)
          },
        },
      },
      [_c("font-awesome-icon", { attrs: { icon: "maximize" } })],
      1
    ),
    _c(
      "a",
      {
        staticClass: "icon",
        attrs: { disabled: _vm.isDisabled },
        on: {
          click: function ($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.fitAuto.apply(null, arguments)
          },
        },
      },
      [_c("font-awesome-icon", { attrs: { icon: "minimize" } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }