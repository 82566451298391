var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ScrollingDocument",
    _vm._b(
      {
        staticClass: "pdf-preview",
        attrs: { "is-parent-visible": _vm.isPreviewEnabled },
        on: { "pages-fetch": _vm.onPagesFetch },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var page = ref.page
              var isPageFocused = ref.isPageFocused
              return [
                _c(
                  "PDFThumbnail",
                  _vm._b(
                    {
                      on: {
                        "thumbnail-rendered": _vm.onThumbnailRendered,
                        "thumbnail-errored": _vm.onThumbnailErrored,
                        "page-focus": _vm.onPageFocused,
                      },
                    },
                    "PDFThumbnail",
                    {
                      scale: _vm.scale,
                      page: page,
                      isPageFocused: isPageFocused,
                    },
                    false
                  )
                ),
              ]
            },
          },
        ]),
      },
      "ScrollingDocument",
      {
        pages: _vm.pages,
        pageCount: _vm.pageCount,
        currentPage: _vm.currentPage,
      },
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }