<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)"  max-width="750px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

        <v-card>
          <v-form v-on:submit.prevent="createItem">
              <v-card-title>
                  <span class="headline">{{ modal_title }}</span>
              </v-card-title>

              <v-card-text>
                  <!-- START FORM DIALOG -->                  
                  <v-text-field
                    data-vv-as="nombre"
                    label="Nombre*"
                    name="name"
                    v-model="item.name"
                    v-validate="'required'" :disabled="isViewModal()"
                  ></v-text-field>
                  <span class="red--text">{{
                    errors.first("name")
                  }}</span>

                  <v-data-table
                    :disabled="isViewModal()"
                    :headers="headers_modules"
                    :items="(item.modules.length != 0)?item.modules:modules"
                    :loading="loading"
                    :hide-default-footer="true"
                    :disable-pagination="true"
                    loading-text="Cargando... Por favor espere"                    
                  >
                      <template v-slot:item="props">
                          <tr>
                              <!-- START ROWS -->
                              <!-- Nombre -->
                              <td>{{ props.item.name }}</td>
                              <td>
                                  <v-checkbox
                                      v-model="props.item.list"
                                      :disabled="isViewModal()"
                                  ></v-checkbox>
                              </td>
                              <td>
                                  <v-checkbox
                                      v-model="props.item.create"
                                      :disabled="isViewModal()"
                                  ></v-checkbox>
                              </td>
                              <td>
                                  <v-checkbox
                                      v-model="props.item.update"
                                      :disabled="isViewModal()"
                                  ></v-checkbox>
                              </td>
                              <td>
                                  <v-checkbox
                                      v-model="props.item.delete"
                                      :disabled="isViewModal()"
                                  ></v-checkbox>
                              </td>
                              <!--
                              <td>
                                  <v-checkbox
                                      v-model="props.item.sidebar"
                                  ></v-checkbox>
                              </td>
                              -->
                              <td>
                                  <v-checkbox
                                      v-model="props.item.sensible"
                                      :disabled="isViewModal()"
                                  ></v-checkbox>
                              </td>
                              <td>
                                  <v-checkbox
                                      v-model="props.item.sidebar"
                                      :disabled="isViewModal()"
                                  ></v-checkbox>
                              </td>
                              <!-- END ROWS -->
                          </tr>
                      </template>
                  </v-data-table>

                  <v-checkbox
                      label="Habilitado"
                      :disabled="isViewModal()"
                      v-model="item.enable"
                  ></v-checkbox>
                  <!-- END FORM DIALOG -->
              </v-card-text>

              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
                  <v-btn color="success" text type="submit" v-if="!isViewModal()">{{ formBtn }}</v-btn>
              </v-card-actions>
          </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { rolService } from "@/libs/ws/rolService";
export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      loading: false,
      show: false,
      modules: [],      
      headers_modules: [
        { text: "Nombre", value: "name" },
        { text: "Listar", value: "list" },
        { text: "Crear", value: "create" },
        { text: "Editar", value: "update" },
        { text: "Eliminar", value: "delete" },        
        { text: "Sensible", value: "sensible" },
        { text: "Acceso desde Panel", value: "sidebar" }
      ],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      if(!this.dialog)
        this.loadModules();      
      this.errors.clear();
    },
  },
  mounted() {    
    this.loadModules();
  },
  methods: {
    loadModules() {
      this.loading =  true;
      rolService
        .listModules()
        .then((response) => {          
          this.modules = response.result;          
          this.loading =  false;          
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
          this.loading =  false;
        });      
    },
    isViewModal() {
      return this.formBtn == "";
    },    
    createItem(e) {
      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();
          if (!this.item.isExternal) {
            this.item.companyExternal = { name: "", id: 1 };
          }
          if (this.item.id > 0) {
            this.service
              .update(this.item)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El rol se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {                                    
                  if(Object.prototype.hasOwnProperty.call(response, 'message')){
                    this.$toast.error(response.message, "Error");
                  }else{
                    if(Object.prototype.hasOwnProperty.call(response, 'message')){
                      this.$toast.error(response.message, "Error");
                    }else{
                      this.$toast.error(response.msg, "Error");
                    }
                  }
                }
              })
              .catch((response) => {                
                this.$toast.error(response.msg, "Error");
              });
          } else {
            this.item.modules = this.modules;            
            this.service
              .create(this.item)
              .then((response) => {                
                if (response.result.id > 0) {
                  this.$toast.success("El rol se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {                  
                  if(Object.prototype.hasOwnProperty.call(response, 'message')){
                    this.$toast.error(response.message, "Error");
                  }else{
                    this.$toast.error(response.msg, "Error");
                  }                  
                }
              })
              .catch((response) => {                  
                this.$toast.error(response.msg, "Error");
              });            
          }
        }
      });
    },    
  },
};
</script>
