<template>
  <div>
    <v-data-table :headers="headers" :items="list" :loading="loading" loading-text="Cargando..." hide-default-footer :items-per-page="20">
      <template v-slot:item="props">
        <tr>

          <td>{{ props.item.documentation_type.name }}</td>
          <td>
            <v-checkbox
                v-model="props.item.apply"
                small
                class="pa-0"
              ></v-checkbox>
          </td>
          <td>{{ props.item.requested_date }}</td>
          <td>{{ props.item.received_date }}</td>
          <td class="justify-content-center px-0">
            <v-tooltip bottom v-if="!isViewModal">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small color="blue" class="mr-2" @click="editItem(props.item)">edit</v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom v-if="props.item.property_documentation_files.length > 0">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small color="blue" class="mr-1" @click="viewAttachItem(props.item)">
                  remove_red_eye </v-icon>
              </template>
              <span>Ver</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!isViewModal">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small color="orange" class="mr-2" @click="attachItem(props.item)">attach_file</v-icon>
              </template>
              <span>Adjuntar</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>

    <!-- Modal de edición -->
    <v-dialog v-model="dialog_edit" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ editedItem.documentation_type.name }}</span>
        </v-card-title>

        <v-card-text>

          <div class="row">
            <div class="col-md-12">
              <v-checkbox v-model="editedItem.apply" label="Aplica"></v-checkbox>
            </div>
            <div class="col-md-6">
              <DatePickerEsp label="Fecha Pedido" :date="editedItem.requested_date" :initToday="true"
                @setDate="date => editedItem.requested_date = date" class="pt-0" name="requested_date" />
            </div>
            <div class="col-md-6">
              <DatePickerEsp label="Fecha Recibido" :date="editedItem.received_date"
                @setDate="date => editedItem.received_date = date" class="pt-0" name="received_date" />
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeEdit">Cancelar</v-btn>
          <v-btn color="success" text @click="saveEdit">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_attach" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ view_attach ? 'Ver Documentos' : 'Documentos' }}</span>
        </v-card-title>

        <v-card-text>

          <div class="row">

            <div class="col-md-12 mb-4">
              <div v-if="!view_attach" class="mt-2 mb-2">

                <input type="file" ref="fileInput" @change="handleFileSelect" style="display: none" multiple>
                <v-btn color="primary" @click="$refs.fileInput.click()">
                  <v-icon left>mdi-upload</v-icon>
                  Adjuntar Documentos
                </v-btn>
              </div>
              <v-data-table :headers=" !view_attach ? headers_documents : headers_documents_view" :items="editedItem.property_documentation_files"
                :loading="loading" loading-text="Cargando..." hide-default-footer>
                <template v-slot:item="props">
                  <tr>

                    <td :class="props.item.id > 0 ? 'redirect' : ''" @click="download(props.item)">{{
                      props.item.name }}
                    </td>
                    <td class="justify-content-center layout px-0">
                      <v-tooltip bottom v-if="!view_attach">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small color="red" class="mr-2"
                            @click="removeFile(props.index)">mdi-delete</v-icon>
                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeEdit">Cancelar</v-btn>
          <v-btn color="success" text @click="saveEdit" v-if="!view_attach">Adjuntar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { propertySaleService } from "@/libs/ws/propertySaleService";
import DatePickerEsp from "@/components/DatePickerEsp.vue";
import { filesService } from "@/libs/ws/filesService";
export default {
  props: {
    list: Array,
    loading: Boolean,
    updateDocumentation: Function,
    isViewModal: Boolean
  },
  components: { DatePickerEsp },

  data() {
    return {
      service: propertySaleService,
      headers: [
        { text: "Descripcion", value: "documentation_type.name", align: "left" },
        { text: "Aplica", value: "apply", align: "left" },
        { text: "Pedido", value: "requested_date", align: "left" },
        { text: "Recibido", value: "received_date", align: "left" },
        { text: "Accion", value: "", sortable: false, align: "center" },
      ],
      headers_documents_view: [
        { text: "Nombre", value: "name", align: "left" },
      
      ],
      headers_documents: [
        { text: "Nombre", value: "name", align: "left" },
        { text: "Accion", value: "", sortable: false, align: "center" },
      ],
      dialog_edit: false,
      dialog_attach: false,
      view_attach: false,
      editedItem: {
        apply: true,
        requested_date: null,
        received_date: null,
        documentation_type: { name: '' },
        property_documentation_files: [],
        id: null
      },
      defaultItem: {
        apply: true,
        requested_date: null,
        received_date: null,
        documentation_type: { name: '' },
        id: null,
        property_documentation_files: []
      },
      files: [],
      loading_download: false
    };
  },
  methods: {

    download(item) {
      console.log(item);
      if (item.id > 0) {

        this.loading_download = true;
        filesService
          .downloadFile(item.path)
          .then((response) => {
            console.log('entre', response.data);

            let blob = new Blob([response.data], {
              type: "application/pdf",
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = item.name;
            link.click();
          })
          .catch((response) => {
            this.$toast.error(response.msg, "Error");
          })
          .finally(() => (this.loading_download = false));
      }
    },
    handleFileSelect(event) {
      const newFiles = Array.from(event.target.files);
      this.editedItem.property_documentation_files = [...this.editedItem.property_documentation_files, ...newFiles];
      event.target.value = '';
    },
    editItem(item) {
      this.editedItem = {
        ...item,
        apply: item.apply,
        requested_date: item.requested_date,
        received_date: item.received_date,
        property_documentation_files: item.property_documentation_files,
        documentation_type: { ...item.documentation_type },
      };
      this.dialog_edit = true;
    },
    saveEdit() {
      this.$emit('updateDocument', this.editedItem);
      this.closeEdit();
    },
    closeEdit() {
      this.dialog_edit = false;
      this.dialog_attach = false;
      this.view_attach = false;
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
    },
    viewAttachItem(item) {
      this.editedItem = {
        ...item,
        property_documentation_files: item.property_documentation_files
      };
      this.view_attach = true;
      this.dialog_attach = true;
    },

    attachItem(item) {
      this.editedItem = {
        ...item,
        property_documentation_files: item.property_documentation_files
      };

      this.dialog_attach = true;
    },
    removeFile(index) {
      this.editedItem.property_documentation_files.splice(index, 1);
    },
  },
};
</script>