<template>
  <div>
    <v-dialog v-model="dialog" @click:outside="$emit('closeDialog')" @keydown.esc="$emit('closeDialog')" v-on:input="$emit('closeDialog', false)" transition="dialog-bottom-transition" max-width="600">
      <v-card>
        <v-card-title class="headline">Estado de la tarea</v-card-title>

        <v-card-text>
          <label class="fs-16 mt-3">Seleccione los firmates que desea agregar a la tarea</label>
          <v-combobox
            v-model="selected_state"
            data-vv-as="state"
            name="state"
            item-text="name"
            item-value="id"
            :items="states"
            label="Estado"
            autocomplete="off"
            v-validate="'required'"
            class="mt-4"
          ></v-combobox>
          <span class="red--text">{{ errors.first("state") }}</span>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('closeDialog')">Cancelar</v-btn>
            <v-btn color="success" text @click="acceptModal" :loading="loading">Aceptar</v-btn>
          </v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ConstantSection from "@/constants/sections";
import { accessUtils } from "@/libs/utils/accessUtils";
import { generalService } from "@/libs/ws/generalService";
import { assignatesTaskService } from "@/libs/ws/assignatesTaskService";
export default {
  components: {},
  props: {
    dialog: Boolean,
    item: Object,
  },
  data: () => ({
    states: [],
    loading: false,
    selected_state: { id: 0, name: "" },
    service: generalService,
    defaultItem: null,
    dialog_signaroty: false,
    mainItem: {
      id: "",
      name: "",
      weight: "",
    },
  }),
  mounted() {
    this.service.setSection(ConstantSection.SECTION_ORDERS_STATES);
    this.loadComboData();
  },
  watch: {
    item: function (newVal, oldVal) {
      if (this.item != null && this.item.id > 0) {
        this.selected_state = this.item.state;
      }
    },
  },
  methods: {
    hasCreateAccessSignatory() {
      return accessUtils.getAccessOf(ConstantSection.SECTION_ORDERS_STATES).create;
    },
    loadComboData() {
      const currentComponent = this;
      this.service.filter({ task_category: 'C' }).then((response) => {
        if (response.result != null && response.status) {
          currentComponent.states = response.result.reverse();
        }
      });
    },
    acceptModal() {
      this.loading = true;
      const data = { assigned_task_id: this.item.id, new_state_id: this.selected_state.id };
      assignatesTaskService
        .update_state(data)
        .then((response) => {
          if (response.status == true) {
            this.$toast.success("El estado de la tarea se modifico correctamente.", "OK");
            this.$emit("updateDialog", false);
            this.$emit("loadList");
            this.$emit("closeDialog");
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.$toast.error(response.message, "Error");
            } else {
              this.$toast.error(response.msg, "Error");
            }
          }
        })
        .catch((response) => {
          this.$toast.error(response.msg, "Error");
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
