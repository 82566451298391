import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Title", {
        attrs: { pages: [{ icon: "", page: _vm.page_title.toUpperCase() }] },
      }),
      _c(
        VContainer,
        { attrs: { fluid: "" } },
        [
          _vm.create_access
            ? _c("Modal", {
                attrs: {
                  button_name: _vm.$t("Crear") + " " + _vm.modal_title,
                  modal_title: _vm.modal_title,
                  page_title: _vm.page_title,
                  formBtn: _vm.formBtn,
                  dialog: _vm.dialog,
                  parent: _vm.parent,
                  service: _vm.service,
                  item: _vm.mainItem,
                },
                on: {
                  updateDialog: _vm.updateDialog,
                  loadList: function ($event) {
                    return _vm.loadList(_vm.service)
                  },
                  updateItem: function (newItem) {
                    _vm.mainItem = newItem
                  },
                },
              })
            : _vm._e(),
          _vm.list_access
            ? _c(VCard, [
                _c("div", { staticClass: "row mb-5 mt-0" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-md-12",
                        staticStyle: { background: "white" },
                      },
                      [
                        _c(
                          VRow,
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              VCol,
                              { attrs: { cols: "12", md: "1", sm: "12" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "cursor text-filter",
                                    staticStyle: { "font-size": "20px" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.expandFilter()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" Filtros "),
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "filter" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              VCol,
                              {
                                staticClass: "text--secondary cursor",
                                attrs: { cols: "12", md: "10", sm: "12" },
                                on: {
                                  click: function ($event) {
                                    return _vm.expandFilter()
                                  },
                                },
                              },
                              [
                                _vm.open_filter
                                  ? _c("p", { staticClass: "mb-0 mt-1" }, [
                                      _vm._v("Seleccione los filtros deseados"),
                                    ])
                                  : _c(
                                      "span",
                                      [
                                        _vm.clients_selected.length > 0
                                          ? _c(
                                              VChip,
                                              {
                                                staticClass: "ml-1 mb-1",
                                                attrs: {
                                                  outlined: "",
                                                  color: "#666",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Clientes: " +
                                                    _vm._s(
                                                      _vm.showClientsSelected()
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.signatories_selected.length > 0
                                          ? _c(
                                              VChip,
                                              {
                                                staticClass: "ml-1 mb-1",
                                                attrs: {
                                                  outlined: "",
                                                  color: "#666",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Firmantes: " +
                                                    _vm._s(
                                                      _vm.showSignatoriesSelected()
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                            _c(
                              VCol,
                              {
                                staticClass: "dropdown-filter",
                                attrs: { cols: "12", md: "1", sm: "12" },
                              },
                              [
                                !_vm.open_filter
                                  ? _c("font-awesome-icon", {
                                      staticClass: "cursor",
                                      attrs: { icon: "chevron-down" },
                                      on: {
                                        click: function ($event) {
                                          _vm.open_filter = true
                                        },
                                      },
                                    })
                                  : _c("font-awesome-icon", {
                                      staticClass: "cursor",
                                      attrs: { icon: "chevron-up" },
                                      on: {
                                        click: function ($event) {
                                          _vm.open_filter = false
                                        },
                                      },
                                    }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.open_filter
                          ? _c("hr", { staticClass: "mt-4" })
                          : _vm._e(),
                        _vm.open_filter
                          ? _c("div", { staticClass: "row pt-5" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-3 pt-0" },
                                [
                                  _c(VCombobox, {
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      autocomplete: "off",
                                      "data-vv-as": "clients_selected",
                                      name: "clients_selected",
                                      "item-text": "business_name",
                                      "item-value": "id",
                                      items: _vm.clients,
                                      label: "Clientes",
                                      clearable: "",
                                      multiple: "",
                                      "small-chips": "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.clientChangeCombo($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.clients_selected,
                                      callback: function ($$v) {
                                        _vm.clients_selected = $$v
                                      },
                                      expression: "clients_selected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3 pt-0" },
                                [
                                  _c(VCombobox, {
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      autocomplete: "off",
                                      "data-vv-as": "signatories_selected",
                                      name: "signatories_selected",
                                      "item-text": "alias",
                                      "item-value": "id",
                                      items: _vm.signatories,
                                      label: "Firmantes",
                                      clearable: "",
                                      multiple: "",
                                      "small-chips": "",
                                    },
                                    model: {
                                      value: _vm.signatories_selected,
                                      callback: function ($$v) {
                                        _vm.signatories_selected = $$v
                                      },
                                      expression: "signatories_selected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "col-md-3 pt-0" }),
                              _c(
                                "div",
                                {
                                  staticClass: "col-md-3 pt-0",
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "end",
                                  },
                                },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { color: "info" },
                                      on: { click: _vm.filter },
                                    },
                                    [_vm._v("Buscar")]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.list_access
            ? _c(
                VCard,
                [
                  _c(
                    VCardTitle,
                    [
                      _c("span", { staticClass: "text-uppercase" }, [
                        _vm._v(_vm._s(this.signatory_name)),
                      ]),
                      _c(VSpacer),
                      _c(VTextField, {
                        attrs: {
                          "append-icon": "search",
                          label: "Buscar",
                          "single-line": "",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(VDataTable, {
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.list,
                      search: _vm.search,
                      loading: _vm.loading,
                      "loading-text": "Cargando...",
                      options: {
                        descending: true,
                        itemsPerPage: 15,
                      },
                      "footer-props": {
                        itemsPerPageOptions: [5, 10, 15, 50, -1],
                      },
                    },
                    on: { pagination: _vm.updatePagination },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function (props) {
                            return [
                              _c("tr", [
                                _c("td", [_vm._v(_vm._s(props.item.name))]),
                                _c(
                                  "td",
                                  _vm._l(
                                    props.item.signatories,
                                    function (obj) {
                                      return _c(
                                        VChip,
                                        {
                                          key: obj.id,
                                          staticClass: "mr-1",
                                          attrs: { small: "" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "truncate",
                                              attrs: { title: obj.alias },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(obj.alias) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      props.item.expiration_date != null
                                        ? props.item.expiration_date
                                        : "-"
                                    )
                                  ),
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "justify-content-center layout px-0",
                                  },
                                  [
                                    _c(
                                      VTooltip,
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                return [
                                                  _vm.update_access
                                                    ? _c(
                                                        VIcon,
                                                        _vm._g(
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              small: "",
                                                              color: "blue",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.editItem(
                                                                  props.item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v(" edit ")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Editar")])]
                                    ),
                                    _c(
                                      VTooltip,
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    VIcon,
                                                    _vm._g(
                                                      {
                                                        staticClass: "mr-1",
                                                        attrs: {
                                                          small: "",
                                                          color: "blue",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.viewItem(
                                                              props.item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v(" remove_red_eye ")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Ver")])]
                                    ),
                                    _c(
                                      VTooltip,
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                return [
                                                  _vm.delete_access
                                                    ? _c(
                                                        VIcon,
                                                        _vm._g(
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              small: "",
                                                              color: "red",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteItem(
                                                                  props.item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v(" delete ")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Eliminar")])]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "no-results",
                          fn: function () {
                            return [
                              _c(
                                VAlert,
                                {
                                  attrs: {
                                    value: true,
                                    color: "error",
                                    icon: "warning",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        "Busqueda sin resultados - " +
                                          _vm.search
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      171584342
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("DialogDelete", {
            attrs: { dialog_delete: _vm.dialog_delete },
            on: {
              updateDialogDelete: _vm.updateDialogDelete,
              deleteItemConfirm: _vm.deleteItemConfirm,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }