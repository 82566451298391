<template>
  <div>
    <v-dialog v-model="dialog" @click:outside="$emit('closeDialog')" @keydown.esc="$emit('closeDialog')" transition="dialog-bottom-transition" max-width="600">
      <v-card>
        <v-card-title class="headline">Carga rapida Actas / Libros / Fojas</v-card-title>

        <v-card-text>
          <label class="fs-16 mt-3 mb-5">Ingrese los datos para la carga rápida</label>

          <br />
          <b><label class="fs-16 mt-3">Acta</label></b>
          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <v-text-field dense data-vv-as="acta_desde" label="Desde" name="acta_desde" v-model="model.acta_desde" />
              <span class="red--text">{{ errors.first("acta_desde") }}</span>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field dense data-vv-as="acta_hasta" label="Hasta" name="acta_hasta" v-model="model.acta_hasta" />
              <span class="red--text">{{ errors.first("acta_hasta") }}</span>
            </v-col>
          </v-row>

          <br />
          <b><label class="fs-16 mt-3">Libro</label></b>
          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <v-text-field dense data-vv-as="libro_desde" label="Desde" name="libro_desde" v-model="model.libro_desde" />
              <span class="red--text">{{ errors.first("libro_desde") }}</span>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field dense data-vv-as="libro_hasta" label="Hasta" name="libro_hasta" v-model="model.libro_hasta" />
              <span class="red--text">{{ errors.first("libro_hasta") }}</span>
            </v-col>
          </v-row>

          <br />
          <b><label class="fs-16 mt-3">Foja</label></b>
          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <v-text-field dense data-vv-as="foja_desde" label="Desde" name="foja_desde" v-model="model.foja_desde" />
              <span class="red--text">{{ errors.first("foja_desde") }}</span>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field dense data-vv-as="foja_hasta" label="Hasta" name="foja_hasta" v-model="model.foja_hasta" />
              <span class="red--text">{{ errors.first("foja_hasta") }}</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('closeDialog')">Cerrar</v-btn>
            <v-btn color="success" text @click="QuickLoadProcess">Agregar</v-btn>
          </v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    dialog: Boolean,
  },
  data: () => ({
    loading: false,
    model: {
      acta_desde: null,
      acta_hasta: null,
    },
  }),
  mounted() {},
  watch: {},
  methods: {
    QuickLoadProcess() {
      //slas diferenicas de cada campo desde-hasta siempre tienen que se iguales en los 3
      //o solo 1 valor en 1 campo
      //Calc diferencias
      const acta_diff = parseInt(this.model.acta_hasta) - parseInt(this.model.acta_desde);
      const libro_diff = parseInt(this.model.libro_hasta) - parseInt(this.model.libro_desde);
      const foja_desde_int = parseInt(this.model.foja_desde.substring(1));
      const foja_hasta_int = parseInt(this.model.foja_hasta.substring(1));
      const foja_diff = foja_hasta_int - foja_desde_int;

      if (
        !(
          (
            (acta_diff == libro_diff && libro_diff == foja_diff) || //los 3 iguales
            (acta_diff == 0 && libro_diff == foja_diff) || //alguno es 0 y los otros iguales
            (libro_diff == 0 && acta_diff == foja_diff) || //alguno es 0 y los otros iguales
            (foja_diff == 0 && acta_diff == libro_diff) || //alguno es 0 y los otros iguales
            (acta_diff == 0 && libro_diff == 0) || //si 2 son 0
            (acta_diff == 0 && foja_diff == 0) || //si 2 son 0
            (libro_diff == 0 && foja_diff == 0)
          ) //si 2 son 0
        )
      ) {
        console.log(acta_diff, libro_diff, foja_diff);
        this.$toast.error("No se puede realizar la carga rapida con esos valores.", "Error");
        return;
      }

      const max = Math.max(acta_diff, libro_diff, foja_diff);
      this.$emit("processQuickLoad", this.model, max);
      this.$emit("closeDialog");
    },
  },
};
</script>
