var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "visible",
          rawName: "v-visible.once",
          value: _vm.drawPage,
          expression: "drawPage",
          modifiers: { once: true },
        },
      ],
      staticClass: "pdf-thumbnail",
      class: { focused: _vm.isPageFocused },
      on: { click: _vm.focusPage },
    },
    [
      _vm.src
        ? _c("img", { staticClass: "box-shadow", attrs: { src: _vm.src } })
        : _c("div", { staticClass: "placeholder box-shadow" }, [
            _c("div", { staticClass: "content" }, [_vm._v(" Loading ")]),
          ]),
      _c("span", { staticClass: "page-number" }, [
        _vm._v(_vm._s(_vm.pageNumber)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }