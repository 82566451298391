import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Title", {
        attrs: {
          pages: [{ icon: "desktop", page: _vm.page_title.toUpperCase() }],
        },
      }),
      _c(VContainer, { attrs: { fluid: "" } }, [
        _c("div", { staticClass: "row mt-4 mb-2" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "box-monitoring-mother" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col box-monitoring divider" }, [
                    _c("p", [_vm._v("Pendientes")]),
                    !_vm.loading_monitoring
                      ? _c("div", [
                          _vm._v(" " + _vm._s(_vm.pending_orders) + " "),
                        ])
                      : _vm._e(),
                    _vm.loading_monitoring
                      ? _c(
                          "div",
                          { staticClass: "skeleton_monitoring" },
                          [
                            _vm.loading_monitoring
                              ? _c(VSkeletonLoader, {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-top": "5px",
                                  },
                                  attrs: { type: "chip" },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "col box-monitoring divider" }, [
                    _c("p", [_vm._v("En ejecución")]),
                    !_vm.loading_monitoring
                      ? _c("div", [
                          _vm._v(" " + _vm._s(_vm.running_orders) + " "),
                        ])
                      : _vm._e(),
                    _vm.loading_monitoring
                      ? _c(
                          "div",
                          { staticClass: "skeleton_monitoring" },
                          [
                            _vm.loading_monitoring
                              ? _c(VSkeletonLoader, {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-top": "5px",
                                  },
                                  attrs: { type: "chip" },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "col box-monitoring divider" }, [
                    _c("p", [_vm._v("Proximas a vencer")]),
                    !_vm.loading_monitoring
                      ? _c("div", [
                          _vm._v(" " + _vm._s(_vm.soon_expired_orders) + " "),
                        ])
                      : _vm._e(),
                    _vm.loading_monitoring
                      ? _c(
                          "div",
                          { staticClass: "skeleton_monitoring" },
                          [
                            _vm.loading_monitoring
                              ? _c(VSkeletonLoader, {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-top": "5px",
                                  },
                                  attrs: { type: "chip" },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "col box-monitoring" }, [
                    _c("p", [_vm._v("Vencidas")]),
                    !_vm.loading_monitoring
                      ? _c("div", [
                          _vm._v(" " + _vm._s(_vm.expired_orders) + " "),
                        ])
                      : _vm._e(),
                    _vm.loading_monitoring
                      ? _c(
                          "div",
                          { staticClass: "skeleton_monitoring" },
                          [
                            _vm.loading_monitoring
                              ? _c(VSkeletonLoader, {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-top": "5px",
                                  },
                                  attrs: { type: "chip" },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm.loading_monitoring
          ? _c(
              "div",
              [
                _c("OrderSkeleton", {
                  attrs: { loading: _vm.loading_monitoring },
                }),
                _c("OrderSkeleton", {
                  attrs: { loading: _vm.loading_monitoring },
                }),
                _c("OrderSkeleton", {
                  attrs: { loading: _vm.loading_monitoring },
                }),
              ],
              1
            )
          : _vm._e(),
        !_vm.loading_monitoring && _vm.work_orders.length > 0
          ? _c(
              "div",
              _vm._l(_vm.work_orders, function (order) {
                return _c(
                  "div",
                  { key: order.id, staticClass: "col-md-12" },
                  [_c("OrderMonitoring", { attrs: { order: order } })],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        !_vm.loading_monitoring && _vm.work_orders.length == 0
          ? _c("div", [
              _c("div", { staticClass: "col-md-12" }, [
                _c("p", { staticClass: "text-center" }, [
                  _c("i", {
                    staticClass: "fa fa-truck",
                    staticStyle: {
                      "font-size": "60px",
                      color: "#dd4b39",
                      "margin-top": "40px",
                    },
                  }),
                ]),
                _c("p", { staticClass: "alert-info-monitoring" }, [
                  _vm._v("Todavía no hay ordenes"),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }