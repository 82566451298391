<template>
  <v-layout>    
    <v-dialog v-if="dialog" v-bind:value="dialog" @click:outside="$emit('close')" v-on:input="$emit('close')" transition="dialog-bottom-transition" fullscreen>      
      <v-card>
        <PDFViewer
          v-bind="{url, b64img}"
          @document-errored="onDocumentErrored"
          @exitModal="closeModal"
          @save="savePdf"
          >          
        </PDFViewer>
        <DialogLoading
          :dialog="loading_save"
          text="Guardando pdf"
        />
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import PDFViewer from './components/PDFViewer.vue'
import DialogLoading from '@/components/DialogLoading.vue'
export default {
  props: {
    close: Function,
    save: Function,
    modal_title: String,
    dialog: Boolean,        
    loading_save: Boolean,   
    url: String,
    b64img: String,
    documentByA: Object,
  },
 components: { PDFViewer,DialogLoading },
  data() {
    return {
      loading: false,
      menu: false,
      show: false,
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    
  },
  methods: {    
    closeModal() {      
      this.$emit('close')
    },
    openModalTask() {
      this.$validator.validate("client").then((valid) => {
        if (valid) this.dialog_task = true;
        else this.$toast.error("Debe completar el cliente", "Error");
      });
    },
    onDocumentErrored(e){
      console.log("ERROR onDocumentErrored",e)
    },
    savePdf(data){
      this.$emit('save',data,this.documentByA);
    }
  },
};
</script>