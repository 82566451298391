<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" max-width="750px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">

                <v-text-field
                  data-vv-as="code" 
                  label="Código*" 
                  name="code" 
                  v-model="item.code" 
                  :disabled="isViewModal() || item.id > 0" 
                  v-validate="'required|regex:^A\\d{2}$'" 
                  :persistent-hint="true"
                  hint="El código debe empezar con 'A' seguido de dos números, por ejemplo: A01."
                ></v-text-field>
                <span class="red--text">{{ errors.first("code") }}</span>

                
                <v-text-field v-if="item.id > 0" label="Cantidad de filas*" name="rows" v-model="item.rows.length"  :disabled="true"></v-text-field>

                <div v-else>
                  <v-text-field  data-vv-as="rows" label="Cantidad de filas*" name="rows" v-model="item.rows" v-validate="'required'" ></v-text-field>
                  <span class="red--text">{{ errors.first("rows") }}</span>

                </div>

                <v-checkbox data-vv-as="enabled" label="Habilitado" name="enabled" v-model="item.enabled" :disabled="isViewModal()"></v-checkbox>
                <span class="red--text">{{ errors.first("enabled") }}</span>  
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { generalService } from "@/libs/ws/generalService";

export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      show: false,
      roles: [],
      clients: [],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {},
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    createItem(e) {
      this.$validator.validate().then((valid) => {        
        if (valid) {
          e.preventDefault();
          if (this.item.id > 0) {
            this.service
              .update(this.item)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              });
          } else {
            generalService
              .create(this.item)
              .then((response) => {
                console.log(response);
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
              });
          }
        }else{
          
          console.log(this.errors)
          let msg = "";
          this.errors.items.forEach(e => {
            msg += e.msg + "\n";
          });
          this.$toast.error(msg, "Error");
        }
      });
    },
  },
};
</script>
