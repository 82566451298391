<template>
  <div class="col-md-12">
    <div class="col-md-12 pt-0">
      <div class="row box-chofer enCurso">
        <div class="col-md-1 ptop-5">
          <v-skeleton-loader v-if="loading" type="list-item" height="60" />
        </div>
        <div class="col-md-2 ptop-5">
          <v-skeleton-loader v-if="loading" type="list-item-two-line" height="60" />
        </div>
        <div class="col-md-2 ptop-5">
          <v-skeleton-loader v-if="loading" type="list-item-two-line" height="60" />
        </div>
        <div class="col-md-2 ptop-5">
          <v-skeleton-loader v-if="loading" type="list-item" height="60" />
        </div>
        <div class="col-md-1 ptop-5">
          <v-skeleton-loader v-if="loading" type="list-item-two-line" height="60" />
        </div>
        <div class="col-md-2 ptop-5">
          <v-skeleton-loader v-if="loading" type="list-item-two-line" height="60" />
        </div>
        <div class="col-md-2 ptop-5">
          <v-skeleton-loader v-if="loading" type="list-item-two-line" height="60" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MonitoringSkeleton",
  props: {
    loading: Boolean,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
