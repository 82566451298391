import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Title", {
        attrs: {
          pages: [{ icon: "file", page: _vm.page_title.toUpperCase() }],
        },
      }),
      _c(
        VContainer,
        { attrs: { fluid: "" } },
        [
          _c(
            VBtn,
            {
              staticClass: "mb-5",
              attrs: { color: "primary", dark: "" },
              on: {
                click: function ($event) {
                  _vm.open_generate_comprobante = true
                },
              },
            },
            [_vm._v("Crear Comprobante")]
          ),
          _c(VCard, [
            _c("div", { staticClass: "row mb-5 mt-0" }, [
              _vm.list_access
                ? _c("div", { staticClass: "col-md-12" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-md-12",
                        staticStyle: { background: "white" },
                      },
                      [
                        _c("div", { staticClass: "row pt-5" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-3 pt-0" },
                            [
                              _c(
                                VMenu,
                                {
                                  ref: "desde_selected",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "close-on-click": false,
                                    "return-value": _vm.desde_selected,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "max-width": "290px",
                                    "min-width": "auto",
                                    clearable: "",
                                  },
                                  on: {
                                    "update:returnValue": function ($event) {
                                      _vm.desde_selected = $event
                                    },
                                    "update:return-value": function ($event) {
                                      _vm.desde_selected = $event
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              VTextField,
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "pt-0 mt-0",
                                                    attrs: {
                                                      label: "Desde",
                                                      "prepend-icon":
                                                        "mdi-calendar",
                                                      readonly: "",
                                                    },
                                                    model: {
                                                      value: _vm.desde_selected,
                                                      callback: function ($$v) {
                                                        _vm.desde_selected = $$v
                                                      },
                                                      expression:
                                                        "desde_selected",
                                                    },
                                                  },
                                                  "v-text-field",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2292088029
                                  ),
                                  model: {
                                    value: _vm.desde_selected_open,
                                    callback: function ($$v) {
                                      _vm.desde_selected_open = $$v
                                    },
                                    expression: "desde_selected_open",
                                  },
                                },
                                [
                                  _c(
                                    VDatePicker,
                                    {
                                      attrs: {
                                        type: "date",
                                        "no-title": "",
                                        scrollable: "",
                                      },
                                      model: {
                                        value: _vm.desde_selected,
                                        callback: function ($$v) {
                                          _vm.desde_selected = $$v
                                        },
                                        expression: "desde_selected",
                                      },
                                    },
                                    [
                                      _c(VSpacer),
                                      _c(
                                        VBtn,
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              _vm.desde_selected = false
                                            },
                                          },
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                      _c(
                                        VBtn,
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.desde_selected.save(
                                                _vm.desde_selected
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" OK ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3 pt-0" },
                            [
                              _c(
                                VMenu,
                                {
                                  ref: "hasta_selected",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "close-on-click": false,
                                    "return-value": _vm.hasta_selected,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "max-width": "290px",
                                    "min-width": "auto",
                                    clearable: "",
                                  },
                                  on: {
                                    "update:returnValue": function ($event) {
                                      _vm.hasta_selected = $event
                                    },
                                    "update:return-value": function ($event) {
                                      _vm.hasta_selected = $event
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              VTextField,
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "pt-0 mt-0",
                                                    attrs: {
                                                      label: "Hasta",
                                                      "prepend-icon":
                                                        "mdi-calendar",
                                                      readonly: "",
                                                    },
                                                    model: {
                                                      value: _vm.hasta_selected,
                                                      callback: function ($$v) {
                                                        _vm.hasta_selected = $$v
                                                      },
                                                      expression:
                                                        "hasta_selected",
                                                    },
                                                  },
                                                  "v-text-field",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1999352541
                                  ),
                                  model: {
                                    value: _vm.hasta_selected_open,
                                    callback: function ($$v) {
                                      _vm.hasta_selected_open = $$v
                                    },
                                    expression: "hasta_selected_open",
                                  },
                                },
                                [
                                  _c(
                                    VDatePicker,
                                    {
                                      attrs: {
                                        type: "date",
                                        "no-title": "",
                                        scrollable: "",
                                      },
                                      model: {
                                        value: _vm.hasta_selected,
                                        callback: function ($$v) {
                                          _vm.hasta_selected = $$v
                                        },
                                        expression: "hasta_selected",
                                      },
                                    },
                                    [
                                      _c(VSpacer),
                                      _c(
                                        VBtn,
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              _vm.hasta_selected = false
                                            },
                                          },
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                      _c(
                                        VBtn,
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.hasta_selected.save(
                                                _vm.hasta_selected
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" OK ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3 pt-0" },
                            [
                              _c(VCombobox, {
                                staticClass: "pt-0 mt-0",
                                attrs: {
                                  autocomplete: "off",
                                  "data-vv-as": "cbte_tipo_selected",
                                  name: "cbte_tipo_selected",
                                  "item-text": "Desc",
                                  "item-value": "Id",
                                  items: _vm.cbte_tipos,
                                  label: "Tipo de Comprobante",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.cbte_tipo_selected,
                                  callback: function ($$v) {
                                    _vm.cbte_tipo_selected = $$v
                                  },
                                  expression: "cbte_tipo_selected",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3 pt-0" },
                            [
                              _c(VTextField, {
                                staticClass: "pt-0 mt-0",
                                attrs: {
                                  clearable: "",
                                  autocomplete: "off",
                                  label: "Nº Comprobante",
                                },
                                model: {
                                  value: _vm.voucher_number_selected,
                                  callback: function ($$v) {
                                    _vm.voucher_number_selected = $$v
                                  },
                                  expression: "voucher_number_selected",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-3 pt-0" },
                            [
                              _c(VCombobox, {
                                staticClass: "pt-0 mt-0",
                                attrs: {
                                  autocomplete: "off",
                                  "data-vv-as": "state_selected",
                                  name: "state_selected",
                                  "item-text": "name",
                                  "item-value": "id",
                                  items: _vm.states,
                                  label: "Estado",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.state_selected,
                                  callback: function ($$v) {
                                    _vm.state_selected = $$v
                                  },
                                  expression: "state_selected",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3 pt-0" },
                            [
                              _c(VCombobox, {
                                staticClass: "pt-0 mt-0",
                                attrs: {
                                  autocomplete: "off",
                                  "data-vv-as": "receiver_selected",
                                  name: "receiver_selected",
                                  "item-text": "name",
                                  "item-value": "id",
                                  items: _vm.receivers,
                                  label: "Receptor",
                                  clearable: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.clientChangeCombo($event)
                                  },
                                },
                                model: {
                                  value: _vm.receiver_selected,
                                  callback: function ($$v) {
                                    _vm.receiver_selected = $$v
                                  },
                                  expression: "receiver_selected",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3 pt-0" },
                            [
                              _c(VCombobox, {
                                staticClass: "pt-0 mt-0",
                                attrs: {
                                  autocomplete: "off",
                                  "data-vv-as": "emitter_selected",
                                  name: "emitter_selected",
                                  "item-text": "name",
                                  "item-value": "id",
                                  items: _vm.emitters,
                                  label: "Emisor",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.emitter_selected,
                                  callback: function ($$v) {
                                    _vm.emitter_selected = $$v
                                  },
                                  expression: "emitter_selected",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col-md-3 pt-0",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "end",
                              },
                            },
                            [
                              _c(
                                VMenu,
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              VBtn,
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      color: "blue-grey mr-2",
                                                      dark: "",
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(" Exportar "),
                                                _c("font-awesome-icon", {
                                                  staticClass: "ml-2",
                                                  attrs: { icon: "caret-down" },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    289319735
                                  ),
                                },
                                [
                                  _c(
                                    VList,
                                    [
                                      _c(
                                        VListItem,
                                        { on: { click: _vm.exportToExcel } },
                                        [
                                          _c(VListItemTitle, [
                                            _vm._v(
                                              "Exportar detalle de ventas por concepto"
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VListItem,
                                        {
                                          on: {
                                            click: function ($event) {
                                              _vm.dialog_libro_iva = true
                                            },
                                          },
                                        },
                                        [
                                          _c(VListItemTitle, [
                                            _vm._v("Exportar libro IVA"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VBtn,
                                {
                                  attrs: { color: "info" },
                                  on: { click: _vm.filter },
                                },
                                [_vm._v("Buscar")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm.list_access
            ? _c(
                VCard,
                [
                  _c(
                    VCardTitle,
                    [
                      _c("span", { staticClass: "text-uppercase" }, [
                        _vm._v(_vm._s(_vm.page_title)),
                      ]),
                      _c(VSpacer),
                      _c(VTextField, {
                        attrs: {
                          "append-icon": "search",
                          label: "Buscar",
                          "single-line": "",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(VDataTable, {
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.list,
                      search: _vm.search,
                      loading: _vm.loading,
                      "loading-text": "Cargando...",
                      options: {
                        descending: true,
                        itemsPerPage: 15,
                      },
                      "footer-props": {
                        itemsPerPageOptions: [5, 10, 15, 50, -1],
                      },
                    },
                    on: { pagination: _vm.updatePagination },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function (props) {
                            return [
                              _c("tr", [
                                _c("td", [
                                  _vm._v(_vm._s(props.item.creation_date)),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(props.item.cbte_tipo_name)),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      props.item.voucher_number != null
                                        ? props.item.pto_vta
                                            .toString()
                                            .padStart(4, "0") +
                                            "-" +
                                            props.item.voucher_number
                                              .toString()
                                              .padStart(8, "0")
                                        : "-"
                                    )
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(props.item.nro_order)),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(props.item.emitter.name)),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(props.item.receiver.name)),
                                ]),
                                _c("td", [_vm._v(_vm._s(props.item.mon_name))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatUtils.formatPrice(
                                        props.item.imp_total
                                      )
                                    )
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(props.item.state.name)),
                                ]),
                                _c(
                                  "td",
                                  { staticClass: "text-center px-0" },
                                  [
                                    props.item.cbtes_asoc.length > 0
                                      ? _c(
                                          VTooltip,
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        VIcon,
                                                        _vm._g(
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              small: "",
                                                              color: "blue",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.viewcbtesAsoc(
                                                                  props.item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v(" info ")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [_c("span", [_vm._v("Ver")])]
                                        )
                                      : _c("p", [_vm._v("-")]),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  { staticClass: "text-center px-0" },
                                  [
                                    props.item.state.id == 1
                                      ? _c(
                                          VTooltip,
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        VIcon,
                                                        _vm._g(
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              small: "",
                                                              color: "green",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.printItem(
                                                                  props.item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v(" print ")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [_c("span", [_vm._v("Imprimir")])]
                                        )
                                      : _vm._e(),
                                    props.item.state.id == 1 &&
                                    props.item.is_invoice
                                      ? _c(
                                          VTooltip,
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        VIcon,
                                                        _vm._g(
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              small: "",
                                                              color: "red",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.cancelInvoice(
                                                                  props.item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v(" close ")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [_c("span", [_vm._v("Anular")])]
                                        )
                                      : _vm._e(),
                                    props.item.state.id == 0
                                      ? _c(
                                          VTooltip,
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        VIcon,
                                                        _vm._g(
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              small: "",
                                                              color: "blue",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openDialogSendToAFIP(
                                                                  props.item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v(" send ")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Enviar a la AFIP"),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    props.item.state.id == 0
                                      ? _c(
                                          VTooltip,
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    return [
                                                      _vm.delete_access
                                                        ? _c(
                                                            VIcon,
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                                attrs: {
                                                                  small: "",
                                                                  color: "red",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.deleteItem(
                                                                        props.item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [_vm._v(" delete ")]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [_c("span", [_vm._v("Eliminar")])]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "no-results",
                          fn: function () {
                            return [
                              _c(
                                VAlert,
                                {
                                  attrs: {
                                    value: true,
                                    color: "error",
                                    icon: "warning",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        "Busqueda sin resultados - " +
                                          _vm.search
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      692120787
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("DialogGenerarComprobante", {
            attrs: {
              title: "Generar Comprobante",
              open: _vm.open_generate_comprobante,
              invoice_to_cancel: _vm.invoice_to_cancel,
            },
            on: {
              cancel: function ($event) {
                _vm.open_generate_comprobante = false
                _vm.invoice_to_cancel = null
              },
              updateDialog: _vm.filter,
            },
          }),
          _c("DialogYesNo", {
            attrs: {
              title: _vm.title_dialog,
              message: _vm.message_dialog,
              open: _vm.dialog_open_afip,
              loading: _vm.loading_dialog,
            },
            on: {
              accept: _vm.sendToAFIP,
              cancel: _vm.closeDialog,
              reload: _vm.filter,
            },
          }),
          _c("DialogLoading", {
            attrs: { dialog: _vm.dialog_imprimiendo, text: "Imprimiendo..." },
          }),
          _c("DialogDelete", {
            attrs: { dialog_delete: _vm.dialog_delete },
            on: {
              updateDialogDelete: _vm.updateDialogDelete,
              deleteItemConfirm: _vm.deleteItemConfirm,
            },
          }),
          _c("DialogExportLibroIVA", {
            attrs: { open: _vm.dialog_libro_iva },
            on: {
              cancel: function ($event) {
                _vm.dialog_libro_iva = false
              },
            },
          }),
          _c("CbtesAsocModal", {
            attrs: { open: _vm.dialog_cbtes_asoc, item: _vm.voucher_selected },
            on: {
              cancel: function ($event) {
                _vm.dialog_cbtes_asoc = false
                _vm.voucher_selected = null
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }