<template>
  <div>
    <Title :pages="[{ icon: 'file-invoice-dollar', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <v-card>
        <div class="row mb-5 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="desde_selected"
                    v-model="desde_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="desde_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="desde_selected" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="desde_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="hasta_selected"
                    v-model="hasta_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="hasta_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="hasta_selected" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-text-field clearable v-model="nro_orden_selected" autocomplete="off" class="pt-0 mt-0" label="Nº Orden"></v-text-field>
                </div>

                <div class="col-md-3 pt-0">
                  <v-text-field clearable v-model="nro_remito_selected" autocomplete="off" class="pt-0 mt-0" label="Nº Remito"></v-text-field>
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="state_selected"
                    autocomplete="off"
                    data-vv-as="state_selected"
                    name="state_selected"
                    item-text="name"
                    item-value="id"
                    :items="states"
                    label="Estado"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="client_selected"
                    autocomplete="off"
                    data-vv-as="client_selected"
                    name="client_selected"
                    item-text="business_name"
                    item-value="id"
                    :items="clients"
                    label="Cliente"
                    class="pt-0 mt-0"
                    v-on:change="clientChangeCombo($event)"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="applicant_selected"
                    autocomplete="off"
                    data-vv-as="applicant_selected"
                    name="applicant_selected"
                    item-text="name"
                    item-value="id"
                    :items="applicants"
                    label="Solicitante"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-3 pt-0" style="display: flex; justify-content: end">
                  <v-btn color="blue-grey" class="mr-2 text-white" @click="exportToExcel">Exportar</v-btn>
                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <div v-if="selected.length != 0" class="col-md-12" style="background: #f4f1f1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="green" class="cursor mb-1 mt-1" @click="generateInvoiceModal">post_add</v-icon>
            </template>
            <span>Generar facturas</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="blue" class="cursor mb-1 mt-1 ml-5" @click="downloadRemitos">download</v-icon>
            </template>
            <span>Descargar remitos</span>
          </v-tooltip>
        </div>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
          :single-expand="false"
          :expanded.sync="expanded"
          show-expand
          v-model="selected"
          show-select
          :single-select="false"
        >
          <template v-slot:item="{ item, expand, isExpanded, isSelected, select }">
            <tr>
              <td>
                <font-awesome-icon v-if="!isExpanded" icon="chevron-down" @click="expand(!isExpanded)" class="cursor" />
                <font-awesome-icon v-else icon="chevron-up" @click="expand(!isExpanded)" class="cursor" />
              </td>
              <td>
                <v-checkbox :input-value="isSelected" style="margin: 0px; padding: 0px" hide-details @change="select($event)" :disabled="item.state.id == remitosWS.STATE_BILLED" />
              </td>
              <td>{{ item.id.toString().padStart(7, "0") }}</td>
              <td>{{ item.work_order.id.toString().padStart(7, "0") }}</td>
              <td>{{ item.creation_date }}</td>
              <td>{{ item.work_order.client.business_name }}</td>
              <td style="min-width: 135px" v-html="service.getStateHtml(item.state)"></td>
              <td class="text-right">{{ formatUtils.formatPrice(item.total) }}</td>

              <td class="justify-content-center px-0">
                <v-tooltip bottom v-if="item.state.id != remitosWS.STATE_BILLED">
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue" class="mr-2" @click="editItem(item)"> edit </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="viewItem(item)"> remove_red_eye </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>

                <v-menu offset-y v-if="sensible_access">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on="on" v-bind="attrs" small color="orange" class="mr-2"> print </v-icon>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title class="cursor" @click="printCocepts(item)">Imprimir remito</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="cursor" @click="printValues(item)">Imprimir remito con precios</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-tooltip bottom v-else>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" v-bind="attrs" small color="orange" class="mr-2" @click="printCocepts(item)"> print </v-icon>
                  </template>
                  <span>Imprimir</span>
                </v-tooltip>

                <!--<v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="green" class="mr-2" @click="generateRemito(item)"> paid </v-icon>
                  </template>
                  <span>Facturar</span>
                </v-tooltip>-->

                <v-tooltip bottom v-if="item.state.id != remitosWS.STATE_BILLED">
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2" @click="deleteItem(item)"> delete </v-icon>
                  </template>
                  <span>Anular</span>
                </v-tooltip>
              </td>
            </tr>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-data-table :headers="headersTasks" :items="item.items" :search="search" :loading-text="'Cargando'" hide-default-footer disable-pagination class="background-color: grey lighten-3">
                <template v-slot:item="props">
                  <tr>
                    <td>{{ props.index + 1 }}</td>
                    <td>{{ props.item.asignated_task.task.name }}</td>
                    <td class="text-right">{{ formatUtils.formatPrice(props.item.spent) }}</td>
                    <td class="text-right">{{ formatUtils.formatPrice(props.item.spent_writing_right) }}</td>
                    <td class="text-right">{{ formatUtils.formatPrice(props.item.spent_notarial_contribution) }}</td>
                    <td class="text-right">{{ formatUtils.formatPrice(props.item.honorary) }}</td>
                    <td class="text-right">{{ formatUtils.formatPrice(props.item.legalizacion) }}</td>
                    <td class="text-right">{{ formatUtils.formatPrice(props.item.apostilla) }}</td>
                    <td class="text-right">{{ formatUtils.formatPrice(props.item.apostilla_urgente) }}</td>
                    <td class="text-right">{{ formatUtils.formatPrice(props.item.anexo_spent) }}</td>
                    <td class="text-right">{{ formatUtils.formatPrice(props.item.anexo_honorary) }}</td>
                    <td class="text-right">{{ formatUtils.formatPrice(props.item.total) }}</td>
                  </tr>
                </template>
              </v-data-table>
            </td>
          </template>

          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />
      <DialogLoading :dialog="dialog_loading && !loading_massive" text="Cargando..." />
      <DialogGenerarFactura        
        title="Generar Factura"    
        :remitos="selected"            
        :open="open_generate_factura_massive"
        @cancel="open_generate_factura_massive = false"
        @accept="generateInvoice"
        @loadList="filter"
      />

      <!--Modal print -->
      <v-layout class="pull-left">
          <v-dialog v-model="loading_massive" max-width="400px" @keydown.esc="loading_massive = false">
              <v-card>
                  <v-form v-on:submit.prevent="imprimir">
                      <v-progress-linear
                        :height="20"
                        v-show="!loading_massive"
                        indeterminate
                        color="green darken-2"
                      ></v-progress-linear>
                      <v-card-text>
                          <div>
                              <div class="row">
                                  <div class="col-md-12 subtitle-1 text-center">
                                      Exportando...
                                  </div>
                                  <div class="col-md-12" id="progress_bar">
                                      <v-progress-linear
                                          v-show="loading_massive"
                                          v-model="loading_percentage"
                                          :height="25"        
                                          color="green darken-2"                               
                                      >
                                          <strong>{{ Math.ceil(loading_percentage) }}%</strong>
                                      </v-progress-linear>
                                  </div>
                              </div>
                          </div>
                      </v-card-text>
                  </v-form>
              </v-card>
          </v-dialog>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import JSZip, { forEach } from "jszip";
import { saveAs } from "file-saver";
import Constant from "@/constants/sections";
import { remitoService } from "@/libs/ws/remitoService";
import { generalService } from "@/libs/ws/generalService";
import { formatUtils } from "@/libs/utils/formatUtils";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import DialogLoading from "@/components/DialogLoading.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import Modal from "./Modal.vue";
import DialogGenerarFactura from "./DialogGenerarFactura.vue";

export default {
  components: { Title, DialogDelete, Modal, DialogLoading, DialogGenerarFactura },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {      
      loading_percentage: 0,
      loading_massive: false,
      filesToZip: [],
      open_generate_factura_massive: false,
      selected: [],
      remitosWS: remitoService,
      formatUtils: formatUtils,
      parent: this,
      expanded: [],
      service: remitoService,
      page_title: "Remitos",
      modal_title: "Remito",
      defaultItem: null,
      dialog_loading: false,
      headers: [
        { text: "Nº Remito", value: "id" },
        { text: "Nº Orden", value: "work_order.id" },
        { text: "Fecha", value: "creation_date" },
        { text: "Cliente", value: "work_order.client.business_name" },
        { text: "Estado", value: "state.name" },
        { text: "Total", value: "total" },
        { text: "Accion", value: "", align: "center" },
      ],
      headersTasks: [
        { text: "", value: "number", sortable: false },
        { text: "Tarea", value: "asignated_task.task.name", sortable: false },
        { text: "Gasto", value: "spent", sortable: false, class: "bg-grey" },
        { text: "Derecho escritura", value: "spent_writing_right", sortable: false, class: "bg-grey" },
        { text: "Aporte notarial", value: "spent_notarial_contribution", sortable: false, class: "bg-grey" },
        { text: "Total honorarios", value: "honorary", sortable: false, class: "bg-grey" },
        { text: "Legalizacion", value: "legalizacion", sortable: false, class: "bg-grey" },
        { text: "Apostilla", value: "apostilla", sortable: false, class: "bg-grey" },
        { text: "Apostilla urgente", value: "apostilla_urgente", sortable: false, class: "bg-grey" },
        { text: "Anexo Gasto", value: "anexo_spent", sortable: false, class: "bg-grey" },
        { text: "Anexo Honorario", value: "anexo_honorary", sortable: false, class: "bg-grey" },
        { text: "Total", value: "total", sortable: false },
      ],
      list: [],
      dialog_delete: false,
      desde_selected_open: false,
      hasta_selected_open: false,
      nro_orden_selected: null,
      nro_remito_selected: null,
      client_selected: null,
      state_selected: null,
      applicant_selected: null,
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      dialog_task_state: false,
      dialog_files: false,
      asignedTaskSelected: null,
      documentsSelected: [],
      criteriaPriceSelected: null,
      taskNameSelected: "",
      mainItem: {
        id: "",
        creation_date: null,
        work_order: {
          id: "",
          client: null,
          applicant: null,
        },
        items: [],
      },
      clients: [],
      applicants: [],
      states: [],
    };
  },
  mounted() {
    this.loadComboData();
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.loadList(this.service, this.getFilterParams());
  },
  methods: {
    getFilterParams() {
      console.log("this.state_selected",this.state_selected)
      let param = {
        from: this.desde_selected,
        to: this.hasta_selected,
        client: (this.client_selected != null)?this.client_selected.id:null,
        applicant: this.applicant_selected,
        state: (this.state_selected != null && this.state_selected.id != -1)?this.state_selected:null,
        nro_orden: this.nro_orden_selected,
        nro_remito: this.nro_remito_selected,
      };
      
      if(this.state_selected != null && this.state_selected.id == -1)
        param['all_states'] = true;
      return param;
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    exportToExcel() {
      if (this.loading) return;
      this.loading = true;
      let param = this.getFilterParams();

      this.service
        .export_filter(param)
        .then((response) => {
          if (Object.prototype.toString.call(response) != "[object ArrayBuffer]" && !response.status) {
            this.$toast.error(response.msg, "Error");
            this.loading = false;
            return;
          }

          let blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          let link = document.createElement("a");
          //link.setAttribute('download', "export")
          link.download = "remitos.xlsx";
          link.href = window.URL.createObjectURL(blob);
          link.target = "_blank";
          link.click();
          this.loading = false;
        })
        .catch((e) => {
          console.log("service.export_filter error");
          console.log(e);
          this.loading = false;
        });
    },
    loadComboData() {
      generalService.filter({}, Constant.SECTION_CLIENTS).then((response) => {
        this.clients = response.result;
      });
      this.service.get_states().then((response) => {
        this.states = response.result;
      });

      if (this.client_selected != null && this.client_selected.id > 0) this.clientChangeCombo();
    },
    clientChangeCombo(event) {
      var param = { client: this.client_selected.id };
      generalService.filter(param, Constant.SECTION_APPLICANTS).then((response) => {
        console.log(this.applicants, "response");
        this.applicants = response.result;
      });
    },
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadList(this.service);
    },
    printCocepts(item) {
      if (this.dialog_loading) return;
      this.dialog_loading = true;
      this.service
        .printCocepts(item.id)
        .then((response) => {
          if (!generalService.downloadPdf(response, "remito_" + item.id + ".pdf")) {
            this.$toast.error(response.msg, "Error");
          }
        })
        .catch((e) => {
          console.log("service.print error");
          console.log(e);
        })
        .finally(() => {
          this.dialog_loading = false;
        });
    },
    printValues(item) {
      if (this.dialog_loading) return;
      this.dialog_loading = true;
      return this.service
        .printValues(item.id)
        .then((response) => {
          console.log(this.selected.length > 0)
          const file = generalService.downloadPdf(response, "remito_" + item.id + ".pdf", this.selected.length > 0)
          console.log("file",file)
          if(this.selected.length > 0 && file != null){
            this.filesToZip.push(file);
          }else if (!file) {
            this.$toast.error(response.msg, "Error");
          }
          console.log("this.filesToZip",this.filesToZip)
        })
        .catch((e) => {
          console.log("service.print error");
          console.log(e);
        })
        .finally(() => {
          this.dialog_loading = false;
        });
    },
    generateInvoiceModal(){         
      let clientsIds = this.selected.map(a => a.work_order.client.id);      
      let uniqueClientsIds = [...new Set(clientsIds)];  
      console.log("uniqueClientsIds",uniqueClientsIds.length)    
      if(uniqueClientsIds.length == 1){
        console.log("xxx")
        this.open_generate_factura_massive = true;
      }else{
        this.$toast.error("No se puede generar facturas para distintos clientes", "Error");
      }
    },
    generateInvoice(){
      console.log("generateInvoice?")
    },
    async downloadRemitos(){      
      this.loading_massive = true;
      var contador = 0;
      for (const element of this.selected) {
          await this.printValues(element);
          contador++;
          this.loading_percentage = (contador * 100) / this.selected.length;
      }
      var zip = new JSZip();
      console.log("yhis.filesToZip",this.filesToZip)
      this.filesToZip.forEach(element => {
        console.log("element",element)
        zip.file(element.filename, element.file);
      });
      await zip.generateAsync({ type: "blob" }).then(function(content) {
          saveAs(content, "remitos.zip");
      });
      this.filesToZip = [];
      this.loadList(this.service, this.getFilterParams());
      this.loading_percentage = 0;
      this.loading_massive = false;
    }
  },
};

// TODO agregar un boton de limpiar busqueda.
</script>
