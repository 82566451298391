import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    [
      _vm.dialog
        ? _c(
            VDialog,
            {
              attrs: {
                value: _vm.dialog,
                transition: "dialog-bottom-transition",
                fullscreen: "",
              },
              on: {
                "click:outside": function ($event) {
                  return _vm.$emit("close")
                },
                input: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [
              _c(
                VCard,
                [
                  _c(
                    "PDFViewer",
                    _vm._b(
                      {
                        on: {
                          "document-errored": _vm.onDocumentErrored,
                          exitModal: _vm.closeModal,
                          save: _vm.savePdf,
                        },
                      },
                      "PDFViewer",
                      { url: _vm.url, b64img: _vm.b64img },
                      false
                    )
                  ),
                  _c("DialogLoading", {
                    attrs: { dialog: _vm.loading_save, text: "Guardando pdf" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }