import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sidebar: {
      open: false,
      drawer: false,
      mini: false,
      expand_on_hover: false,
    },
    config: {
      items_per_page: 10,
    },
  },
  mutations: {
    setOpenSidebar: function (state, payload) {
      if (payload.open != undefined) state.sidebar.open = payload.open;

      if (payload.drawer != undefined) state.sidebar.drawer = payload.drawer;

      if (payload.mini != undefined) state.sidebar.mini = payload.mini;
      if (payload.expand_on_hover != undefined) state.sidebar.expand_on_hover = payload.expand_on_hover;
    },
  },
  actions: {},
  modules: {},
});
