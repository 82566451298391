import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { BNav } from "bootstrap-vue";

import {
  faFilePowerpoint,
  faCaretDown,
  faUserSecret,
  faDesktop,
  faRoad,
  faTruck,
  faUsers,
  faCog,
  faMapMarker,
  faChartBar,
  faRoute,
  faMapPin,
  faMapMarkedAlt,
  faTruckLoading,
  faDolly,
  faTruckMoving,
  faTag,
  faShieldAlt,
  faClipboardList,
  faFilter,
  faChevronDown,
  faChevronUp,
  faBan,
  faUserCog,
  faDownload,
  faReceipt,
  faCrosshairs,
  faFileExcel,
  faFileInvoice,
  faDollarSign,
  faPlaneDeparture,
  faBuilding,
  faFile,
  faUserTie,
  faList,
  faListCheck,
  faArrowsRotate,
  faPerson,
  faSignature,
  faUsersBetweenLines,
  faFileInvoiceDollar,
  faXmark,
  faPlus,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faMaximize,
  faMinimize,
  faScroll,
  faFloppyDisk,
  faBolt,
  faInbox,
  faFolder,
  faLocation
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faFilePowerpoint,
  faCaretDown,
  faFileInvoiceDollar,
  faUsersBetweenLines,
  faSignature,
  faPerson,
  faListCheck,
  faArrowsRotate,
  faList,
  faUserTie,
  faFile,
  faBuilding,
  faPlaneDeparture,
  faDollarSign,
  faFileInvoice,
  faTruckMoving,
  faDownload,
  faTag,
  faShieldAlt,
  faClipboardList,
  faUserSecret,
  faDesktop,
  faRoad,
  faTruck,
  faUsers,
  faCog,
  faMapMarker,
  faChartBar,
  faRoute,
  faMapPin,
  faMapMarkedAlt,
  faTruckLoading,
  faDolly,
  faFilter,
  faChevronDown,
  faChevronUp,
  faBan,
  faUserCog,
  faReceipt,
  faCrosshairs,
  faFileExcel,
  faXmark,
  faPlus,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faMaximize,
  faMinimize,
  faScroll,
  faFloppyDisk,
  faBolt,
  faInbox,
  faFolder,
  faLocation
);

export default FontAwesomeIcon;
