import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VDialog,
        {
          attrs: { fullscreen: "", transition: "dialog-bottom-transition" },
          on: {
            "click:outside": function ($event) {
              return _vm.closeModal()
            },
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeModal()
            },
            input: function ($event) {
              return _vm.closeModal()
            },
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, { staticClass: "headline" }, [
                _vm._v("Impresion de foja de " + _vm._s(_vm.task_name)),
              ]),
              _c(
                VCardText,
                {
                  staticStyle: {
                    background: "#ecf0f5",
                    height: "calc(100vh - 110px)",
                    overflow: "scroll",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "mt-5 elevation-2 p-5",
                      staticStyle: {
                        width: "1000px",
                        margin: "0 auto",
                        background: "white",
                        "min-height": "95%",
                      },
                    },
                    [
                      _vm.step_0
                        ? _c("div", [
                            _c("h5", { staticClass: "pb-5" }, [
                              _c("b", [
                                _vm._v(
                                  "Complete los campos para la impresion de foja"
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-12 d-flex" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "fs-16 d-flex align-items-center pr-2",
                                  },
                                  [_vm._v("Buenos Aires,")]
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { width: "80px" } },
                                  [
                                    _c(VTextField, {
                                      attrs: {
                                        dense: "",
                                        "data-vv-as": "day",
                                        label: "Día",
                                        name: "day",
                                        maxlength: "2",
                                      },
                                      model: {
                                        value: _vm.foja.day,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.foja, "day", $$v)
                                        },
                                        expression: "foja.day",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "fs-16 d-flex align-items-center pl-2 pr-2",
                                  },
                                  [_vm._v("de")]
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { width: "180px" } },
                                  [
                                    _c(VTextField, {
                                      attrs: {
                                        dense: "",
                                        "data-vv-as": "month",
                                        label: "Mes",
                                        name: "month",
                                      },
                                      model: {
                                        value: _vm.foja.month,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.foja, "month", $$v)
                                        },
                                        expression: "foja.month",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "fs-16 d-flex align-items-center pl-2 pr-2",
                                  },
                                  [_vm._v("de")]
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { width: "130px" } },
                                  [
                                    _c(VTextField, {
                                      attrs: {
                                        dense: "",
                                        "data-vv-as": "ano",
                                        label: "Año",
                                        name: "ano",
                                      },
                                      model: {
                                        value: _vm.foja.year,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.foja, "year", $$v)
                                        },
                                        expression: "foja.year",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "fs-16 d-flex align-items-center pl-2 pr-2",
                                  },
                                  [_vm._v(". En mi caracter de escribano")]
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticStyle: { width: "780px" } },
                              [
                                _c(VTextField, {
                                  attrs: {
                                    dense: "",
                                    "data-vv-as": "record",
                                    label: "",
                                    name: "notary_record",
                                  },
                                  model: {
                                    value: _vm.foja.notary_record,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.foja, "notary_record", $$v)
                                    },
                                    expression: "foja.notary_record",
                                  },
                                }),
                              ],
                              1
                            ),
                            !_vm.isCertificacionFirma()
                              ? _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-12 d-flex" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "fs-16 d-flex align-items-center pr-4",
                                        },
                                        [
                                          _vm._v(
                                            "CERTIFICO: Que la reproduccion anexa, extendida en"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "480px" } },
                                        [
                                          _c(VTextField, {
                                            attrs: {
                                              dense: "",
                                              "data-vv-as": "texto2",
                                              label: "",
                                              name: "texto2",
                                            },
                                            model: {
                                              value: _vm.foja.texto2,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.foja,
                                                  "texto2",
                                                  $$v
                                                )
                                              },
                                              expression: "foja.texto2",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            !_vm.isCertificacionFirma()
                              ? _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-12 d-flex" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "fs-16 d-flex align-items-center",
                                        },
                                        [
                                          _vm._v(
                                            "foja/s que sello y firmo, es COPIA FIEL de su original, que tengo a la vista, doy fe."
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm.isCertificacionFotocopia()
                              ? _c("div", [
                                  _vm.isCertificacionFotocopia()
                                    ? _c("hr", {
                                        staticStyle: {
                                          border: "0px",
                                          "border-top": "3px dotted",
                                          "margin-top": "40px !important",
                                          "margin-bottom": "20px !important",
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "h5",
                                    {
                                      staticStyle: {
                                        "margin-bottom": "20px !important",
                                      },
                                    },
                                    [
                                      _c("b", [
                                        _vm._v("Seleccione una plantilla"),
                                      ]),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm.isCertificacionFotocopia()
                              ? _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-12 d-flex" },
                                    [
                                      _c(VCombobox, {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        attrs: {
                                          dense: "",
                                          "data-vv-as": "detail",
                                          name: "detail",
                                          items: _vm.plantillas,
                                          "item-text": "name",
                                          "item-value": "id",
                                          label: "Plantilla de foja",
                                          autocomplete: "off",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.plantillaSelected()
                                          },
                                        },
                                        model: {
                                          value: _vm.plantilla_seleccionada,
                                          callback: function ($$v) {
                                            _vm.plantilla_seleccionada = $$v
                                          },
                                          expression: "plantilla_seleccionada",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            !_vm.isCertificacionFirma()
                              ? _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-12 d-flex" },
                                    [
                                      _c(VTextarea, {
                                        attrs: {
                                          rows: "12",
                                          outlined: "",
                                          "data-vv-as": "texto3",
                                          name: "texto3",
                                          label: "Agregado",
                                        },
                                        model: {
                                          value: _vm.foja.texto3,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.foja, "texto3", $$v)
                                          },
                                          expression: "foja.texto3",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm.isCertificacionFirma()
                              ? _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-12 d-flex" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "fs-16 d-flex align-items-center pr-4",
                                        },
                                        [_vm._v("CERTIFICO: Que la/s")]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "480px" } },
                                        [
                                          _c(VTextField, {
                                            attrs: {
                                              dense: "",
                                              "data-vv-as": "texto2",
                                              label: "",
                                              name: "texto2",
                                            },
                                            model: {
                                              value: _vm.foja.texto2,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.foja,
                                                  "texto2",
                                                  $$v
                                                )
                                              },
                                              expression: "foja.texto2",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "fs-16 d-flex align-items-center pl-4 pr-4",
                                        },
                                        [_vm._v("que obra/n en el")]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm.isCertificacionFirma()
                              ? _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-12 d-flex" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "fs-16 d-flex align-items-center pr-4",
                                        },
                                        [
                                          _vm._v(
                                            "documento que adjunto a esta foja, cuyo requerimiento de certificaión se"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm.isCertificacionFirma()
                              ? _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-12 d-flex" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "fs-16 d-flex align-items-center pr-4",
                                        },
                                        [
                                          _vm._v(
                                            "formaliza simultaneamente por ACTA número"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "250px" } },
                                        [
                                          _c(VCombobox, {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'",
                                              },
                                            ],
                                            attrs: {
                                              dense: "",
                                              "data-vv-as": "detail",
                                              name: "detail",
                                              items: _vm.getFojasComboBox(),
                                              "item-text": "name",
                                              "item-value": "id",
                                              label: "Nº Acta/Libro/foja",
                                              autocomplete: "off",
                                              clearable: "",
                                              multiple: "",
                                              "small-chips": "",
                                            },
                                            model: {
                                              value: _vm.foja.detail,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.foja,
                                                  "detail",
                                                  $$v
                                                )
                                              },
                                              expression: "foja.detail",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "fs-16 d-flex align-items-center pl-4 pr-4",
                                        },
                                        [_vm._v("del LIBRO...")]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _c("hr"),
                            _c("div", { staticClass: "row mt-5" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-6 d-flex" },
                                [
                                  _c(VTextField, {
                                    attrs: {
                                      dense: "",
                                      "data-vv-as": "font_size",
                                      label: "Tamaño de letra",
                                      name: "font_size",
                                    },
                                    model: {
                                      value: _vm.font_size,
                                      callback: function ($$v) {
                                        _vm.font_size = $$v
                                      },
                                      expression: "font_size",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-6 d-flex" },
                                [
                                  _c(VTextField, {
                                    attrs: {
                                      dense: "",
                                      "data-vv-as": "leading",
                                      label: "Interlineado",
                                      name: "leading",
                                    },
                                    model: {
                                      value: _vm.leading,
                                      callback: function ($$v) {
                                        _vm.leading = $$v
                                      },
                                      expression: "leading",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm.isCertificacionFirma()
                              ? _c(
                                  "div",
                                  [
                                    _c("hr"),
                                    _c("h5", { staticClass: "mt-5 pb-5" }, [
                                      _c("b", [
                                        _vm._v(
                                          "Seleccione las personerias que desea imprimir en la foja"
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      VBtn,
                                      {
                                        staticClass: "mt-5",
                                        attrs: { color: "success" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.newPersoneriaModal()
                                          },
                                        },
                                      },
                                      [_vm._v("Caracter")]
                                    ),
                                    _c(
                                      VCard,
                                      {
                                        staticClass: "mt-5",
                                        staticStyle: {
                                          border: "solid 1px #1c1c1c",
                                        },
                                      },
                                      [
                                        _c(
                                          VCardTitle,
                                          {
                                            style: {
                                              backgroundColor: "#FFF",
                                              border: 0,
                                            },
                                          },
                                          [
                                            _c(VSpacer),
                                            _c(VTextField, {
                                              attrs: {
                                                "append-icon": "search",
                                                label: "Buscar",
                                                "single-line": "",
                                                "hide-details": "",
                                              },
                                              model: {
                                                value: _vm.search,
                                                callback: function ($$v) {
                                                  _vm.search = $$v
                                                },
                                                expression: "search",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(VDataTable, {
                                          staticClass: "pb-4",
                                          attrs: {
                                            headers: _vm.headers_signatories,
                                            items: _vm.personalities,
                                            "items-per-page": 100,
                                            loading: _vm.loading,
                                            "loading-text": "Cargando...",
                                            "show-select": "",
                                            "single-select": false,
                                            search: _vm.search,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "item",
                                                fn: function (props) {
                                                  return [
                                                    _c("tr", [
                                                      _c(
                                                        "td",
                                                        [
                                                          _c(VCheckbox, {
                                                            staticStyle: {
                                                              margin: "0px",
                                                              padding: "0px",
                                                            },
                                                            attrs: {
                                                              disabled:
                                                                props.item
                                                                  .expiration_date !=
                                                                  null &&
                                                                new Date(
                                                                  props.item.expiration_date
                                                                ) < new Date(),
                                                              "input-value":
                                                                props.isSelected,
                                                              "hide-details":
                                                                "",
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return props.select(
                                                                  $event
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.item.name
                                                          )
                                                        ),
                                                      ]),
                                                      _c(
                                                        "td",
                                                        _vm._l(
                                                          props.item
                                                            .signatories,
                                                          function (obj) {
                                                            return _c(
                                                              VChip,
                                                              {
                                                                key: obj.id,
                                                                staticClass:
                                                                  "mr-1",
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "truncate",
                                                                    attrs: {
                                                                      title:
                                                                        obj.alias,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          obj.alias
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          class:
                                                            _vm.checkExpiredSignatory(
                                                              props.item
                                                                .expiration_date
                                                            ),
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              props.item
                                                                .expiration_date !=
                                                                null
                                                                ? props.item
                                                                    .expiration_date
                                                                : "-"
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2357483691
                                          ),
                                          model: {
                                            value: _vm.selected,
                                            callback: function ($$v) {
                                              _vm.selected = $$v
                                            },
                                            expression: "selected",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        : _c(
                            "div",
                            [
                              _c(
                                VStepper,
                                {
                                  staticClass: "mt-5",
                                  attrs: { "elevation-0": "" },
                                  model: {
                                    value: _vm.actualStep,
                                    callback: function ($$v) {
                                      _vm.actualStep = $$v
                                    },
                                    expression: "actualStep",
                                  },
                                },
                                [
                                  _c(
                                    VStepperHeader,
                                    { staticClass: "headerstep" },
                                    [
                                      _vm.foja.detail.length != 1
                                        ? [
                                            _c(
                                              VStepperStep,
                                              {
                                                attrs: {
                                                  complete: _vm.actualStep > 1,
                                                  step: 1,
                                                },
                                              },
                                              [_vm._v("Personerias")]
                                            ),
                                            _vm.actualStep > 0
                                              ? _c(VDivider)
                                              : _vm._e(),
                                            _c(
                                              VStepperStep,
                                              {
                                                attrs: {
                                                  complete: _vm.actualStep > 2,
                                                  step: 2,
                                                },
                                              },
                                              [_vm._v("Impresíon de Fojas")]
                                            ),
                                          ]
                                        : [
                                            _c(
                                              VStepperStep,
                                              {
                                                attrs: {
                                                  complete: _vm.actualStep > 1,
                                                  step: 1,
                                                },
                                              },
                                              [_vm._v("Personerias")]
                                            ),
                                            _vm.actualStep != 1
                                              ? _c(VDivider)
                                              : _vm._e(),
                                          ],
                                    ],
                                    2
                                  ),
                                  _c(
                                    VStepperContent,
                                    {
                                      staticClass: "height-modal-stepper",
                                      attrs: { step: 1 },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        {
                                          staticClass: "mt-4",
                                          attrs: { "no-gutters": "" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col-md-4 pl-2 pr-2",
                                            },
                                            [
                                              _c(
                                                VBtn,
                                                {
                                                  staticClass:
                                                    "primary warning w-100",
                                                  attrs: { text: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.autoFill(
                                                        _vm.PersonalityConstants
                                                          .POR_SI
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("1 - Por sí")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col-md-4 pl-2 pr-2",
                                            },
                                            [
                                              _c(
                                                VBtn,
                                                {
                                                  staticClass:
                                                    "primary warning w-100",
                                                  attrs: { text: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.autoFill(
                                                        _vm.PersonalityConstants
                                                          .REGISTRO_AUTOMOTOR
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "2 - Registro automotor"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col-md-4 pl-2 pr-2",
                                            },
                                            [
                                              _c(
                                                VBtn,
                                                {
                                                  staticClass:
                                                    "primary warning w-100",
                                                  attrs: { text: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.autoFill(
                                                        _vm.PersonalityConstants
                                                          .IDIOMA_EXTRANJERO
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "3 - Idioma extranjero"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        VRow,
                                        {
                                          staticClass: "mt-4 mb-4",
                                          attrs: { "no-gutters": "" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col-md-4 pl-2 pr-2",
                                            },
                                            [
                                              _c(
                                                VBtn,
                                                {
                                                  staticClass:
                                                    "primary warning w-100",
                                                  attrs: { text: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.autoFill(
                                                        _vm.PersonalityConstants
                                                          .DIFIERA_FECHA
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("4 - Difiera fecha")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col-md-4 pl-2 pr-2",
                                            },
                                            [
                                              _c(
                                                VBtn,
                                                {
                                                  staticClass:
                                                    "primary warning w-100",
                                                  attrs: { text: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.autoFill(
                                                        _vm.PersonalityConstants
                                                          .DIFIERA_LUGAR
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("5 - Difiera lugar")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col-md-4 pl-2 pr-2",
                                            },
                                            [
                                              _c(
                                                VBtn,
                                                {
                                                  staticClass:
                                                    "primary warning w-100",
                                                  attrs: { text: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.autoFill(
                                                        _vm.PersonalityConstants
                                                          .DIFIERA_FECHA_LUGAR
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "6 - Difiera fecha y lugar"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "text-left pt-3" },
                                        [
                                          _c(VTextarea, {
                                            ref: "description_textarea",
                                            attrs: {
                                              rows: "12",
                                              outlined: "",
                                              "data-vv-as": "record",
                                              name: "record",
                                              label: "Descripción",
                                            },
                                            model: {
                                              value: _vm.personalities_text,
                                              callback: function ($$v) {
                                                _vm.personalities_text = $$v
                                              },
                                              expression: "personalities_text",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.foja.detail.length != 1
                                    ? _c(
                                        VStepperContent,
                                        {
                                          staticClass: "height-modal-stepper",
                                          attrs: { step: 2 },
                                        },
                                        _vm._l(
                                          _vm.foja.detail,
                                          function (detail_foja, index) {
                                            return _c(
                                              VRow,
                                              {
                                                key: detail_foja.foja,
                                                staticClass:
                                                  "d-flex text-center",
                                              },
                                              [
                                                _c(
                                                  VCol,
                                                  [
                                                    _vm._v(
                                                      " Acta Nº" +
                                                        _vm._s(
                                                          detail_foja.acta
                                                        ) +
                                                        " / Libro Nº" +
                                                        _vm._s(
                                                          detail_foja.libro
                                                        ) +
                                                        " / Foja Nº" +
                                                        _vm._s(
                                                          detail_foja.foja
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      VBtn,
                                                      {
                                                        class:
                                                          "primary ml-5 mb-1 " +
                                                          _vm.getPrintBtnClass(
                                                            index
                                                          ),
                                                        attrs: {
                                                          small: "",
                                                          disabled:
                                                            _vm.isCertificacionFirma() &&
                                                            _vm.selected
                                                              .length == 0,
                                                          loading:
                                                            _vm.getLoadingFojas(
                                                              index
                                                            ),
                                                          text: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.printFoja(
                                                              index,
                                                              true,
                                                              false
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("Imprimir")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ]
                  ),
                ]
              ),
              _c(VSpacer),
              _c(
                VCardActions,
                {
                  staticClass: "elevation-5",
                  staticStyle: {
                    position: "absolute",
                    bottom: "0",
                    background: "#f4f4f4",
                    width: "100%",
                  },
                },
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "error", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeModal()
                        },
                      },
                    },
                    [_vm._v("Cerrar")]
                  ),
                  !_vm.step_0
                    ? _c(
                        VBtn,
                        {
                          attrs: { color: "blue", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.prevStep()
                            },
                          },
                        },
                        [_vm._v("Atras")]
                      )
                    : _vm._e(),
                  _c(
                    VBtn,
                    {
                      attrs: {
                        disabled:
                          (_vm.isCertificacionFirma() &&
                            _vm.selected.length == 0) ||
                          (_vm.isLastStep() && _vm.foja.detail.length > 1) ||
                          (_vm.foja.detail != undefined &&
                            _vm.foja.detail.length == 0),
                        loading: _vm.loading_foja,
                        color: "green",
                        text: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.step_0 ? _vm.step0Next() : _vm.nextStep()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isLastStep() || !_vm.isCertificacionFirma()
                              ? "Imprimir foja"
                              : "Siguiente"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Modal", {
        attrs: {
          show_create_button: false,
          button_name: _vm.$t("Crear") + " Personería",
          modal_title: "Crear Personería",
          page_title: "Personerías",
          formBtn: "Crear",
          dialog: _vm.dialog_new_personeria,
          parent: _vm.parent,
          service: _vm.service_personeria,
          item: _vm.personalityItem,
          signatories: _vm.signatories,
          signatories_selected: _vm.getSignatoriesIds(),
        },
        on: {
          updateDialog: function ($event) {
            return _vm.closeModalPersoneria()
          },
          loadList: function ($event) {
            return _vm.getPersonalities()
          },
          updateItem: function (newItem) {
            _vm.personalityItem = newItem
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }