import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VRow,
    [
      _c(
        VCol,
        { attrs: { cols: "12" } },
        [
          _c(
            VMenu,
            {
              ref: "menu1",
              attrs: {
                "close-on-content-click": false,
                transition: "scale-transition",
                "offset-y": "",
                "max-width": "290px",
                "min-width": "auto",
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        VTextField,
                        _vm._g(
                          _vm._b(
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                label: _vm.label,
                                name: "date_esp",
                                "data-vv-as": _vm.label,
                                "persistent-hint": "",
                                "prepend-icon": "mdi-calendar",
                                disabled: _vm.isViewModal,
                              },
                              model: {
                                value: _vm.dateFormatted,
                                callback: function ($$v) {
                                  _vm.dateFormatted = $$v
                                },
                                expression: "dateFormatted",
                              },
                            },
                            "v-text-field",
                            attrs,
                            false
                          ),
                          on
                        )
                      ),
                      _c("span", { staticClass: "red--text" }, [
                        _vm._v(_vm._s(_vm.errors.first("date_esp"))),
                      ]),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.menu1,
                callback: function ($$v) {
                  _vm.menu1 = $$v
                },
                expression: "menu1",
              },
            },
            [
              _c(VDatePicker, {
                attrs: { "no-title": "" },
                on: {
                  change: _vm.blur,
                  input: function ($event) {
                    _vm.menu1 = false
                  },
                },
                model: {
                  value: _vm.fecha,
                  callback: function ($$v) {
                    _vm.fecha = $$v
                  },
                  expression: "fecha",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }