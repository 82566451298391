var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "header",
        { staticClass: "pdf-viewer__header box-shadow elevation-1" },
        [
          _c("div", { staticClass: "pdf-preview-toggle pdf-icons" }, [
            _c(
              "a",
              {
                staticClass: "icon",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.togglePreview.apply(null, arguments)
                  },
                },
              },
              [_c("font-awesome-icon", { attrs: { icon: "scroll" } })],
              1
            ),
          ]),
          _c("div", { staticClass: "pdf-icons" }, [
            _c(
              "a",
              {
                staticClass: "icon",
                attrs: { title: "Guardar" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.savePdf.apply(null, arguments)
                  },
                },
              },
              [_c("font-awesome-icon", { attrs: { icon: "floppy-disk" } })],
              1
            ),
          ]),
          _c("PDFZoom", {
            staticClass: "header-item",
            attrs: { scale: _vm.scale },
            on: {
              change: _vm.updateScale,
              fit: _vm.updateFit,
              exitModal: function ($event) {
                return _vm.$emit("exitModal")
              },
            },
          }),
          _c("PDFPaginator", {
            staticClass: "header-item",
            attrs: { pageCount: _vm.pageCount },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
          _vm._t("header"),
        ],
        2
      ),
      _c("PDFData", {
        staticClass: "pdf-viewer__main",
        attrs: { url: _vm.url },
        on: {
          "page-count": _vm.updatePageCount,
          "page-focus": _vm.updateCurrentPage,
          "document-rendered": _vm.onDocumentRendered,
          "document-errored": _vm.onDocumentErrored,
        },
        scopedSlots: _vm._u([
          {
            key: "preview",
            fn: function (ref) {
              var pages = ref.pages
              return [
                _c(
                  "PDFPreview",
                  _vm._b(
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPreviewEnabled,
                          expression: "isPreviewEnabled",
                        },
                      ],
                      staticClass: "pdf-viewer__preview",
                    },
                    "PDFPreview",
                    {
                      pages: pages,
                      scale: _vm.scale,
                      currentPage: _vm.currentPage,
                      pageCount: _vm.pageCount,
                      isPreviewEnabled: _vm.isPreviewEnabled,
                    },
                    false
                  )
                ),
              ]
            },
          },
          {
            key: "document",
            fn: function (ref) {
              var pages = ref.pages
              return [
                _c(
                  "PDFDocument",
                  _vm._b(
                    {
                      staticClass: "pdf-viewer__document",
                      class: { "preview-enabled": _vm.isPreviewEnabled },
                      on: {
                        "scale-change": _vm.updateScale,
                        imageDropped: _vm.imageDropped,
                        finishLoading: _vm.finishLoading,
                      },
                    },
                    "PDFDocument",
                    {
                      pages: pages,
                      scale: _vm.scale,
                      optimalScale: _vm.optimalScale,
                      fit: _vm.fit,
                      currentPage: _vm.currentPage,
                      pageCount: _vm.pageCount,
                      isPreviewEnabled: _vm.isPreviewEnabled,
                      b64img: _vm.b64img,
                    },
                    false
                  )
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }