import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VDialog,
        {
          attrs: { "max-width": "900" },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.close()
            },
            "click:outside": function ($event) {
              return _vm.close()
            },
          },
          model: {
            value: _vm.open,
            callback: function ($$v) {
              _vm.open = $$v
            },
            expression: "open",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _c(VCardText, [
                _c("p", { staticClass: "text-h6 mb-5" }, [
                  _vm._v(
                    "Complete el formulario para crear una factura con los remitos seleccionados"
                  ),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { attrs: { md: "6" } },
                            [
                              _c(VCombobox, {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  "data-vv-as": "emitter",
                                  name: "emitter",
                                  "item-text": "name",
                                  "item-value": "id",
                                  items: _vm.emitters,
                                  label: "Emisor*",
                                  autocomplete: "off",
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.emitterChangeCombo($event)
                                    _vm.getCbtesTipos()
                                  },
                                },
                                model: {
                                  value: _vm.invoice.emitter,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.invoice, "emitter", $$v)
                                  },
                                  expression: "invoice.emitter",
                                },
                              }),
                              _c("span", { staticClass: "red--text" }, [
                                _vm._v(_vm._s(_vm.errors.first("emitter"))),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { md: "6" } },
                            [
                              _c(VCombobox, {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  "data-vv-as": "receiver",
                                  name: "receiver",
                                  "item-text": "name",
                                  "item-value": "id",
                                  items: _vm.receivers,
                                  label: "Receptor*",
                                  autocomplete: "off",
                                },
                                model: {
                                  value: _vm.invoice.receiver,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.invoice, "receiver", $$v)
                                  },
                                  expression: "invoice.receiver",
                                },
                              }),
                              _c("span", { staticClass: "red--text" }, [
                                _vm._v(_vm._s(_vm.errors.first("receiver"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { attrs: { md: "6" } },
                            [
                              _c(VCombobox, {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "overflow-auto",
                                attrs: {
                                  "data-vv-as": "cbte_tipo",
                                  name: "cbte_tipo",
                                  "item-text": "Desc",
                                  "item-value": "Id",
                                  items: _vm.cbte_tipos,
                                  label: "Tipo de comprobante*",
                                  autocomplete: "off",
                                  filled: "",
                                },
                                model: {
                                  value: _vm.invoice.cbte_tipo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.invoice, "cbte_tipo", $$v)
                                  },
                                  expression: "invoice.cbte_tipo",
                                },
                              }),
                              _c("span", { staticClass: "red--text" }, [
                                _vm._v(_vm._s(_vm.errors.first("cbte_tipo"))),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { md: "3" } },
                            [
                              _c(VCombobox, {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  "data-vv-as": "pto_vta",
                                  name: "pto_vta",
                                  items: _vm.ptosvtas,
                                  label: "Punto de venta*",
                                  autocomplete: "off",
                                },
                                model: {
                                  value: _vm.invoice.pto_vta,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.invoice, "pto_vta", $$v)
                                  },
                                  expression: "invoice.pto_vta",
                                },
                              }),
                              _c("span", { staticClass: "red--text" }, [
                                _vm._v(_vm._s(_vm.errors.first("pto_vta"))),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { md: "3" } },
                            [
                              _c("DatePickerEsp", {
                                attrs: {
                                  initToday: true,
                                  label: "Fecha",
                                  date: _vm.invoice.cbte_fch,
                                  isRequired: true,
                                  name: "cbte_fch",
                                },
                                on: {
                                  setDate: function (date) {
                                    _vm.invoice.cbte_fch = date
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { attrs: { md: "4" } },
                            [
                              _c("DatePickerEsp", {
                                attrs: {
                                  initToday: true,
                                  label:
                                    "Periodo desde (Min: -10 días de la fecha de emisión)",
                                  date: _vm.invoice.cbte_fch_serv_desde,
                                  isRequired: true,
                                  name: "cbte_fch_serv_desde",
                                },
                                on: {
                                  setDate: function (date) {
                                    _vm.invoice.cbte_fch_serv_desde = date
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { md: "4" } },
                            [
                              _c("DatePickerEsp", {
                                attrs: {
                                  initToday: true,
                                  label:
                                    "Periodo hasta (Max: +10 días de la fecha de emisión)",
                                  date: _vm.invoice.cbte_fch_serv_hasta,
                                  isRequired: true,
                                  name: "cbte_fch_serv_hasta",
                                },
                                on: {
                                  setDate: function (date) {
                                    _vm.invoice.cbte_fch_serv_hasta = date
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { md: "4" } },
                            [
                              _c("DatePickerEsp", {
                                attrs: {
                                  initToday: true,
                                  label: "Fecha Vto. Pago",
                                  date: _vm.invoice.fch_vto_pago,
                                  isRequired: true,
                                  name: "fch_vto_pago",
                                },
                                on: {
                                  setDate: function (date) {
                                    _vm.invoice.fch_vto_pago = date
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { attrs: { md: "4" } },
                            [
                              _c(VCombobox, {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  "data-vv-as": "concepto",
                                  name: "concepto",
                                  "item-text": "Desc",
                                  "item-value": "Id",
                                  items: _vm.conceptos,
                                  label: "Concepto*",
                                  autocomplete: "off",
                                },
                                model: {
                                  value: _vm.invoice.concepto,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.invoice, "concepto", $$v)
                                  },
                                  expression: "invoice.concepto",
                                },
                              }),
                              _c("span", { staticClass: "red--text" }, [
                                _vm._v(_vm._s(_vm.errors.first("concepto"))),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { md: "4" } },
                            [
                              _c(VCombobox, {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  "data-vv-as": "moneda",
                                  name: "moneda",
                                  "item-text": "Desc",
                                  "item-value": "Id",
                                  items: _vm.monedas,
                                  label: "Moneda*",
                                  autocomplete: "off",
                                },
                                model: {
                                  value: _vm.invoice.moneda,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.invoice, "moneda", $$v)
                                  },
                                  expression: "invoice.moneda",
                                },
                              }),
                              _c("span", { staticClass: "red--text" }, [
                                _vm._v(_vm._s(_vm.errors.first("moneda"))),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { md: "4" } },
                            [
                              _vm.invoice.moneda != null &&
                              _vm.invoice.moneda.Id !== "PES"
                                ? _c(VTextField, {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "decimal|required",
                                        expression: "'decimal|required'",
                                      },
                                    ],
                                    attrs: {
                                      autocomplete: "off",
                                      "data-vv-as": "mon_cotiz",
                                      label: "Cotización de la moneda*",
                                      name: "mon_cotiz",
                                    },
                                    model: {
                                      value: _vm.invoice.mon_cotiz,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.invoice, "mon_cotiz", $$v)
                                      },
                                      expression: "invoice.mon_cotiz",
                                    },
                                  })
                                : _vm._e(),
                              _c("span", { staticClass: "red--text" }, [
                                _vm._v(_vm._s(_vm.errors.first("mon_cotiz"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { attrs: { md: "4" } },
                            [
                              _c(VCombobox, {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  "data-vv-as": "condition_vta",
                                  name: "condition_vta",
                                  items: _vm.conditions,
                                  label: "Condicion*",
                                  autocomplete: "off",
                                },
                                model: {
                                  value: _vm.invoice.condition_vta,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.invoice, "condition_vta", $$v)
                                  },
                                  expression: "invoice.condition_vta",
                                },
                              }),
                              _c("span", { staticClass: "red--text" }, [
                                _vm._v(
                                  _vm._s(_vm.errors.first("condition_vta"))
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { attrs: { md: "12" } },
                            [
                              _c(VDataTable, {
                                staticClass: "pb-4",
                                staticStyle: { border: "solid 1px #1c1c1c" },
                                attrs: {
                                  headers: _vm.header_remitos,
                                  items: this.invoice.remitos,
                                  "hide-default-footer": true,
                                  "items-per-page": 100,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function (props) {
                                      return [
                                        _c("tr", [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                props.item.id
                                                  .toString()
                                                  .padStart(7, "0")
                                              )
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                props.item.work_order.id
                                                  .toString()
                                                  .padStart(7, "0")
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatUtils.formatPrice(
                                                    props.item.grouped_items
                                                      .no_taxed_total
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatUtils.formatPrice(
                                                    props.item.grouped_items
                                                      .taxed_total
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatUtils.formatPrice(
                                                    props.item.grouped_items
                                                      .taxed_total * 0.21
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.formatUtils.formatPrice(
                                                      props.item.grouped_items
                                                        .no_taxed_total +
                                                        props.item.grouped_items
                                                          .taxed_total +
                                                        props.item.grouped_items
                                                          .taxed_total *
                                                          0.21
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                VTooltip,
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              VIcon,
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "red",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeDetail(
                                                                          props.item,
                                                                          "remitos"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " indeterminate_check_box "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Remover"),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { attrs: { md: "12" } },
                            [
                              _c("b", [_vm._v("Detalles personalizados")]),
                              _c(
                                VBtn,
                                {
                                  staticClass: "ml-5 text-white",
                                  attrs: { small: "", color: "blue-grey" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addCustomDetail()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    VIcon,
                                    { attrs: { big: "", color: "green" } },
                                    [_vm._v(" add ")]
                                  ),
                                  _c("span", [_vm._v("Agregar")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { md: "12" } },
                            [
                              _c(VDataTable, {
                                staticClass: "pb-4",
                                staticStyle: { border: "solid 1px #1c1c1c" },
                                attrs: {
                                  headers: _vm.header_custome,
                                  items: this.invoice.custom_items,
                                  "hide-default-footer": true,
                                  "items-per-page": 100,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function (props) {
                                      return [
                                        _c("tr", [
                                          _c(
                                            "td",
                                            [
                                              _c(VTextField, {
                                                staticClass: "mt-3",
                                                attrs: {
                                                  autocomplete: "off",
                                                  name: "desc",
                                                  placeholder: "Descripción*",
                                                  dense: "",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: props.item.desc,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      props.item,
                                                      "desc",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "props.item.desc",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "red--text" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "description"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(VTextField, {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "decimal",
                                                    expression: "'decimal'",
                                                  },
                                                ],
                                                staticClass: "mt-3",
                                                attrs: {
                                                  autocomplete: "off",
                                                  name: "quantity",
                                                  placeholder: "Cantidad*",
                                                  dense: "",
                                                  type: "number",
                                                },
                                                model: {
                                                  value: props.item.quantity,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      props.item,
                                                      "quantity",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "props.item.quantity",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "red--text" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "quantity"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(VTextField, {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "decimal",
                                                    expression: "'decimal'",
                                                  },
                                                ],
                                                staticClass: "mt-3",
                                                attrs: {
                                                  autocomplete: "off",
                                                  name: "price",
                                                  placeholder: "Precio*",
                                                  dense: "",
                                                  type: "number",
                                                },
                                                model: {
                                                  value: props.item.price,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      props.item,
                                                      "price",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "props.item.price",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "red--text" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errors.first("price")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(VCombobox, {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "required",
                                                    expression: "'required'",
                                                  },
                                                ],
                                                attrs: {
                                                  "data-vv-as": "iva",
                                                  name: "iva",
                                                  "item-text": "Desc",
                                                  "item-value": "Id",
                                                  items: _vm.ivas,
                                                  label: "IVA*",
                                                  autocomplete: "off",
                                                },
                                                model: {
                                                  value: props.item.iva,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      props.item,
                                                      "iva",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "props.item.iva",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "red--text" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errors.first("iva")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.showTotalCustomeItem(
                                                    props.item
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                VTooltip,
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              VIcon,
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "red",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeDetail(
                                                                          props.item,
                                                                          "custom"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " indeterminate_check_box "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Remover"),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VRow,
                        [
                          _c(VCol, { attrs: { md: "4" } }, [
                            _vm._v(
                              " Total IVA: " +
                                _vm._s(
                                  _vm.formatUtils.formatPrice(_vm.imp_iva)
                                ) +
                                " "
                            ),
                          ]),
                          _c(VCol, { attrs: { md: "4" } }, [
                            _vm._v(
                              " Total Gravado: " +
                                _vm._s(
                                  _vm.formatUtils.formatPrice(_vm.imp_neto)
                                ) +
                                " "
                            ),
                          ]),
                          _c(VCol, { attrs: { md: "4" } }, [
                            _vm._v(
                              " Total No Gravado: " +
                                _vm._s(
                                  _vm.formatUtils.formatPrice(_vm.imp_tot_conc)
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        VRow,
                        [
                          _c(VCol, { attrs: { md: "12" } }, [
                            _c("b", [
                              _vm._v(
                                "Total: " +
                                  _vm._s(
                                    _vm.formatUtils.formatPrice(_vm.imp_total)
                                  )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v(" Cancelar ")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: {
                        color: "green darken-1",
                        text: "",
                        loading: _vm.loading,
                      },
                      on: { click: _vm.generateInvoice },
                    },
                    [_vm._v(" Generar ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }