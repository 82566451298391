var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ScrollingDocument",
    _vm._b(
      {
        staticClass: "pdf-document",
        attrs: { "enable-page-jump": true },
        on: {
          "page-jump": _vm.onPageJump,
          "pages-fetch": _vm.onPagesFetch,
          "pages-reset": _vm.fitWidth,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var page = ref.page
              var isPageFocused = ref.isPageFocused
              var isElementFocused = ref.isElementFocused
              return [
                _c(
                  "PDFPage",
                  _vm._b(
                    {
                      attrs: {
                        drawImage: page == 1,
                        scrollValue: _vm.scrollValue(),
                      },
                      on: {
                        "page-rendered": _vm.onPageRendered,
                        "page-errored": _vm.onPageErrored,
                        "page-focus": _vm.onPageFocused,
                        imageDropped: function ($event) {
                          return _vm.$emit("imageDropped", true)
                        },
                        finishLoading: function ($event) {
                          return _vm.$emit("finishLoading")
                        },
                      },
                    },
                    "PDFPage",
                    {
                      scale: _vm.scale,
                      optimalScale: _vm.optimalScale,
                      page: page,
                      isPageFocused: isPageFocused,
                      isElementFocused: isElementFocused,
                      b64img: _vm.b64img,
                    },
                    false
                  )
                ),
              ]
            },
          },
        ]),
      },
      "ScrollingDocument",
      {
        pages: _vm.pages,
        pageCount: _vm.pageCount,
        currentPage: _vm.currentPage,
      },
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }