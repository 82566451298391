<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="$emit('closeDialog')"
      @keydown.esc="$emit('closeDialog')"
      v-on:input="$emit('updateDialogAddSignaroty', false)"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>
        <v-card-title class="headline">Agregar firmante</v-card-title>

        <v-card-text>
          <label class="fs-16 mt-3">Seleccione los firmates que desea agregar a la tarea</label>
          <v-combobox
            v-model="selected_signatories"
            data-vv-as="firmante"
            name="firmante"
            item-text="name"
            item-value="id"
            :items="signatories"
            label="Firmante"
            autocomplete="off"
            v-validate="'required'"
            multiple
          ></v-combobox>
          <span class="red--text">{{ errors.first("firmante") }}</span>

          <div class="text-center">
            <hr />
            <label class="subtext">Si el firmante buscado no se encuentra en el listado puede darlo de alta</label>
            <div class="mt-3 text-center">
              <ModalCreateSignature
                v-if="hasCreateAccessSignatory()"
                :fixed_client="true"
                :center="true"
                :button_name="$t('Crear Firmante')"
                modal_title="Nuevo Firmante"
                page_title="Firmantes"
                formBtn="Crear"
                :dialog="dialog_signaroty"
                :service="service"
                @updateDialog="
                  (value) => {
                    dialog_signaroty = value;
                  }
                "
                @loadList="loadComboData()"
                :item="mainItem"
                @updateItem="
                  (newItem) => {
                    mainItem = newItem;
                  }
                "
              />
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('closeDialog')">Cancelar</v-btn>
            <v-btn color="success" text @click="acceptModal">Agregar</v-btn>
          </v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ConstantSection from "@/constants/sections";
import { accessUtils } from "@/libs/utils/accessUtils";
import ModalCreateSignature from "@/views/Signatories/Modal.vue";
import { generalService } from "@/libs/ws/generalService";
export default {
  components: { ModalCreateSignature },
  props: {
    dialog: Boolean,
    item: Object,
    client: Object,
  },
  data: () => ({
    signatories: [],
    selected_signatories: [],
    service: generalService,
    defaultItem: null,
    dialog_signaroty: false,
    mainItem: {
      id: "",
      name: "",
      dni: "",
      client: {
        id: "",
        business_name: "",
      },
    },
  }),
  mounted() {
    this.service.setSection(ConstantSection.SECTION_SIGNATORIES);
    this.loadComboData();
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
  },
  watch: {
    client: function (newVal, oldVal) {
      if (this.client != null && this.client.id > 0) {
        this.loadComboData();
        this.mainItem.client = this.client;
      }
    },
    dialog: function (newVal, oldVal) {
      if (this.dialog) {
        this.loadComboData();
        this.$validator.reset();
      }
    },
  },
  methods: {
    hasCreateAccessSignatory() {
      return accessUtils.getAccessOf(ConstantSection.SECTION_SIGNATORIES).create;
    },
    loadComboData() {
      if (this.client != null && this.client.id > 0) {
        const currentComponent = this;
        generalService.filter({ client: this.client.id }).then((response) => {
          currentComponent.signatories = response.result;
          currentComponent.selected_signatories = [];

          if (currentComponent.signatories != undefined && currentComponent.signatories.length > 0) {
            currentComponent.signatories.forEach((sign) => {
              const result = currentComponent.item.signatories.filter((sign_by_task) => sign.id == sign_by_task.id);
              if (result.length > 0) currentComponent.selected_signatories.push(sign);
            });
          }
        });
      }
    },
    acceptModal() {
      this.item.signatories = this.selected_signatories;
      this.$emit("closeDialog");
    },
  },
};
</script>
