<template>
  <div>
    <Title :pages="[{ icon: 'file-invoice', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtnName="formBtn"
        :dialog_modal="dialog"
        :service="service"
        @updateDialogParent="updateDialog"
        @loadList="loadList(service)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />      

      <ModalFiles
        :dialog="dialog_files"
        :task_name="taskNameSelected"
        :documents="documentsSelected"
        :criteria_price="criteriaPriceSelected"
        @closeDialog="closeDialogFiles"
        :asigned_task="asignedTaskSelected"
        :work_order="workOrderSelected"
      />

      <v-card>
        <div class="row mb-5 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <v-row no-gutters>
                <v-col cols="12" md="1" sm="12">
                  <span class="cursor text-filter" style="font-size: 20px" @click="expandFilter()">
                    Filtros
                    <font-awesome-icon icon="filter" />
                  </span>
                </v-col>
                <v-col cols="12" md="10" sm="12" class="text--secondary cursor" @click="expandFilter()">
                  <p class="mb-0 mt-1" v-if="open_filter">Seleccione los filtros deseados</p>
                  <span v-else>
                    <v-chip outlined color="#666" class="ml-1 mb-1" v-if="nro_orden_selected == null"> Desde: {{ desde_selected }}</v-chip>
                    <v-chip outlined color="#666" class="ml-1 mb-1" v-if="nro_orden_selected == null"> Hasta: {{ hasta_selected }}</v-chip>
                    <v-chip outlined color="#666" class="ml-1 mb-1" v-if="nro_orden_selected != null"> Nº orden: {{ nro_orden_selected }}</v-chip>
                    <v-chip outlined color="#666" class="ml-1 mb-1" v-if="state_selected != null"> Estado: {{ state_selected.name }}</v-chip>
                    <v-chip outlined color="#666" class="ml-1 mb-1" v-if="employee_selected != null"> Ejecutante: {{ employee_selected.name }}</v-chip>
                    <v-chip outlined color="#666" class="ml-1 mb-1" v-if="client_selected != null"> Cliente: {{ client_selected.business_name }}</v-chip>
                    <v-chip outlined color="#666" class="ml-1 mb-1" v-if="applicant_selected != null"> Solicitante: {{ applicant_selected.name }}</v-chip>
                    <v-chip outlined color="#666" class="ml-1 mb-1" v-if="task_selected != null"> Tarea: {{ task_selected.name }}</v-chip>
                    <v-chip outlined color="#666" class="ml-1 mb-1" v-if="acta_selected != null"> Acta: {{ acta_selected }}</v-chip>
                    <v-chip outlined color="#666" class="ml-1 mb-1" v-if="libro_selected != null"> Libro: {{ libro_selected }}</v-chip>
                    <v-chip outlined color="#666" class="ml-1 mb-1" v-if="foja_selected != null"> Foja: {{ foja_selected }}</v-chip>
                  </span>
                </v-col>
                <v-col cols="12" md="1" sm="12" class="dropdown-filter">
                  <font-awesome-icon class="cursor" icon="chevron-down" v-if="!open_filter" @click="open_filter = true" />
                  <font-awesome-icon v-else class="cursor" icon="chevron-up" @click="open_filter = false" />
                </v-col>
              </v-row>
              <hr v-if="open_filter" class="mt-4" />
              <div class="row pt-5" v-if="open_filter">
                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="desde_selected"
                    v-model="desde_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="desde_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="desde_selected" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="desde_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="hasta_selected"
                    v-model="hasta_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="hasta_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="hasta_selected" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-text-field v-model="nro_orden_selected" autocomplete="off" class="pt-0 mt-0" label="Nº Orden" clearable></v-text-field>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="state_selected"
                    autocomplete="off"
                    data-vv-as="state_selected"
                    name="state_selected"
                    item-text="name"
                    item-value="id"
                    :items="states"
                    label="Estado"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>                

                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="client_selected"
                    autocomplete="off"
                    data-vv-as="client_selected"
                    name="client_selected"
                    item-text="business_name"
                    item-value="id"
                    :items="clients"
                    label="Cliente"
                    class="pt-0 mt-0"
                    v-on:change="clientChangeCombo($event)"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="applicant_selected"
                    autocomplete="off"
                    data-vv-as="applicant_selected"
                    name="applicant_selected"
                    item-text="name"
                    item-value="id"
                    :items="applicants"
                    label="Solicitante"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="task_selected"
                    autocomplete="off"
                    data-vv-as="tarea"
                    name="tarea"
                    item-text="name"
                    item-value="id"
                    :items="tasks"
                    label="Tarea"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-text-field v-model="acta_selected" autocomplete="off" class="pt-0 mt-0" label="Acta" clearable></v-text-field>
                </div>

                <div class="col-md-3 pt-0">
                  <v-text-field v-model="libro_selected" autocomplete="off" class="pt-0 mt-0" label="Libro" clearable></v-text-field>
                </div>

                <div class="col-md-3 pt-0">
                  <v-text-field v-model="foja_selected" autocomplete="off" class="pt-0 mt-0" label="Foja" clearable></v-text-field>
                </div>

                <div class="col-md-3 pt-0 text-right">
                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>          
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>       

        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
          item-key="id"
          :single-expand="false"
          :expanded.sync="expanded"
          show-expand
          v-model="selected"
          show-select
          :single-select="false"
        >
          <template v-slot:item="props">
            <tr>
              <td>
                <font-awesome-icon v-if="!props.isExpanded" icon="chevron-down" @click="props.expand(!props.isExpanded)" class="cursor" />
                <font-awesome-icon v-else icon="chevron-up" @click="props.expand(!props.isExpanded)" class="cursor" />
              </td>
              <td>
                <v-checkbox :input-value="props.isSelected" style="margin: 0px; padding: 0px" hide-details @change="props.select($event)" :disabled="props.item.state.id == remitosWS.STATE_BILLED" />
              </td>
              <td>{{ props.item.id.toString().padStart(7, "0") }}</td>                          
              <td>{{ props.item.applicant_date }}</td>
              <td>{{ props.item.client.business_name }}</td>              
              <td>{{ props.item.asignated_tasks.length }}</td>
              <td>
                <span :class="renderExpirationDate(props.item.expiration_date)">{{ props.item.expiration_date != null ? props.item.expiration_date : "-" }}</span>
              </td>
              
              <td style="min-width: 135px" v-html="service.getStateHtml(props.item.state)"></td>

              <td style="min-width: 135px" class="text-center px-0">
                <v-tooltip bottom v-if="props.item.state.id == 0"><!-- PENDING -->
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue" class="mr-2" @click="editItem(props.item)"> edit </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="viewItem(props.item)"> remove_red_eye </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>    

                <v-tooltip bottom v-if="props.item.state.id == 0"><!-- PENDING -->
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2" @click="deleteItem(props.item)"> delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>           

                <v-tooltip bottom v-if="props.item.state_accounting.id > 0">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="orange" class="mr-2" @click="printCocepts(props.item)"> print </v-icon>
                  </template>
                  <span>Imprimir Remito</span>
                </v-tooltip>                
              </td>
            </tr>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-data-table
                :headers="headersTasks"
                :items="item.asignated_tasks"
                :search="search"
                :loading-text="'Cargando'"
                hide-default-footer
                disable-pagination
                class="background-color: grey lighten-3"
              >
                <template v-slot:item="props2">
                  <tr>
                    <td>{{ props2.index + 1 }}</td>
                    <td>{{ props2.item.task.name }}</td>
                    <td>{{ props2.item.details.length }}</td>

                    <td :key="props2.item.documents.length">
                      {{ props2.item.documents.length }}
                    </td>
                    <td>{{ props2.item.signatories.length }}</td>
                    <td class="text-center">{{ props2.item.priority_name }}</td>
                    <td class="text-center">{{ props2.item.state.name }}</td>
                    <td class="text-center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small color="blue" class="mr-2" @click="viewFiles(props2.item, item)"> mdi-file </v-icon>
                        </template>
                        <span>Ver documentos</span>
                      </v-tooltip>                      
                    </td>
                  </tr>
                </template>
                <template>
                  <thead>
                    <tr>
                      <th colspan="1">Numero</th>
                      <th colspan="1">Tarea</th>
                      <th colspan="4" class="text-center">Informacion adicional</th>
                      <th colspan="1" class="text-center">Prioridad</th>
                      <th colspan="1" class="text-center">Estado</th>
                      <th colspan="1" class="text-center">Accion</th>
                    </tr>
                  </thead>
                </template>
              </v-data-table>
            </td>
          </template>

          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      
      <DialogLoading :dialog="dialog_loading" text="Cargando..." />  
      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />    
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { orderService } from "@/libs/ws/orderService";
import { remitoService } from "@/libs/ws/remitoService";
import { generalService } from "@/libs/ws/generalService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";

import DialogDelete from "@/components/DialogDelete.vue";
import DialogLoading from "@/components/DialogLoading.vue";
import Modal from "./Modal.vue";
import ModalFiles from "./ModalFiles.vue";
import RolMixin from "@/mixins/RolMixin.vue";

export default {
  components: { Title, Modal, DialogLoading,  ModalFiles, DialogDelete },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      parent: this,
      expanded: [],
      service: orderService,
      remitosWS: remitoService,
      page_title: "Certificaciones",
      modal_title: "Certificación",
      defaultItem: null,
      headers: [
        { text: "Nº Orden", value: "id" },
        { text: "Fecha de solicitud", value: "applicant_date" },        
        { text: "Cliente", value: "client.business_name" },        
        { text: "Tareas", value: "asignated_tasks" },
        { text: "Fecha de expiración", value: "expiration_date" },        
        { text: "Estado", value: "state.name" },
        { text: "Accion", value: "", align: "center" },
      ],
      headersTasks: [
        { text: "", value: "number", sortable: false },
        { text: "", value: "task", sortable: false },
        { text: "Cant. Actas/Libros/Fojas", value: "", sortable: false, class: "bg-grey" },
        { text: "Cant. de Documentos", value: "cant_documentos", sortable: false, class: "bg-grey" },
        { text: "Cant. de Firmas", value: "cant_firmas", sortable: false, class: "bg-grey" },
        { text: "", value: "priority", sortable: false },
        { text: "", value: "state" },
        { text: "", value: "", sortable: false },
      ],
      list: [],
      selected: [],
      dialog_loading: false,      
      dialog_delete: false,
      desde_selected_open: false,
      hasta_selected_open: false,
      nro_orden_selected: null,
      client_selected: null,
      task_selected: null,
      state_selected: null,
      applicant_selected: null,
      employee_selected: null,
      acta_selected: null,
      libro_selected: null,
      foja_selected: null,
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      dialog_task_state: false,
      dialog_files: false,
      asignedTaskSelected: null,
      workOrderSelected: null,
      documentsSelected: [],
      signatoriesSelected: [],
      criteriaPriceSelected: null,
      taskNameSelected: "",
      mainItem: {
        id: "",
        applicant_date: null,
        asignation_date: null,
        expiration_date: null,
        employee: null,
        client: null,
        applicant: null,
        asignated_tasks: [],
        observaciones_internas: "",
        observaciones_cliente: "",
      },
      clients: [],
      tasks: [],
      applicants: [],      
      states: [],
      order_id_for_remito: null,
      open_generate_remito: false,
      open_generate_remito_massive: false,
    };
  },
  mounted() {
    this.loadComboData();
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.loadList(this.service, this.getFilterParams());
  },
  methods: {
    getFilterParams() {
      const param = {
        from: this.desde_selected,
        to: this.hasta_selected,
        client: this.client_selected,
        applicant: this.applicant_selected,
        employee: this.employee_selected,
        state: this.state_selected,
        nro_orden: this.nro_orden_selected,
        tasks: this.task_selected,
        acta: this.acta_selected,
        libro: this.libro_selected,
        foja: this.foja_selected,
      };
      return param;
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    loadComboData() {
      generalService.filter({}, Constant.SECTION_CLIENTS).then((response) => {
        this.clients = response.result;
      });
      this.service.get_states().then((response) => {
        this.states = response.result;
      });
      generalService.filter({}, Constant.SECTION_TASKS).then((response) => {
        this.tasks = response.result;
      });

      if (this.client_selected != null && this.client_selected.id > 0) this.clientChangeCombo();
    },
    clientChangeCombo(event) {
      var param = { client: this.client_selected.id };
      generalService.filter(param, Constant.SECTION_APPLICANTS).then((response) => {
        console.log(this.applicants, "response");
        this.applicants = response.result;
      });
    },
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadList(this.service);
    },
    changeTaskState(assignedTask) {
      this.asignedTaskSelected = assignedTask;
      this.dialog_task_state = true;
    },
    closeDialogTaskState() {
      this.asignedTaskSelected = null;
      this.dialog_task_state = false;
    },
    closeDialogFiles() {
      this.documentsSelected = [];
      this.signatoriesSelected = [];
      this.asignedTaskSelected = null;
      this.workOrderSelected = null;
      this.criteriaPriceSelected = null;
      this.dialog_files = false;
    },
    viewFiles(assignedTask, work_order) {
      this.documentsSelected = assignedTask.documents;
      this.signatoriesSelected = assignedTask.signatories;
      this.asignedTaskSelected = assignedTask;
      this.workOrderSelected = work_order;
      this.criteriaPriceSelected = assignedTask.task.criteria_price;
      this.taskNameSelected = assignedTask.task.name;
      this.dialog_files = true;
    },
    renderExpirationDate(expiration_date) {
      const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString().slice(0, 10);
      if (today > expiration_date) return "text-danger";
      return "";
    },
    openHistory(assigned_task_id, task_name) {
      this.$refs.historyStatesModal.open(assigned_task_id, task_name);
    },
    openGenerateRemito(item) {
      this.order_id_for_remito = item.id;
      this.open_generate_remito = true;
    },
    closeGenerateRemito() {
      this.order_id_for_remito = null;
      this.open_generate_remito = false;
    },    
    printCocepts(item) {
      if (this.dialog_loading) return;
      this.dialog_loading = true;

      this.remitosWS
        .printOrder(item.id)
        .then((response) => {
          if (!generalService.downloadPdf(response, "remito_orden_" + item.id + ".pdf")) {
            this.$toast.error(response.msg, "Error");
          }
        })
        .catch((e) => {
          console.log("service.print error");
          console.log(e);
        })
        .finally(() => {
          this.dialog_loading = false;
        });
    },
    generateRemito(checkbox_generate_remito) {
      if (this.order_id_for_remito > 0) {
        this.loading = true;
        return remitoService
          .create({ work_order: this.order_id_for_remito, end_order: checkbox_generate_remito })
          .then((response) => {
            this.loading = false;
            if (response.status == true) {
              this.$toast.success("El remito se creo correctamente.", "OK");
              //this.$emit("updateDialog", false);
              this.loadList(this.service);
              this.loading = false;
              this.open_generate_remito = false;
            } else {
              if (Object.prototype.hasOwnProperty.call(response, "message")) {
                this.$toast.error(response.message, "Error");
              } else {
                this.$toast.error(response.msg, "Error");
              }
            }
          })
          .catch((response) => {
            this.$toast.error(response.msg, "Error");
            this.loading = false;
          });
      }
    },    
  },
  deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadList(this.service);
    },
};

// TODO agregar un boton de limpiar busqueda.
</script>
