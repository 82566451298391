import { render, staticRenderFns } from "./PDFPaginator.vue?vue&type=template&id=046aa8a8&"
import script from "./PDFPaginator.vue?vue&type=script&lang=js&"
export * from "./PDFPaginator.vue?vue&type=script&lang=js&"
import style0 from "./PDFPaginator.vue?vue&type=style&index=0&id=046aa8a8&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\WWW\\escibania\\Frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('046aa8a8')) {
      api.createRecord('046aa8a8', component.options)
    } else {
      api.reload('046aa8a8', component.options)
    }
    module.hot.accept("./PDFPaginator.vue?vue&type=template&id=046aa8a8&", function () {
      api.rerender('046aa8a8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/PdfEditor/components/PDFPaginator.vue"
export default component.exports