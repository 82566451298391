import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-12" }, [
    _c("div", { staticClass: "col-md-12 pt-0" }, [
      _c("div", { staticClass: "row box-chofer enCurso" }, [
        _c(
          "div",
          { staticClass: "col-md-1 ptop-5" },
          [
            _vm.loading
              ? _c(VSkeletonLoader, {
                  attrs: { type: "list-item", height: "60" },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-2 ptop-5" },
          [
            _vm.loading
              ? _c(VSkeletonLoader, {
                  attrs: { type: "list-item-two-line", height: "60" },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-2 ptop-5" },
          [
            _vm.loading
              ? _c(VSkeletonLoader, {
                  attrs: { type: "list-item-two-line", height: "60" },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-2 ptop-5" },
          [
            _vm.loading
              ? _c(VSkeletonLoader, {
                  attrs: { type: "list-item", height: "60" },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-1 ptop-5" },
          [
            _vm.loading
              ? _c(VSkeletonLoader, {
                  attrs: { type: "list-item-two-line", height: "60" },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-2 ptop-5" },
          [
            _vm.loading
              ? _c(VSkeletonLoader, {
                  attrs: { type: "list-item-two-line", height: "60" },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-2 ptop-5" },
          [
            _vm.loading
              ? _c(VSkeletonLoader, {
                  attrs: { type: "list-item-two-line", height: "60" },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }