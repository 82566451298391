import { wsService } from "./wsService";
import sections from "@/constants/sections";

const section = sections.SECTION_VOUCHERS;

const IVA_ID_0 = 3;
const IVA_ID_21 = 5;

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function getVoucherAsoc(emitter, voucher_number, pto_vta, cbte_tipo) {
  return wsService.make_post_request(section + "/get_voucher_asoc", {emitter: emitter, voucher_number: voucher_number, pto_vta: pto_vta, cbte_tipo: cbte_tipo});
}

function create(params) {
  return wsService.make_post_request(section + "/create", params);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function print(voucher_id) {
  return wsService.make_post_request(
    section + "/print/" + voucher_id,
    {},
    {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
      },
    }
  );  
}

function sendToAFIP(voucher_id) {
  return wsService.make_post_request(section + "/send_to_afip/" + voucher_id, {});
}

function export_filter(params) {
  return wsService.make_post_request(section + "/export", params, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/vnd.ms-excel",
    },
  });
}

function export_libro_iva_filter(params) {
  return wsService.make_post_request(section + "/export_libro_iva", params, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/vnd.ms-excel",
    },
  });
}

function getCancelIdForCbteTipo(cbte_tipo) {
  switch(cbte_tipo){
    case 1: return 3;//FACTURA_A -> NOTA_CREDITO_A
    case 6: return 8;//FACTURA_B -> NOTA_DEBITO_B
    case 11: return 13;//FACTURA_C -> NOTA_DEBITO_C
    case 51: return 53;//FACTURA_M -> NOTA_DEBITO_M
    case 201: return 203;//FACTURA_CRED_ELEC_A -> NOTA_CREDITO_A
    case 206: return 208;//FACTURA_CRED_ELEC_B -> NOTA_CREDITO_B
    case 211: return 213;//FACTURA_CRED_ELEC_C -> NOTA_CREDITO_C    
  }
}
 
export const vouchersService = { 
  filter,
  getVoucherAsoc,
  create,
  remove,
  print,
  sendToAFIP,
  export_filter,
  export_libro_iva_filter,

  getCancelIdForCbteTipo,
  IVA_ID_0,
  IVA_ID_21,
};