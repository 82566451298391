<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" max-width="750px">
      <template v-slot:activator="{ on }" v-if="button_name != null">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-text-field data-vv-as="business_name" label="Razón social*" name="business_name" v-model="item.business_name" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("business_name") }}</span>

                <v-text-field data-vv-as="code" label="Código*" name="code" v-model="item.code" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("code") }}</span>

                <v-text-field data-vv-as="cuit" label="CUIT (XX-XXXXXXXXXX-X)*" name="CUIT" v-model="item.cuit" v-validate="{ required: true, regex: /\b(20|23|24|27|30|33|34)(-)[0-9]{8}(-)[0-9]/ }" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("cuit") }}</span>

                <v-text-field data-vv-as="phone" label="Teléfono" name="phone" v-model="item.phone" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("phone") }}</span>

                <v-text-field data-vv-as="address" label="Dirección" name="address" v-model="item.address" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("address") }}</span>

                <v-text-field
                  data-vv-as="custom_percentage"
                  label="Porcentaje variación de precios"
                  name="custom_percentage"
                  v-model="item.custom_percentage"
                  v-validate="'decimal:2'"
                  :disabled="isViewModal()"
                ></v-text-field>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { generalService } from "@/libs/ws/generalService";

export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
    section: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      show: false,
      roles: [],
      clients: [],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
      if(this.section != null) { 
        this.service.setSection(this.section);
      }
    },
  },
  mounted() {},
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    createItem(e) {
      this.$validator.validate().then((valid) => {        
        if (valid) {
          e.preventDefault();
          if (!this.item.isExternal) {
            this.item.companyExternal = { name: "", id: 1 };
          }
          if (this.item.id > 0) {
            this.service
              .update(this.item)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                  
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              });
          } else {
            generalService
              .create(this.item)
              .then((response) => {
                console.log(response);
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
              });
          }
        }else{
          
          console.log(this.errors)
          let msg = "";
          this.errors.items.forEach(e => {
            msg += e.msg + "\n";
          });
          this.$toast.error(msg, "Error");
        }
      });
    },
  },
};
</script>
