import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    [
      _c(
        VDialog,
        {
          attrs: {
            value: _vm.dialog,
            transition: "dialog-bottom-transition",
            fullscreen: "",
          },
          on: {
            "click:outside": function ($event) {
              return _vm.$emit("updateDialog", false)
            },
            input: function ($event) {
              return _vm.$emit("updateDialog", false)
            },
          },
        },
        [
          _c(
            VCard,
            [
              _c(
                VForm,
                {
                  ref: "modal_form",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.createItem.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(VCardTitle, [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v(_vm._s(_vm.modal_title)),
                    ]),
                  ]),
                  _c(VCardText, { staticClass: "container mt-5" }, [
                    _vm.item.id <= 0
                      ? _c("p", { staticClass: "text-h6 mb-5" }, [
                          _vm._v("Complete el formulario para crear una orden"),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c(
                            VRow,
                            [
                              _c(
                                VCol,
                                { attrs: { md: "3" } },
                                [
                                  _c(VCombobox, {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    attrs: {
                                      "data-vv-as": "cliente",
                                      name: "client",
                                      "item-text": "business_name",
                                      "item-value": "id",
                                      items: _vm.clients,
                                      label: "Cliente*",
                                      autocomplete: "off",
                                      disabled: true,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.clientChangeCombo($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.item.work_order.client,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.item.work_order,
                                          "client",
                                          $$v
                                        )
                                      },
                                      expression: "item.work_order.client",
                                    },
                                  }),
                                  _c("span", { staticClass: "red--text" }, [
                                    _vm._v(_vm._s(_vm.errors.first("client"))),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                { attrs: { md: "3" } },
                                [
                                  _c(VCombobox, {
                                    attrs: {
                                      "data-vv-as": "solicitante",
                                      name: "applicant",
                                      "item-text": "name",
                                      "item-value": "id",
                                      items: _vm.applicants,
                                      label: "Solicitante",
                                      autocomplete: "off",
                                      disabled: true,
                                    },
                                    model: {
                                      value: _vm.item.work_order.applicant,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.item.work_order,
                                          "applicant",
                                          $$v
                                        )
                                      },
                                      expression: "item.work_order.applicant",
                                    },
                                  }),
                                  _c("span", { staticClass: "red--text" }, [
                                    _vm._v(
                                      _vm._s(_vm.errors.first("applicant"))
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                { attrs: { md: "3" } },
                                [
                                  _c(VTextField, {
                                    staticClass: "mt-6",
                                    attrs: {
                                      dense: "",
                                      "data-vv-as": "nro_orden",
                                      label: "N° Orden",
                                      name: "work_order_id",
                                      disabled: true,
                                    },
                                    model: {
                                      value: _vm.item.work_order.id,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item.work_order, "id", $$v)
                                      },
                                      expression: "item.work_order.id",
                                    },
                                  }),
                                  _c("span", { staticClass: "red--text" }, [
                                    _vm._v(
                                      _vm._s(_vm.errors.first("nro_orden"))
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                { attrs: { md: "3" } },
                                [
                                  _c("DatePickerEsp", {
                                    attrs: {
                                      label: "Fecha creación",
                                      date: _vm.item.creation_date,
                                      isRequired: false,
                                      name: "creation_date",
                                      isViewModal: true,
                                    },
                                    on: {
                                      setDate: function (date) {
                                        //item.creation_date = date;
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(VDataTable, {
                            staticStyle: { border: "solid 1px #1c1c1c" },
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.item.items,
                              "hide-default-footer": true,
                              "items-per-page": 100,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function (props) {
                                  return [
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(_vm._s(props.index + 1)),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            props.item.asignated_task.task.name
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "text-center" },
                                        [
                                          _c(VTextField, {
                                            attrs: {
                                              dense: "",
                                              "data-vv-as": "spent",
                                              name: "spent",
                                              disabled: _vm.isViewModal(),
                                            },
                                            model: {
                                              value: props.item.spent,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "spent",
                                                  $$v
                                                )
                                              },
                                              expression: "props.item.spent",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-center" },
                                        [
                                          _c(VTextField, {
                                            attrs: {
                                              dense: "",
                                              "data-vv-as":
                                                "spent_writing_right",
                                              name: "spent_writing_right",
                                              disabled: _vm.isViewModal(),
                                            },
                                            model: {
                                              value:
                                                props.item.spent_writing_right,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "spent_writing_right",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "props.item.spent_writing_right",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-center" },
                                        [
                                          _c(VTextField, {
                                            attrs: {
                                              dense: "",
                                              "data-vv-as":
                                                "spent_notarial_contribution",
                                              name: "spent_notarial_contribution",
                                              disabled: _vm.isViewModal(),
                                            },
                                            model: {
                                              value:
                                                props.item
                                                  .spent_notarial_contribution,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "spent_notarial_contribution",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "props.item.spent_notarial_contribution",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-center" },
                                        [
                                          _c(VTextField, {
                                            attrs: {
                                              dense: "",
                                              "data-vv-as": "honorary",
                                              name: "honorary",
                                              disabled: _vm.isViewModal(),
                                            },
                                            model: {
                                              value: props.item.honorary,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "honorary",
                                                  $$v
                                                )
                                              },
                                              expression: "props.item.honorary",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-center" },
                                        [
                                          _c(VTextField, {
                                            attrs: {
                                              dense: "",
                                              "data-vv-as": "legalizacion",
                                              name: "legalizacion",
                                              disabled: _vm.isViewModal(),
                                            },
                                            model: {
                                              value: props.item.legalizacion,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "legalizacion",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "props.item.legalizacion",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-center" },
                                        [
                                          _c(VTextField, {
                                            attrs: {
                                              dense: "",
                                              "data-vv-as": "apostilla",
                                              name: "apostilla",
                                              disabled: _vm.isViewModal(),
                                            },
                                            model: {
                                              value: props.item.apostilla,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "apostilla",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "props.item.apostilla",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-center" },
                                        [
                                          _c(VTextField, {
                                            attrs: {
                                              dense: "",
                                              "data-vv-as": "apostilla_urgente",
                                              name: "apostilla_urgente",
                                              disabled: _vm.isViewModal(),
                                            },
                                            model: {
                                              value:
                                                props.item.apostilla_urgente,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "apostilla_urgente",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "props.item.apostilla_urgente",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-center" },
                                        [
                                          _c(VTextField, {
                                            attrs: {
                                              dense: "",
                                              "data-vv-as": "anexo_spent",
                                              name: "anexo_spent",
                                              disabled: _vm.isViewModal(),
                                            },
                                            model: {
                                              value: props.item.anexo_spent,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "anexo_spent",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "props.item.anexo_spent",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-center" },
                                        [
                                          _c(VTextField, {
                                            attrs: {
                                              dense: "",
                                              "data-vv-as": "anexo_honorary",
                                              name: "anexo_honorary",
                                              disabled: _vm.isViewModal(),
                                            },
                                            model: {
                                              value: props.item.anexo_honorary,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "anexo_honorary",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "props.item.anexo_honorary",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.calculateItemTotal(props.item)
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "text-center" },
                                        [
                                          !_vm.isViewModal()
                                            ? _c(
                                                VTooltip,
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              VIcon,
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "red",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeItem(
                                                                          props.item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " indeterminate_check_box "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Remover"),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                },
                              },
                              {
                                key: "header",
                                fn: function (props) {
                                  return [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", { attrs: { colspan: "1" } }, [
                                          _vm._v("Numero"),
                                        ]),
                                        _c("th", { attrs: { colspan: "1" } }, [
                                          _vm._v("Tarea"),
                                        ]),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "text-center",
                                            attrs: { colspan: "6" },
                                          },
                                          [_vm._v("Importes")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "text-center",
                                            attrs: { colspan: "1" },
                                          },
                                          [_vm._v("Total")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "text-center",
                                            attrs: { colspan: "1" },
                                          },
                                          [_vm._v("Accion")]
                                        ),
                                      ]),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c(
                    VCardActions,
                    { staticClass: "card-footer-fullscreen" },
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "error", "min-width": "100" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("updateDialog", false)
                            },
                          },
                        },
                        [_vm._v("Cerrar")]
                      ),
                      !_vm.isViewModal()
                        ? _c(
                            VBtn,
                            {
                              attrs: {
                                color: "success",
                                "min-width": "100",
                                loading: _vm.loading,
                                type: "submit",
                              },
                            },
                            [_vm._v(_vm._s(_vm.formBtn))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }