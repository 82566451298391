import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpandTransition } from 'vuetify/lib/components/transitions';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "col-md-12 pt-0" }, [
        _c(
          "div",
          {
            class: !_vm.menu
              ? "row box-chofer enCurso"
              : "row box-chofer-open enCurso",
            staticStyle: { cursor: "default !important" },
          },
          [
            _c(
              "div",
              { staticClass: "col-md-1 col-xs-12 center-vh" },
              [
                !_vm.menu
                  ? _c("font-awesome-icon", {
                      staticClass: "cursor",
                      staticStyle: { color: "rgb(52, 131, 250)" },
                      attrs: { icon: "chevron-down" },
                      on: {
                        click: function ($event) {
                          _vm.menu = true
                        },
                      },
                    })
                  : _c("font-awesome-icon", {
                      staticClass: "cursor",
                      staticStyle: { color: "rgb(52, 131, 250)" },
                      attrs: { icon: "chevron-up" },
                      on: {
                        click: function ($event) {
                          _vm.menu = false
                        },
                      },
                    }),
                _c(
                  "p",
                  {
                    staticClass: "mb-0 ml-2",
                    staticStyle: { "white-space": "nowrap" },
                  },
                  [_c("b", [_vm._v("#" + _vm._s(_vm.order.id))])]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "col-md-2 col-xs-6 subBoxChofer" }, [
              _vm._m(0),
              _c("span", { staticStyle: { "text-transform": "uppercase" } }, [
                _vm._v(_vm._s(_vm.order.asignation_date)),
              ]),
            ]),
            _c("div", { staticClass: "col-md-2 col-xs-6 subBoxChofer" }, [
              _vm._m(1),
              _c("span", { staticStyle: { "text-transform": "uppercase" } }, [
                _vm._v(
                  _vm._s(
                    _vm.order.employee != null
                      ? _vm.order.employee.name
                      : "Empleado"
                  )
                ),
              ]),
            ]),
            _c("div", { staticClass: "col-md-3 col-xs-6 subBoxChofer" }, [
              _vm._m(2),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.order.client.business_name) +
                    " | " +
                    _vm._s(
                      _vm.order.applicant != null
                        ? _vm.order.applicant.name
                        : "-"
                    )
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "col-md-2 col-xs-6 text-center" },
              [
                _vm.order.stats.not_finished > 0
                  ? _c("apexchart", {
                      ref: "Chart01",
                      attrs: {
                        type: "donut",
                        width: "90",
                        height: "90",
                        options: _vm.chart01Options,
                        series: _vm.series01,
                      },
                    })
                  : _c("apexchart", {
                      ref: "Chart01",
                      attrs: {
                        type: "donut",
                        width: "90",
                        height: "90",
                        options: _vm.chart02Options,
                        series: _vm.series01,
                      },
                    }),
              ],
              1
            ),
            _c("div", {
              staticClass: "col-md-2 col-xs-6 center-vh",
              domProps: {
                innerHTML: _vm._s(_vm.service.getStateHtml(_vm.order.state)),
              },
            }),
          ]
        ),
      ]),
      _c(VExpandTransition, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.menu,
                expression: "menu",
              },
            ],
            staticClass: "extra-info-monitoring mb-5",
          },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c(VDataTable, {
                    staticClass: "background-color: grey lighten-3",
                    attrs: {
                      headers: _vm.headersTasks,
                      items: _vm.order.asignated_tasks,
                      search: _vm.search,
                      "loading-text": "Cargando",
                      "hide-default-footer": "",
                      "disable-pagination": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function (props) {
                          return [
                            _c("tr", [
                              _c("td", [_vm._v(_vm._s(props.index + 1))]),
                              _c("td", [_vm._v(_vm._s(props.item.task.name))]),
                              _c("td", [_vm._v(_vm._s(props.item.page_nr))]),
                              _c("td", [_vm._v(_vm._s(props.item.record_nr))]),
                              _c("td", { key: props.item.documents.length }, [
                                _vm._v(
                                  " " +
                                    _vm._s(props.item.documents.length) +
                                    " "
                                ),
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(props.item.signatories.length)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(props.item.priority_name)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(props.item.state.name)),
                              ]),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "header",
                        fn: function (props) {
                          return [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { attrs: { colspan: "1" } }, [
                                  _vm._v("Numero"),
                                ]),
                                _c("th", { attrs: { colspan: "1" } }, [
                                  _vm._v("Tarea"),
                                ]),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "4" },
                                  },
                                  [_vm._v("Informacion adicional")]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "1" },
                                  },
                                  [_vm._v("Prioridad")]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "1" },
                                  },
                                  [_vm._v("Estado")]
                                ),
                              ]),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mb-0" }, [_c("b", [_vm._v("Fecha")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mb-0" }, [_c("b", [_vm._v("Ejecutante")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mb-0" }, [
      _c("b", [_vm._v("Cliente | Solicitante")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }