var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.pages.length == 1
      ? _c("section", { staticClass: "content-header line-header d-flex " }, [
          _c(
            "div",
            { staticClass: "container-title d-flex" },
            [
              _c("font-awesome-icon", { attrs: { icon: _vm.pages[0].icon } }),
              _c("h1", [_vm._v(" " + _vm._s(_vm.pages[0].page) + " ")]),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.pages.length > 1
      ? _c("section", { staticClass: "content-header line-header d-flex " }, [
          _c(
            "ol",
            { staticClass: "breadcrumb" },
            _vm._l(_vm.pages, function (item, index) {
              return _c("li", { key: index }, [
                index == 0
                  ? _c(
                      "a",
                      {
                        staticClass: "container-item-blue",
                        on: {
                          click: function ($event) {
                            return _vm.$router.back()
                          },
                        },
                      },
                      [
                        _c("font-awesome-icon", { attrs: { icon: item.icon } }),
                        _c("span", { staticClass: "item-blue ml-2" }, [
                          _vm._v(_vm._s(item.page)),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                index != 0 &&
                index != _vm.pages.length - 1 &&
                _vm.pages.length > 2
                  ? _c("a", { staticClass: "container-item-blue" }, [
                      _c("span", { staticClass: "item-blue" }, [
                        _vm._v(_vm._s(item.page)),
                      ]),
                    ])
                  : _vm._e(),
                index != 0 && index == _vm.pages.length - 1
                  ? _c("a", { staticStyle: { cursor: "default !important" } }, [
                      _c("span", { staticClass: "item" }, [
                        _vm._v(_vm._s(item.page)),
                      ]),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }