var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pdf-paginator" },
    [
      _vm.pageCount
        ? [
            _c("input", {
              attrs: { min: "1", max: _vm.pageCount, type: "number" },
              domProps: { value: _vm.value },
              on: { input: _vm.input },
            }),
            _vm._v(" / "),
            _c("span", [_vm._v(_vm._s(_vm.pageCount))]),
          ]
        : _c("input", { attrs: { type: "number" } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }