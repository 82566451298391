import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VDialog,
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VProgressLinear, {
                attrs: { indeterminate: "", color: "blue darken-2" },
              }),
              _c(VCardText, [
                _c(
                  "div",
                  [
                    _c(
                      VContainer,
                      { staticStyle: { height: "20px" } },
                      [
                        _c(
                          VRow,
                          {
                            staticClass: "fill-height",
                            attrs: {
                              "align-content": "center",
                              justify: "center",
                            },
                          },
                          [
                            _c(
                              VCol,
                              {
                                staticClass: "subtitle-1 text-center",
                                attrs: { cols: "12" },
                              },
                              [_vm._v(" " + _vm._s(_vm.text) + " ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }