<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)"  max-width="750px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-text-field data-vv-as="name" label="Nombre*" name="name" v-model="item.name" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("name") }}</span>

                <v-text-field 
                  data-vv-as="username" label="Username*" name="username" v-model="item.user.username" v-validate="'required'" 
                  :disabled="isViewModal() || item.id > 0"
                />
                <span class="red--text">{{ errors.first("username") }}</span>
                
                <v-text-field
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show = !show"
                  :type="show ? 'text' : 'password'"
                  label="Password*"
                  data-vv-as="password"
                  name="password"
                  v-validate="!(item.id > 0)?'required':''"
                  v-model="item.user.password"
                  :disabled="isViewModal()"
                />
                <span class="red--text">{{ errors.first("password") }}</span>

                <v-combobox
                  v-model="item.user.role"
                  data-vv-as="rol"
                  name="rol"
                  item-text="name"
                  item-value="id"
                  :items="roles"
                  label="Rol*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("rol") }}</span>

                <v-text-field v-if="isNotary()" data-vv-as="matricula" label="Matricula" name="matricula" v-model="item.matricula" :disabled="isViewModal()"></v-text-field>
                <v-textarea v-if="isNotary()" outlined v-model="item.record" data-vv-as="record" name="record" label="Registro" :disabled="isViewModal()"></v-textarea>                 

                <v-combobox
                  v-model="item.employee_type"
                  data-vv-as="employee_type"
                  name="employee_type"
                  item-text="name"
                  item-value="id"
                  :items="getTypes()"
                  label="Tipo*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("employee_type") }}</span>

                <div class="row">
                  <div class="col-md-12">
                    <v-checkbox label="Habilitado" :disabled="isViewModal()" v-model="item.user.enable"></v-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ConstantEmployeesType from "@/constants/employee_types";
import { rolService } from "@/libs/ws/rolService";
export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    page_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      show: false,
      roles: [],
      clients: [],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadRoles();
  },
  methods: {
    getTypes(){ return ConstantEmployeesType.TYPE_COMBO; },
    isNotary(){ return this.item.employee_type != null && this.item.employee_type.id == ConstantEmployeesType.TYPE_NOTARY; },
    isViewModal() {
      return this.formBtn == "";
    },
    loadRoles() {
      rolService
        .getEmployees()
        .then((response) => {          
          this.roles = response.result;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    createItem(e) {
      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();   
          
          //Se hace para que el parseo de array no reemplace la propiedad "user" por un array
          var formData = new FormData();
          formData.append("id", this.item.id);
          formData.append("name", this.item.name);

          formData.append("user_id", (this.item.user.id != undefined)?this.item.user.id:0);
          formData.append("username", this.item.user.username);
          if(this.item.user.password != undefined && this.item.user.password != "")
            formData.append("password", this.item.user.password);
          formData.append("role", this.item.user.role.id);
          formData.append("enable", (this.item.user.enable)?1:0);          
          formData.append("employee_type", this.item.employee_type.id);
          formData.append("matricula", this.item.matricula);
          formData.append("record", this.item.record);          
          
          const data = {
            id: this.item.id,
            name: this.item.name,
            user_id: this.item.user.id,
            username: this.item.user.username,
            password: this.item.user.password,
            role: this.item.user.role.id,
            enable: (this.item.user.enable)?1:0,
            employee_type: this.item.employee_type.id,
            matricula: this.item.matricula,
            record: this.item.record,
          };

          console.log(data)

          if (this.item.id > 0) {            
            this.service
              .update(formData)             
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El "+this.modal_title+" se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");                  
                } else {
                  if(Object.prototype.hasOwnProperty.call(response, 'message')){
                    this.$toast.error(response.message, "Error");
                  }else{
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {                
                this.$toast.error(response.msg, "Error");
              });
          } else {                        
            this.service
              .create(formData)
              .then((response) => {
                console.log(response)             
                if (response.result.id > 0) {
                  this.$toast.success("El "+this.modal_title+" se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");                  
                } else{                  
                  if(Object.prototype.hasOwnProperty.call(response, 'message')){
                    this.$toast.error(response.message, "Error");
                  }else{
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {              
                this.$toast.error(response.msg, "Error");
              });
          }
        }
      });
    },
  },
};
</script>
