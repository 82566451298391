var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "canvas",
      _vm._b(
        {
          directives: [
            {
              name: "visible",
              rawName: "v-visible.once",
              value: _vm.drawPage,
              expression: "drawPage",
              modifiers: { once: true },
            },
          ],
          ref: "PageRef",
        },
        "canvas",
        _vm.canvasAttrs,
        false
      )
    ),
    _vm.pageNumber == 1
      ? _c("canvas", {
          ref: "ImgRef",
          staticStyle: {
            cursor: "pointer",
            position: "absolute",
            "z-index": "999",
            top: "0px",
            left: "0px",
            background: "white",
          },
          attrs: { id: "dogPic" },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }